import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'src/types/User';
import { IStatisticsResponseDTO } from 'src/api/user/ResponseDtos';
import { userAPI } from 'src/api/user/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { Inbox } from 'src/types/Inbox';
import { RoutesEnum } from 'src/routes';

export interface UserState {
  suggestedTherapistsLoading: boolean;
  suggestedTherapists: User[];
  therapistDetailsLoading: boolean;
  therapistDetails: User;
  therapistStatisticsLoading: boolean;
  therapistStatistics: IStatisticsResponseDTO;
  loadingUserInbox: boolean;
  userInbox: Inbox[];
  selectedInbox: Inbox;
}

const initialState: UserState = {
  suggestedTherapistsLoading: false,
  suggestedTherapists: [],
  therapistDetailsLoading: false,
  therapistDetails: undefined,
  therapistStatisticsLoading: false,
  therapistStatistics: undefined,
  loadingUserInbox: false,
  userInbox: [],
  selectedInbox: undefined,
};

export const getTherapistDetails = createAsyncThunk('getTherapistDetails', async (userId: string, thunkAPI) => {
  try {
    return await userAPI.getUserDetails(userId);
  } catch (error) {
    // const err = parseError(error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getSuggestedTherapists = createAsyncThunk('getSuggestedTherapists', async (_, thunkAPI) => {
  try {
    return await userAPI.getSuggestedTherapists();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getSuggestedTherapists');
    return thunkAPI.rejectWithValue(error);
  }
});

export const getTherapistStatistics = createAsyncThunk('getTherapistStatistics', async (userId: number, thunkAPI) => {
  try {
    return await userAPI.getUserStatistics(userId);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getTherapistStatistics');
    return thunkAPI.rejectWithValue(error);
  }
});

export const getInbox = createAsyncThunk('getInbox', async (_, thunkAPI) => {
  try {
    return await userAPI.getInbox();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getInbox');
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateUser = createAsyncThunk('updateUser', async (data: FormData, thunkAPI) => {
  try {
    return await userAPI.updateUser(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'updateUser');
    return thunkAPI.rejectWithValue(error);
  }
});

export const recommendFriend = createAsyncThunk('recommendFriend', async (email: string, thunkAPI) => {
  try {
    return await userAPI.recommendFriend(email);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'recommendFriend');
    return thunkAPI.rejectWithValue(error);
  }
});

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setSelectedInbox: (state, action) => {
      state.selectedInbox = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSuggestedTherapists.pending, (state) => {
        state.suggestedTherapistsLoading = true;
      })
      .addCase(getSuggestedTherapists.fulfilled, (state, action: PayloadAction<any>) => {
        state.suggestedTherapistsLoading = false;
        state.suggestedTherapists = action.payload.data;
      })
      .addCase(getSuggestedTherapists.rejected, (state) => {
        state.suggestedTherapistsLoading = false;
      })
      .addCase(getTherapistDetails.pending, (state) => {
        state.therapistDetailsLoading = true;
      })
      .addCase(getTherapistDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.therapistDetailsLoading = false;
        state.therapistDetails = action.payload.data;
      })
      .addCase(getTherapistDetails.rejected, (state) => {
        state.therapistDetailsLoading = false;
        window.location.href = RoutesEnum.HOME
      })
      .addCase(getTherapistStatistics.pending, (state) => {
        state.therapistStatisticsLoading = true;
      })
      .addCase(getTherapistStatistics.fulfilled, (state, action: PayloadAction<any>) => {
        state.therapistStatisticsLoading = false;
        state.therapistStatistics = action.payload.data;
      })
      .addCase(getTherapistStatistics.rejected, (state) => {
        state.therapistStatisticsLoading = false;
      })
      .addCase(getInbox.pending, (state) => {
        state.loadingUserInbox = true;
      })
      .addCase(getInbox.fulfilled, (state, action: PayloadAction<any>) => {
        state.loadingUserInbox = false;
        state.userInbox = action.payload.data;
      })
      .addCase(getInbox.rejected, (state) => {
        state.loadingUserInbox = false;
      });
  },
});

export const { setSelectedInbox } = userSlice.actions;

export default userSlice.reducer;
