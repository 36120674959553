import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch } from 'src/store';
import { recommendFriend } from 'src/store/slices/userSlice';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { useTranslation } from 'react-i18next';

export interface RecommendFriendState {
  email: string;
}

const useRecommendFriendForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const useRecommendFriendFormSchema = yup.object().shape({
    email: yup.string().email(t('error_email')).required(t('hint_email')),
  });

  const formDefaultValues: RecommendFriendState = {
    email: '',
  };

  const recommendFriendForm = useForm({
    resolver: yupResolver(useRecommendFriendFormSchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: RecommendFriendState, onClose: () => void) => {
    const response: any = await dispatch(recommendFriend(data.email));

    if (response.payload.success) {
      ToastNotifications.success(t('msg_invite_sent'));
      onClose();
    }
  };

  return {
    formDefaultValues,
    recommendFriendForm,
    onFormSubmit: onSubmit,
  };
};

export { useRecommendFriendForm };
