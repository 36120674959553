import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const ChevronDown = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', className = '', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} style={style} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        d="M12 16.5c-.3 0-.5-.1-.7-.3l-7-7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l6.3 6.3 6.3-6.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.2.2-.4.3-.7.3z"
      />
    </svg>
  );
};
