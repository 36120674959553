import React from 'react';

import { Flex, Text } from 'src/components/common';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} flex="1">
      <Text variant={'heading3'} text={t('not_found_1')} />
    </Flex>
  );
};
