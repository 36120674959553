import LandingSekcija2Ikona1 from 'src/assets/images/landing/landing-sekcija2-ikona-1.png';
import LandingSekcija2Ikona2 from 'src/assets/images/landing/landing-sekcija2-ikona-2.png';
import LandingSekcija2Ikona3 from 'src/assets/images/landing/landing-sekcija2-ikona-3.png';
import LandingSekcija3Ikona1 from 'src/assets/images/landing/landing-sekcija3-ikona-1.png';
import LandingSekcija3Ikona2 from 'src/assets/images/landing/landing-sekcija3-ikona-2.png';
import LandingSekcija3Ikona3 from 'src/assets/images/landing/landing-sekcija3-ikona-3.png';
import LandingSekcija3Ikona4 from 'src/assets/images/landing/landing-sekcija3-ikona-4.png';
import LandingSekcija3Ikona5 from 'src/assets/images/landing/landing-sekcija3-ikona-5.png';
import { useTranslation } from 'react-i18next';

interface Item {
  icon: string;
  text: string;
  reverse?: boolean;
}

export const howDoWeSelectTherapists = (): Item[] => {
  const { t } = useTranslation();
  return [
    { icon: LandingSekcija2Ikona1, text: t('home_13') },
    { icon: LandingSekcija2Ikona2, text: t('home_14') },
    { icon: LandingSekcija2Ikona3, text: t('home_15') },
  ];
};

export const whyWeStarted = (): Item[] => {
  const { t } = useTranslation();
  return [
    { icon: LandingSekcija3Ikona1, text: t('home_16'), reverse: true },
    { icon: LandingSekcija3Ikona2, text: t('home_17') },
    { icon: LandingSekcija3Ikona3, text: t('home_18'), reverse: true },
    { icon: LandingSekcija3Ikona4, text: t('home_19') },
    { icon: LandingSekcija3Ikona5, text: t('home_20'), reverse: true },
  ];
};
