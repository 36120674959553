import React from 'react';
import styled, { useTheme } from 'styled-components';

import { TextProps } from 'src/types/Text';
import { TypographyVariant } from 'src/theme/styled';

const StyledText = styled.div<Partial<TextProps>>`
  font-size: ${(props) => props.typography.fontSize};
  font-weight: ${(props) => props.typography.fontWeight};
  line-height: ${(props) => props.typography.lineHeight};
  font-family: ${(props) => props.typography.fontFamily};
  margin: ${(props) => props.margin ?? '0px'};
  padding: ${(props) => props.padding ?? '0px'};
  width: ${(props) => props.width ?? 'fit-content'};
  word-break: ${(props) => props.wordBreak ?? 'normal'};
  white-space: ${(props) => props.whiteSpace ?? 'normal'};
  color: ${(props) => props.color ?? props.theme.colors.text.primary};
  display: ${(props) => props.display};
  text-decoration: ${(props) => props.textDecoration ?? 'none'};
  align-items: center;
  justify-content: ${(props) =>
    props.textAlign ? (props.textAlign === 'left' ? 'flex-start' : props.textAlign === 'right' ? 'flex-end' : 'center') : 'flex-start'};
  text-align: ${(props) => props.textAlign ?? 'left'};
  cursor: ${(props) => (props.cursor ? props.cursor : props.onClick ? 'pointer' : 'inherit')};
`;

export const Text = ({
  display = 'flex',
  text = ' ',
  variant = 'paragraph1',
                       classNames = undefined,
  children = undefined,
  style = {},
  ...rest
}: TextProps): React.ReactElement => {
  const theme = useTheme();
  const typography: TypographyVariant = theme.typography.variants[variant];
  const content = children ? children : !Number.isNaN(text) ? text : '';

  return (
    <StyledText {...rest} display={display} typography={typography} style={style} className={classNames ?? ''}>
      {content}
    </StyledText>
  );
};
