import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, Modal, Text, Input } from 'src/components/common';
import { ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { InlineTextWrapper } from 'src/components/shared';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';

export interface ConfirmPaymentPolicyModalProps {
  isOpen?: boolean;
  loading: boolean;
  onClose?: () => void;
  onConfirm?: (string) => void;
}

export const ConfirmPaymentPolicyModal = ({ isOpen, loading, onClose, onConfirm }: ConfirmPaymentPolicyModalProps) => {
  const theme = useTheme();

  const [voucherCode, setVoucherCode] = useState('');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Text variant={'heading5'} text={'Uslovi plaćanja'} margin={'0 0 32px 0'} />

      <ToggleButtonWrapper>
        <InlineTextWrapper margin={'0 0 32px 0'}>
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            text={'Prije nego što platite svoju terapijsku sesiju, molimo vas da prihvatite'}
          />
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            text={'uslove plaćanja'}
            color={theme.colors.palette.purple}
            style={{ textDecoration: 'underline' }}
            onClick={() => goToUrl(config.PRIVACY_POLICY_URL, '_blank')}
          />
        </InlineTextWrapper>
      </ToggleButtonWrapper>

      <Input
        placeholder={'Ukoliko imate vaučer unesite ga ovdje'}
        width={'100%'}
        margin={'0 0 32px 0'}
        value={voucherCode}
        onChange={(e) => {
          setVoucherCode(e.target.value);
        }}
      />

      <Button
        variant="primaryDefault"
        text={'Prihvatam'}
        width={'100%'}
        disabled={loading}
        isLoading={loading}
        onClick={() => onConfirm(voucherCode)}
      />
    </Modal>
  );
};
