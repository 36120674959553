import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale, setDefaultLocale, ReactDatePickerProps } from 'react-datepicker';
import { getMonth, getYear, setHours, setMinutes } from 'date-fns';
import hr from 'date-fns/locale/hr';
import range from 'lodash/range';

import { Button, SelectDropdown } from 'src/components/common';
import { useTranslation } from 'react-i18next';

registerLocale('hr', hr);
setDefaultLocale('hr');

const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    .react-datepicker {
      width: 100%;
      display: flex;
      border: none;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__week {
        display: flex;
      }

      .react-datepicker__month-container {
        width: ${(props) => props.monthTrackWidth};

        .react-datepicker__day,
        .react-datepicker__day-name {
          width: calc(100% / 7) !important;
          height: ${(props) => props.dayWrapperSize};
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: ${(props) => props.dayTextSize}px;
        }

        .react-datepicker__day {
          &--today {
            font-size: 28px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.palette.purple};
          }

          &--selected {
            font-size: ${(props) => props.dayTextSize + 4}px;
            font-weight: bold !important;
            color: ${(props) => props.theme.colors.palette.white};
          }

          &--disabled {
            background-color: transparent !important;
          }
        }
      }

      .react-datepicker__time-list-item {
        display: none;
        width: 100% !important;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${(props) => props.dayTextSize}px;

        &--disabled {
          display: none;
        }

        &--selected {
          background-color: ${(props) => props.theme.colors.palette.purple} !important;
        }

        @media (max-width: ${(props) => props.theme.breakpoints.md}) {
          font-size: ${(props) => props.dayTextSize - 5}px;
          height: 30px !important;
        }
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent !important;
      }

      .basic-single .select__control {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        background-color: rgb(250, 250, 250);
        border: none;
        border-size 0px;
        font-size: ${(props) => props.dayTextSize - 2}px;
      }    
    }
  }

  .react-datepicker__day--highlighted.react-datepicker__day--selected {
    outline: 3px solid ${(props) => props.theme.colors.palette.purple} !important;
    outline-offset: 2px;
  }

  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.purple};
    color: #fff !important;
  }

  .react-datepicker__day--highlighted-available-to-book {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.green} !important;
    color: #fff !important;
  }

  .react-datepicker__day--highlighted-booked {
    border-radius: 0.3rem;
    background-color: ${(props) => props.theme.colors.palette.red} !important;
    color: #fff !important;
  }

   .react-datepicker__day--highlighted-availability-booked-match {
    border-radius: 0.3rem;
    background-image: ${(props) =>
      `linear-gradient(135deg, ${props.theme.colors.palette.green} 50%, ${props.theme.colors.palette.red} 50%)`} !important;
    color: #fff !important;
  }

  .react-datepicker-time__header {
    height: 60px;
    font-size: ${(props) => props.dayTextSize};

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: ${(props) => props.dayTextSize - 7}px;
      height: auto;
      margin-bottom: 20px;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    width: 100%;
    height: 60px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .react-datepicker__time-container {
    display: ${(props) => (props.timeTrackVisible ? '' : 'none')};
    width: ${(props) => props.timeTrackWidth};
    border-left: 1px solid ${(props) => props.theme.colors.border.lighter};
  }

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: 100%;
      margin-left: 5px;
    }
  }

  .react-datepicker__day-names {
    display: flex;
    align-items: center;
    height: 5rem !important;
  }

  .select__value-container {
    padding: 20px 12px;
  }

  .react-datepicker__header {
    font-size: ${(props) => props.dayTextSize};
    background-color: transparent;
    border: none;
  }

  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.colors.palette.purple};
  }

  .react-datepicker__header--custom {
    button {
      width: 40px;
      height: 40px;
      background-color: ${(props) => props.theme.colors.background.secondary.BG1};
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-left: ${(props) => props.monthSpacer};
      margin-right: ${(props) => props.monthSpacer};
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.border.lighter} !important;

      &:disabled {
        font-size: ${(props) => props.dayTextSize};
      }

      > div {
        font-size: 22px;
        font-family: 'Barlow-Light' !important;
        line-height: 22px;
        margin-bottom: 4px;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .select__indicator {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const DEFAULT_TIME_INTERVAL_IN_MINUTES = 30;
const DEFAULT_MAX_YEARS = getYear(new Date()) + 10;
const DEFAULT_MIN_TIME = setHours(setMinutes(new Date(), 0), 0);
const DEFAULT_MAX_TIME = setHours(setMinutes(new Date(), 0), 23);
const DEFAULT_MAX_MONTHS_FROM_NOW = 2; // 2 -> current and next
const DAY_WRAPPER_SIZE = '2.7rem';
const DAY_TEXT_SIZE = '16px';
const MONTH_WIDTH = '100px';
const MONTH_SPACER = '10px';
const TIME_TRACK_VISIBLE = true;
const TIME_TRACK_WIDTH = '20%';
const MONTH_TRACK_WIDTH = '80%';
const MONTH_YEAR_HEADER_MARGIN = 10;

export const DateTimePicker = ({
  selectedDate,
  setSelectedDate,
  minTime = DEFAULT_MIN_TIME,
  maxTime = DEFAULT_MAX_TIME,
  timeIntervalInMinutes = DEFAULT_TIME_INTERVAL_IN_MINUTES,
  maxYears = DEFAULT_MAX_YEARS,
  maxMonthsFromNow = undefined,
  dayWrapperSize = DAY_WRAPPER_SIZE,
  dayTextSize = DAY_TEXT_SIZE,
  monthWidth = MONTH_WIDTH,
  monthSpacer = MONTH_SPACER,
  timeTrackWidth = TIME_TRACK_WIDTH,
  timeTrackVisible = TIME_TRACK_VISIBLE,
  monthTrackWidth = MONTH_TRACK_WIDTH,
  monthYearHeaderMargin = MONTH_YEAR_HEADER_MARGIN,
  ...rest
}: ReactDatePickerProps) => {
  const { t } = useTranslation();

  const DEFAULT_MONTHS = [
    t('date_time_2'),
    t('date_time_3'),
    t('date_time_4'),
    t('date_time_5'),
    t('date_time_6'),
    t('date_time_7'),
    t('date_time_8'),
    t('date_time_9'),
    t('date_time_10'),
    t('date_time_11'),
    t('date_time_12'),
    t('date_time_13'),
  ];

  const [internalDate, setInternalDate] = useState(selectedDate ?? new Date());

  const years = range(getYear(new Date()), maxYears, 1);
  const months = DEFAULT_MONTHS;
  const maxDate = new Date(new Date().getFullYear(), new Date().getMonth() + (maxMonthsFromNow ?? DEFAULT_MAX_MONTHS_FROM_NOW), 0);

  const yearsOptions = years.map((value) => {
    return {
      label: value,
      value,
    };
  });

  const monthsOptions = months.map((value) => {
    return {
      label: value,
      value,
    };
  });

  return (
    <DatePickerWrapper
      dayWrapperSize={dayWrapperSize}
      dayTextSize={dayTextSize}
      monthWidth={monthWidth}
      monthSpacer={monthSpacer}
      timeTrackWidth={timeTrackWidth}
      timeTrackVisible={timeTrackVisible}
      monthTrackWidth={monthTrackWidth}
    >
      <DatePicker
        {...rest}
        dateFormat="dd MMMM, yyyy HH:mm"
        timeCaption={t('date_time_1')}
        timeFormat="HH:mm"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: monthYearHeaderMargin,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button disabled={prevMonthButtonDisabled} variant="transparentSmall" onClick={decreaseMonth} text="<" noBorder={true} />

            <SelectDropdown
              width="auto"
              minWidth={monthWidth}
              margin="0 20px 0 0"
              options={yearsOptions}
              value={getYear(date)}
              onChange={(value) => changeYear(value)}
              variant="default"
            />

            <SelectDropdown
              width="auto"
              minWidth={monthWidth}
              options={monthsOptions}
              value={months[getMonth(date)]}
              onChange={(value) => changeMonth(months.indexOf(value))}
              variant="default"
            />

            <Button disabled={nextMonthButtonDisabled} variant="transparentSmall" onClick={increaseMonth} text=">" noBorder={true} />
          </div>
        )}
        locale="hr"
        showTimeSelect
        timeIntervals={timeIntervalInMinutes}
        selected={selectedDate ?? internalDate}
        minDate={new Date()}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        onChange={(date) => {
          setInternalDate(date);
          if (typeof setSelectedDate === 'function') {
            setSelectedDate(date);
          }
        }}
        inline
      />
    </DatePickerWrapper>
  );
};
