import { HTMLProps, ReactNode } from 'react';
import { VariantType } from 'src/types/Text';

export enum ButtonSize {
  SMALL = '30px',
  DEFAULT = '40px',
  LARGE = '48px',
}

export type ButtonVariant =
  | 'primaryLarge'
  | 'primaryDefault'
  | 'primarySmall'
  | 'secondaryLarge'
  | 'secondaryDefault'
  | 'secondarySmall'
  | 'transparentLarge'
  | 'transparentDefault'
  | 'transparentSmall'
  | 'redLarge'
  | 'redDefault'
  | 'secondaryRedDefault'
  | 'redSmall'
  | 'accentLarge'
  | 'accentDefault'
  | 'secondaryAccentDefault'
  | 'accentSmall'
  | 'infoDefault';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  text?: string;
  textColor?: string;
  variant?: ButtonVariant;
  isLoading?: boolean;
  onClick?: () => void;
  width?: string;
  minWidth?: string;
  margin?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  noBorder?: boolean;
}

export interface ButtonStyles {
  backgroundColor: string;
  backgroundColorDisabled: string;
  border: string;
  borderDisabled: string;
  color: string;
  iconColor: string;
  colorDisabled: string;
  padding: string;
  height: ButtonSize;
  typographyVariant: VariantType;
}
