export const jsonToFormData = (json: any) => {
  const keys = Object.keys(json);
  const formData = new FormData();

  keys.forEach((key) => {
    const value = json[key];
    formData.append(`${key}`, value);
  });

  return formData;
};
