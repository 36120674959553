import React, { ReactElement, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Flex, Text } from 'src/components/common';
import LinearProgress from '@material-ui/core/LinearProgress';
import { X } from 'src/components/icons/ui-icons';
import { useTranslation } from 'react-i18next';

const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || 'fit-content'};
  justify-content: center;
  align-items: flex-start;
  margin: ${(props) => props.margin || 0};
  position: relative;
`;

const UploadButtonElement = styled.button<Partial<IProps>>`
  height: 40px;
  width: ${(props) => props.width || 'max-content'};
  padding: 0 32px;
  background-color: ${(props) => props.backgroundColor || props.theme.colors.palette.purple};
  margin: ${(props) => props.margin || 0};
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  border: none;

  > * {
    cursor: pointer;
  }

  input[type='file'] {
    display: none;
  }
`;

const ErrorMessageWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
`;

const UploadLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 3,
      width: '100%',
      marginTop: 10,
    },
    colorPrimary: {
      backgroundColor: 'rgb(179, 142, 255)',
      opacity: 0.8,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#6252ff',
    },
  })
)(LinearProgress);

interface IProps {
  width?: string;
  text?: string;
  color?: string;
  backgroundColor?: string;
  margin?: string;
  onChange?: (event: any) => void;
  loading?: boolean;
  multiple?: boolean;
  documentsAdded?: File[];
  allowedFormats?: string;
  errorMessage?: string;
}

export const UploadButton = (props: IProps): ReactElement => {
  const theme = useTheme();
  const fileInput = useRef(null);
  const { t } = useTranslation();

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    props.onChange && props.onChange(files);
  };

  const handleFileRemove = (file: File) => {
    if (props.documentsAdded && props.onChange) {
      const files = Array.from(props.documentsAdded).filter((item) => item != file);
      props.onChange(files);
    }
  };

  const renderAddedFilesNames = (files) => {
    return (
      <Flex flexDirection={'column'} margin={'16px 0 0 8px'} width={'97%'} gap={'8px'}>
        {files &&
          Array.from(files).map((file: File, index) => (
            <Flex key={index} width={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Text variant={'paragraph2'} text={`${index + 1}. ${file.name}`} />
              <Flex onClick={() => handleFileRemove(file)}>
                <X size={24} color={theme.colors.palette.red} />
              </Flex>
            </Flex>
          ))}
      </Flex>
    );
  };

  return (
    <UploadButtonWrapper margin={props.margin} width={props.width}>
      <UploadButtonElement
        type={'button'}
        width={props.width}
        disabled={props.loading}
        backgroundColor={props.backgroundColor}
        onClick={() => {
          if (fileInput && fileInput.current) {
            // @ts-ignore
            fileInput.current.click();
          }
        }}
      >
        <Text width={'100%'} textAlign="center" text={props.text || t('upload_2')} color={theme.colors.palette.white} />
        <input ref={fileInput} type="file" onChange={handleFileChange} multiple={!!props.multiple} accept={props.allowedFormats || '*'} />
      </UploadButtonElement>
      {props.errorMessage && (
        <ErrorMessageWrapper>
          <Text text={props.errorMessage} variant="paragraph3" margin="4px 0 0 0" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}
      {props.loading && <UploadLinearProgress />}
      {!props.loading && renderAddedFilesNames(props.documentsAdded)}
    </UploadButtonWrapper>
  );
};
