import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILoginRequestDTO, IRegisterRequestDTO, IResetPasswordRequestDTO } from 'src/api/auth/RequestDtos';
import { authAPI } from 'src/api/auth/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { storageService } from 'src/utils/storage';
import { RoutesEnum } from 'src/routes';
import { RolesEnum } from 'src/constants/RolesEnum';
import { User } from 'src/types/User';

export interface AuthState {
  showLoginModal: boolean;
  showRegistrationodal: boolean;
  isAuthenticated: boolean;
  token: string | null;
  loading: boolean;
  user: User;
  redirectRouteAfterRegistration?: string;
}

const initialState: AuthState = {
  showLoginModal: false,
  showRegistrationodal: false,
  isAuthenticated: !!storageService.getToken(),
  token: storageService.getToken(),
  loading: false,
  user: undefined,
  redirectRouteAfterRegistration: undefined,
};

export const register = createAsyncThunk('register', async (data: IRegisterRequestDTO, thunkAPI) => {
  try {
    return await authAPI.register(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'register');
    return thunkAPI.rejectWithValue(error);
  }
});

export const login = createAsyncThunk('login', async (data: ILoginRequestDTO, thunkAPI) => {
  try {
    return await authAPI.login(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'login');
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCurrentUser = createAsyncThunk('getCurrentUser', async (_, thunkAPI) => {
  try {
    return await authAPI.getCurrentUser();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getCurrentUser');
    return thunkAPI.rejectWithValue(error);
  }
});

export const refreshCurrentUser = createAsyncThunk('refreshCurrentUser', async (_, thunkAPI) => {
  try {
    return await authAPI.getCurrentUser();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'refreshCurrentUser');
    return thunkAPI.rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk('resetPassword', async (data: IResetPasswordRequestDTO, thunkAPI) => {
  try {
    const response = await authAPI.resetPassword(data);
    ToastNotifications.success(response.data);
    return response;
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'resetPassword');
    return thunkAPI.rejectWithValue(error);
  }
});

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setRedirectRouteAfterRegistration: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        localStorage.setItem('redirectRouteAfterRegistration', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('redirectRouteAfterRegistration');
      }

      state.redirectRouteAfterRegistration = action.payload;
    },
    toggleLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    toggleRegistrationModal: (state, action) => {
      state.showRegistrationodal = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = undefined;
      storageService.clear();
      window.location.href = '/';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.fulfilled, (state, action: PayloadAction<any>) => {
        // Automatic login and set up tokens only for client role
        if (
          action.payload.data.user.role.role === RolesEnum.CLIENT_INDIVIDUAL ||
          action.payload.data.user.role.role === RolesEnum.CLIENT_CHILDREN
        ) {
          state.isAuthenticated = !!action.payload.data.token;
          state.token = action.payload.data.token;
          state.user = action.payload.data.user;
          storageService.setToken(action.payload.data.token);

          if (state.redirectRouteAfterRegistration) {
            window.location.href = state.redirectRouteAfterRegistration;
            localStorage.removeItem('redirectRouteAfterRegistration');
            state.redirectRouteAfterRegistration = undefined;
          } else {
            window.location.href = RoutesEnum.SUGGESTED_THERAPISTS;
          }
        } else {
          window.location.href = RoutesEnum.DASHBOARD;
        }
      })
      .addCase(register.rejected, (state) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = undefined;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = !!action.payload.data.token;
        state.token = action.payload.data.token;
        state.user = action.payload.data.user;
        storageService.setToken(action.payload.data.token);

        if (state.redirectRouteAfterRegistration) {
          window.location.href = state.redirectRouteAfterRegistration;
          localStorage.removeItem('redirectRouteAfterRegistration');
          state.redirectRouteAfterRegistration = undefined;
        } else {
          window.location.href = RoutesEnum.DASHBOARD;
        }
      })
      .addCase(login.rejected, (state) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = undefined;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.token = null;
        window.location.href = RoutesEnum.HOME;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(refreshCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.user = action.payload.data;
        state.loading = false;
      });
  },
});

export const { toggleLoginModal, toggleRegistrationModal, logout, setRedirectRouteAfterRegistration } = authSlice.actions;

export default authSlice.reducer;
