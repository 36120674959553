import { setHours, setMinutes } from 'date-fns';

import { range } from 'src/utils/range';
import { config } from 'src/config/config';

export const getDefaultAvailableTimesForDatepicker = (selectedDate: Date) => {
  const hours = range(config.MIN_HOUR_TIMESLOT, config.MAX_HOUR_TIMESLOT);
  let includeTimes = [];

  hours.forEach((h) => {
    const time = setHours(setMinutes(selectedDate, 0), h);
    time.setSeconds(0)
    includeTimes = [...includeTimes, time];
  });

  return includeTimes;
};
