import React from 'react';
import styled from 'styled-components';

import { SelectDropdown, Flex, Textarea, Text } from 'src/components/common';
import { SelectDropdownValueType } from 'src/components/common/select-dropdown/SelectDropdown.types';

import { Question } from 'src/types/Question';
import { Answer } from 'src/types/Answer';
import { QuestionChoiceTypeEnum } from 'src/constants/QuestionChoiceTypeEnum';
import { useTranslation } from 'react-i18next';

const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => props.height};
  background-color: transparent;
  align-items: ${(props) => props.horizontalAlignment || 'center'};
  justify-content: center;
  border-radius: 16px;
`;

interface QuestionnaireListProps {
  questions: Question[];
  handleAnswering: (value: string | number, questionId: number) => void;
  showEmptyMessage: boolean;
  height?: string;
}

export const QuestionnaireList = ({ questions, handleAnswering, showEmptyMessage = true, height = 'auto' }: QuestionnaireListProps) => {
  const { t } = useTranslation();

  const getDropdownOptions = (answers: Answer[]) => {
    return answers.map((a) => {
      return {
        label: a.answer.toString(),
        value: a.id,
      };
    });
  };

  return (
    <QuestionnaireWrapper height={height}>
      {showEmptyMessage && questions.length === 0 && (
        <Text variant={'paragraph2'} width={'75%'} text={t('questionaire_1')} margin={'0 0 32px 0'} textAlign="center" />
      )}

      {questions.map((item, index) => {
        return (
          <Flex key={index} flexDirection="column" margin="0 0 24px 0" width={'100%'}>
            {item.answer_type.type === QuestionChoiceTypeEnum.TEXT && (
              <Textarea
                value={item.answered as string}
                placeholder={item.question}
                minChars={item.minChars && item.minChars > 0 ? item.minChars : undefined}
                rows={5}
                width="100%"
                onChange={(e) => {
                  handleAnswering(e.target.value, item.id);
                }}
              />
            )}

            {item.answer_type.type === QuestionChoiceTypeEnum.DROPDOWN && (
              <SelectDropdown
                placeholder={item.question}
                options={getDropdownOptions(item.answers)}
                value={item.answered as string}
                width={'100%'}
                onChange={(value) => {
                  handleAnswering(value, item.id);
                }}
              />
            )}

            {item.answer_type.type === QuestionChoiceTypeEnum.CHECKBOX && (
              <SelectDropdown
                isMultiple={true}
                placeholder={item.question}
                options={getDropdownOptions(item.answers)}
                value={item.answered as SelectDropdownValueType}
                maxSelectable={item.maxChars || undefined}
                width={'100%'}
                onChange={(value) => {
                  handleAnswering(value, item.id);
                }}
              />
            )}
          </Flex>
        );
      })}
    </QuestionnaireWrapper>
  );
};
