import React from 'react';
import { useTheme } from 'styled-components';
import ReactPlayer from 'react-player';

import { Flex, Modal, Text } from 'src/components/common';
import { useTranslation } from 'react-i18next';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const VideoModal = ({ isOpen, onClose }: VideoModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection="column" gap="16px">
        <Text variant={'heading5'} text={t('for_therapists_10')} />
        <ReactPlayer className="react-player fixed-bottom" url="/video.mp4" width="100%" height="100%" controls={true} playing={true} />
      </Flex>
    </Modal>
  );
};
