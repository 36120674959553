import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { jsonToFormData } from 'src/utils/json-to-form-data';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { useAppDispatch } from 'src/store';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { updateUser } from 'src/store/slices/userSlice';
import { User } from 'src/types/User';
import { patternsRegexp } from 'src/constants/patterns-regexp';
import { useTranslation } from 'react-i18next';

export interface EditProfileFormState {
  username: string;
  email: string;
  phone: string;
  price: number;
  details: string;
  password: string;
  repeatPassword: string;
}

interface useEditProfileFormProps {
  profileData: User;
  closeModal: () => void;
  setSelectedImage: (file: File | null) => void;
  optOutEnabled: boolean;
  freeSessionEnabled: boolean;
  earlyBookingEnabled: boolean;
}

const useEditProfileForm = ({
  profileData,
  closeModal,
  setSelectedImage,
  optOutEnabled,
  freeSessionEnabled,
  earlyBookingEnabled,
}: useEditProfileFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const useEditProfileFormchema = yup.object().shape({
    username: yup.string().required(t('error_username_required')),
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
    phone: yup
      .string()
      .nullable()
      .notRequired()
      .test('phone', t('error_phone'), (value: string) => !value || patternsRegexp.phone.test(value)),
    price: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    details: yup.string().nullable(),
    password: yup.string().min(6, t('error_password_required')),
    repeatPassword: yup.string().oneOf([yup.ref('password'), null], t('error_password_repeat_match')),
  });

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [formDefaultValues] = useState<EditProfileFormState>({
    username: profileData?.username,
    email: profileData?.email,
    phone: profileData?.phone,
    price: profileData?.price ?? 0,
    details: profileData?.details,
    password: undefined,
    repeatPassword: undefined,
  });

  const editProfileForm = useForm({
    resolver: yupResolver(useEditProfileFormchema),
    defaultValues: formDefaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: EditProfileFormState, selectedImage: File | null) => {
    setIsSubmittingForm(true);

    // send to backend only data that exists
    const dataWithoutEmptyFields = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

    const dataToSendToBackend = {
      ...dataWithoutEmptyFields,
      ...(selectedImage && { file: selectedImage }), // send photo only if exists
      status: optOutEnabled ? 'opt-out' : 'verified', // update status if opt enabled,
      freeSession: freeSessionEnabled,
      earlyBooking: earlyBookingEnabled,
    };

    const formDataBody = jsonToFormData(dataToSendToBackend);

    try {
      const response: any = await dispatch(updateUser(formDataBody));

      if (response.payload.success) {
        dispatch(refreshCurrentUser());
        ToastNotifications.success(response.payload.data, 'editProfileSuccess');
        closeModal();
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'editProfileSubmit');
    } finally {
      setSelectedImage(null);
      setIsSubmittingForm(false);
    }
  };

  return {
    formDefaultValues,
    editProfileForm,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useEditProfileForm };
