import React, { useContext, useState } from 'react';
import { Button, Flex, Modal, Text } from 'src/components/common';
import { User } from 'src/types/User';
import { useTranslation } from 'react-i18next';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';

interface Props {
  therapist: User;
  isOpen: boolean;
  onClose: () => void;
}

export const FreeSessionModal = ({ isOpen, onClose, therapist }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const [loading, setLoading] = useState(false);

  async function requestFreeSession() {
    try {
      const response = await appointmentsAPI.requestFreeSession({ therapistId: therapist.id });
      if (response.success) {
        ToastNotifications.success(response.data, 'createAppointment');
        onClose();
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'createAppointment');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'10px'}>
        <Text
          variant="heading7"
          text={t('free_session_1')}
          margin={'32px 0 32px 0'}
          width={'100%'}
          textAlign={'center'}
          whiteSpace={'pre-wrap'}
        />
        <Button
          variant={'accentDefault'}
          text={t('btn_request_free_session')}
          minWidth="250px"
          onClick={requestFreeSession}
          isLoading={loading}
          width={isMobile ? '100%' : 'auto'}
        />
      </Flex>
    </Modal>
  );
};
