import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const PasswordShow = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', className = '', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} style={style} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path fill={color} d="M12 14a2 2 0 100-4 2 2 0 000 4z" />
      <path fill={color} d="M12 4C5 4 1 12 1 12s4 8 11 8 11-8 11-8-4-8-11-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z" />
    </svg>
  );
};
