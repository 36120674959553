import styled from 'styled-components';

import { Flex } from 'src/components/common';
import { SelectDropdownProps } from './SelectDropdown.types';

export const OptionWrapper = styled(Flex)`
  ${({ theme: { colors } }) => `
    padding: 16px 16px;
    width: 100%;
    gap: 8px;
    align-items: center;
    border-bottom: 0.1px solid #EBEBEB;
    .label{
      white-space: pre-line;
      overflow: hidden;
      display: inline-block;
    }
    &:hover{
      background: ${colors.background.secondary.BG1}
    }
  `}
`;

export const SelectWrapper = styled.div<Pick<SelectDropdownProps, 'width' | 'variant' | 'maxWidth' | 'minWidth' | 'margin'>>`
  width: ${(props) => props.width};
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => props.margin ?? '0px'};
  min-width: ${(props) => props.minWidth ?? 'unset'};
  max-width: ${(props) => props.maxWidth ?? 'unset'};
  position: relative;
  background-color: transparent;
  gap: 4px;

  .basic-single,
  .basic-multi-select {
    cursor: pointer;
    position: relative;
    box-shadow: none;
    background-color: transparent;
    height: auto;

    * {
      outline: none;
      cursor: pointer;
    }

    .select__control {
      height: 48px;
      border-width: 1px;
      border-style: solid;
      border-radius: 50px;
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
      border-color: ${(props) => props.theme.colors.border.darker} !important;
      background-color: rgb(250, 250, 250);
      min-height: unset;

      &:active,
      &:focus-visible,
      &:focus-within,
      &:focus,
      &:hover {
        border-color: ${(props) => props.theme.colors.palette.purple} !important;

        svg {
          > path[stroke] {
            stroke: ${(props) => props.theme.colors.palette.purple};
          }

          > path[fill] {
            fill: ${(props) => props.theme.colors.palette.purple};
          }

          path {
            color: ${(props) => props.theme.colors.palette.purple};
          }
        }
      }

      &.select__control--is-disabled {
        background-color: ${(props) =>
                props.theme.colors.background.secondary.BG1} !important;
        border-color: ${(props) =>
                props.theme.colors.background.secondary.BG1} !important;

        .select__single-value {
          color: ${(props) => props.theme.colors.text.disabled} !important;
        }
      }

      &--menu-is-open {
        .select__dropdown-indicator {
          transform: rotate(180deg);
          padding: 0 0 0 8px;
        }
      }
    }

    .select__menu {
      z-index: 10000;
      background-color: ${(props) => props.theme.colors.background.main};

      .label {
        color: ${(props) => props.theme.colors.text.primary};
      }

      input {
        background-color: ${(props) => props.theme.colors.background.main} !important;
        color: ${(props) => props.theme.colors.text.primary};
      }

      svg {
        > path[stroke] {
          stroke: ${(props) => props.theme.colors.text.secondary} !important;
        }

        > path[fill] {
          fill: ${(props) => props.theme.colors.text.secondary} !important;
        }

        path {
          color: ${(props) => props.theme.colors.text.secondary} !important;
        }
      }

      .select__menu-list {
        width: 100%;
        div[id*='option'],
        div[id*='react-select'] {
          &:hover {
            background-color: ${(props) => props.theme.colors.background.secondary.BG1};
          }
        }

        &.select__menu-list--is-multi {
          span {
            // style here the checkbox square
          }
        }
      }
    }

    .select__indicator {
      padding: 0 8px 0 0;
    }

    .select__value-container {
      padding: 2px 12px;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__single-value {
      color: ${(props) => props.theme.colors.text.primary};
    }

    .select__option {
      background-color: ${(props) => props.theme.colors.palette.white};
      color: ${(props) => props.theme.colors.text.primary};
      font-size: ${(props) => props.theme.typography.variants.paragraph2.fontSize};
      font-family: ${(props) => props.theme.typography.variants.paragraph2.fontFamily};

      &-is-selected,
      &--is-focused {
        background-color: ${(props) => props.theme.colors.palette.purple};
        color: ${(props) => props.theme.colors.palette.white};
      }
    }
  }

  .basic-multi-select {
    .select__menu {
      > div {
        > div:first-child:not(.select__menu-list) {
          &:hover {
            background-color: ${(props) => props.theme.colors.background.secondary.BG1};
          }
        }
      }
    }
  }

  .select__value-container--is-multi.select__value-container--has-value {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    padding: 2px 10px;
    color: ${(props) => props.theme.colors.text.primary} !important;
  }

  .select-control,
  .select__placeholder {
    font-size: ${(props) => props.theme.typography.variants.paragraph1.fontSize};
    font-weight: ${(props) => props.theme.typography.variants.paragraph1.fontWeight};
    line-height: ${(props) => props.theme.typography.variants.paragraph1.lineHeight};
    font-family: ${(props) => props.theme.typography.variants.paragraph1.fontFamily};
  }

  ${(props) => props.theme.breakpoints.md} {
    .select__placeholder,
    .select-control {
      font-size: ${(props) => props.theme.typography.variants.paragraph1.fontSize};
      font-weight: ${(props) => props.theme.typography.variants.paragraph1.fontWeight};
      line-height: ${(props) => props.theme.typography.variants.paragraph1.lineHeight};
      font-family: ${(props) => props.theme.typography.variants.paragraph1.fontFamily};
    }
  }

  .select__placeholder {
    color: ${(props) => props.theme.colors.text.disabled};
    font-size: ${(props) => props.theme.typography.variants.paragraph2.fontSize};
    font-family: ${(props) => props.theme.typography.variants.paragraph2.fontFamily};
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: contents;
  position: absolute;
  bottom: -25px;
  left: 0;
`;
