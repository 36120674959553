import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  margin?: string;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  children?: ReactElement | ReactElement[];
}

const InlineText = styled.span`
  display: inline;
  text-align: ${(props) => props.textAlign ?? 'left'};
  line-height: 24px;
  margin: ${(props) => props.margin ?? '0px'};

  * {
    display: inline !important;
    word-break: break-word !important;
  }

  a {
    cursor: pointer;
  }
`;

export const InlineTextWrapper = ({ children, margin, textAlign }: Props) => {
  return (
    <InlineText margin={margin} textAlign={textAlign}>
      {children}
    </InlineText>
  );
};
