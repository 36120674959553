import { httpService } from 'src/config/http';
import { Appointment } from 'src/types/Appointment';
import { ApiResponse } from 'src/types/ApiResponse';
import { CreateAppointmentRequestDTO, UpdateAppointmentRequestDTO, PayAppointmentRequestDTO, RequestFreeSessionDTO } from './RequestDtos';

export default class AppointmentsAPI {
  public createAppointment(data: CreateAppointmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments', data);
  }

  public createAppointmentWithVoucher(data: CreateAppointmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/voucher', data);
  }
  public payAppointmentWithVoucher(data: PayAppointmentRequestDTO) {
    return httpService.post<ApiResponse<any>>('/appointments/voucher/pay', data);
  }

  public cencelAppointment(appointmentId: number) {
    return httpService.post<ApiResponse<any>>(`/appointments/cancel/${appointmentId}`);
  }

  public updateAppointment(appointmentId: string, data: UpdateAppointmentRequestDTO) {
    return httpService.patch<Appointment>(`/appointments/${appointmentId}`, data);
  }

  public deleteAppointment(appointmentId: string) {
    return httpService.delete(`/appointments/${appointmentId}`);
  }

  public requestFreeSession(data: RequestFreeSessionDTO) {
    return httpService.post<ApiResponse<string>>(`/free-session`, data);
  }
}

export const appointmentsAPI = new AppointmentsAPI();
