import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sha512 } from 'js-sha512';
import { User } from 'src/types/User';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { subscribe, pay } from 'src/store/slices/subscriptionsSlice';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { delay } from 'src/utils/delay';

interface MontriPymentInputsProps {
  user: User;
  onPaymentSuccessful: () => void;
}

export const MonriPaymentInputs = ({ user, onPaymentSuccessful }: MontriPymentInputsProps) => {
  const dispatch = useAppDispatch();
  const params = useQueryParams();

  const { selectedPlan } = useAppSelector((state: RootState) => state.subscriptions);
  const [searchParams, setSearchParams] = useSearchParams();
  const { session } = useAppSelector((state: RootState) => state.session);

  const key = process.env.REACT_APP_MONRI_KEY;
  const token = process.env.REACT_APP_MONRI_TOKEN;

  const orderInfo = useMemo(() => {
    return session ? `Posredovanje za psihoterapijsku sesiju` : `${selectedPlan?.name} - ${selectedPlan?.price}KM`;
  }, [session, selectedPlan]);

  const calculatedPrice = useMemo(() => {
    return session?.therapist?.price
      ? (session?.therapist?.price ?? 0) + Number(process.env.REACT_APP_PRICE_FEE ?? 0)
      : selectedPlan?.price;
  }, [session, selectedPlan]);

  const price = useMemo(() => {
    return Number(calculatedPrice * 100).toFixed(0);
  }, [calculatedPrice]);

  const orderNumber = `PSHT-${Date.now()}`;
  const digest = `${key}${orderNumber}${price}BAM`;
  const digestHashed = sha512(digest);

  const clearQueryParams = () => {
    const newParams: { [key: string]: string } = {};

    searchParams.forEach((value: string, key: string) => {
      searchParams.delete(key);
    });

    setSearchParams(newParams);
  };

  const subscribeToPlan = async (redirectUrl: string) => {
    if (!selectedPlan) return;

    const response: any = await dispatch(
      subscribe({
        planId: selectedPlan.id,
        redirectUrl: `${window.location.protocol}//${window.location.hostname}/dashboard?${redirectUrl}`,
      })
    );
    if (response.payload?.success) {
      ToastNotifications.success(response.payload?.data);
      await dispatch(refreshCurrentUser());
    }

    await delay(500);
    clearQueryParams();
  };

  const payForAppointment = async (redirectUrl: string) => {
    if (!session) return;

    const response: any = await dispatch(
      pay({
        appointmentId: session.id,
        redirectUrl: `${window.location.protocol}//${window.location.hostname}/dashboard?${redirectUrl}`,
      })
    );
    
    if (response.payload?.success) {
      ToastNotifications.success(response.payload?.data);
      await dispatch(refreshCurrentUser());
      onPaymentSuccessful();
    }

    await delay(500);
    clearQueryParams();
  };

  useEffect(() => {
    if (params.get('approval_code') && params.get('approval_code').length > 0) {
      if (selectedPlan) {
        // User paid for selected plan
        subscribeToPlan(params.toString());
      } else if (session) {
        // User paid for selected appointment
        payForAppointment(params.toString());
      }
    }
  }, [params]);

  return (
    <>
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="authenticity_token" value={token ?? ''} />
      <input type="hidden" name="ch_full_name" id="ch_full_name" value={user.username ?? ''} />
      <input type="hidden" name="ch_address" id="ch_address" value={''} />
      <input type="hidden" name="ch_city" id="ch_city" value={''} />
      <input type="hidden" name="ch_zip" id="ch_zip" value={''} />
      <input type="hidden" name="ch_country" id="ch_country" value="Bosna i Hercegovina" />
      <input type="hidden" name="ch_phone" id="ch_phone" value={user.phone ?? ''} />
      <input type="hidden" name="ch_email" id="ch_email" value={user.email ?? ''} />
      <input type="hidden" name="order_info" id="order_info" value={orderInfo ?? ''} />
      <input type="hidden" name="amount" id="amount" value={price ?? ''} />
      <input type="hidden" name="order_number" id="order_number" value={orderNumber ?? ''} />
      <input type="hidden" name="currency" id="currency" value="BAM" />
      <input type="hidden" name="tokenize_pan" id="tokenize_pan" value="true" />
      <input type="hidden" name="transaction_type" id="transaction_type" value="purchase" />
      <input type="hidden" name="authenticity_token" id="authenticity_token" value={token ?? ''} />
      <input type="hidden" name="digest" id="digest" value={digestHashed ?? ''} />
      <input type="hidden" name="language" id="language" value="ba" />
    </>
  );
};
