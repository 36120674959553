import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -msbox-sizing: border-box;
  }
  /* do not delete */
  /* to remove background screen cover loaded by recaptcha lib */
  div[style*="z-index: 2000000000"] {
   div:first-child {
     display: none;
   }
  }
  
  body {
    min-height: 100vh;
    width:100%;
    color: ${(props) => props.theme.colors.text.primary};
    margin: 0 !important;
    padding: 0;
    font-family: ${(props) => props.theme.typography.fontFamily};
    position: relative;
    overflow-x: hidden;
    background: rgb(246, 246, 246);
  }

  .wf-loading > * {
    opacity: 0;
  }

  .ie.wf-loading > * {
    visibility: hidden; /* Old IE */
  }

 * {
    opacity: 1;
    visibility: visible; /* Old IE */
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }

  a {
    text-decoration: none;
    color: inherit;
    
    &:hover,
    :visited {
      color: inherit;
    }
  }

  #root {
    height: 100%;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    min-height: 100vh;
    width: 100%;

    @media only screen and (max-width: 768px) {
      overflow-x: hidden;
    }
  }

  label.required::after {
    content: "*";
    color: ${(props) => props.theme.colors.palette.red};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-family: ${(props) => props.theme.typography.fontFamily};
  }

  textarea {
    font-family: ${(props) => props.theme.typography.fontFamily};
  }
  
  .Toastify__toast-container {
    z-index: 10000;
    top: 88px;
    max-width: 100%;

    .Toastify__close-button > svg {
      display: none;
    }
    
    .Toastify__toast {
      border-radius: 6px;
      padding: 14px 16px;
      box-shadow: none;
      max-width: 100%;

      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        max-width: 95%;
        margin: 0 auto;
      }

      &--success {
        background: ${(props) => props.theme.colors.palette.lightOrDarkGreen};
        border: 1px solid ${(props) => props.theme.colors.palette.green};
      }
      
      &--error {
        background: ${(props) => props.theme.colors.palette.lightOrDarkRed};
        border: 1px solid ${(props) => props.theme.colors.palette.red};
      }
    }
  }

  .bm-overlay {
    z-index: 9001 !important;
  }

  .bm-menu {
    overflow: scroll !important;
    padding-bottom: 65px;
    
    .bm-item-list {
      min-height: 100%;
      overflow: scroll;
    }
  }

  .bm-menu-wrap {
        z-index: 9002 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
