import { toast, cssTransition } from 'react-toastify';
import { Text } from 'src/components/common';

const commonOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
};

const fade = cssTransition({
  enter: 'animate__animated animate__fadeIn',
  exit: 'animate__animated animate__fadeOut',
});

export class ToastNotifications {
  static error(message: string, toastId?: string) {
    return toast(<Text variant={'paragraph2'} text={message} />, {
      ...commonOptions,
      ...(toastId && { toastId }),
      type: 'error',
      transition: fade,
    });
  }

  static success(message: string, toastId?: string) {
    return toast(<Text variant={'paragraph2'} text={message} />, {
      ...commonOptions,
      ...(toastId && { toastId }),
      type: 'success',
      transition: fade,
    });
  }
}
