import React, { useState, useCallback, useContext } from 'react';
import classnames from 'classnames';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { AccordionSection, Dropdown, AccordionTitleWrap, AccordionItemWrap } from './styles';
import { ChevronDown } from 'src/components/icons/ui-icons';
import { Grid, Text } from 'src/components/common';

export interface AccordionItem {
  id: number;
  title: string;
  content: string;
}

interface AccordionProps {
  panels: AccordionItem[];
  columns?: 1 | 2;
}

function splitHalf(arr: Array<any>) {
  const half = Math.ceil(arr.length / 2);
  const left = arr.slice(0, half);
  const right = arr.slice(half);

  return [left, right];
}

export const Accordion = ({ panels, columns = 1 }: AccordionProps) => {
  const { isMobile } = useContext(IsMobileContext);
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

  const expandAccordionItem = useCallback(
    (index: number) => {
      if (expandedIndex === index) {
        setExpandedIndex(undefined);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex]
  );

  const renderPanels = (panelsGroup: Array<any>) => {
    return panelsGroup.map((item) => (
      <AccordionItemWrap key={item.id}>
        <AccordionTitleWrap
          onClick={() => {
            expandAccordionItem(item.id);
          }}
        >
          <Text variant="paragraph1">{item.title}</Text>

          <ChevronDown
            className={classnames('', {
              expanded: expandedIndex === item.id,
            })}
          />
        </AccordionTitleWrap>

        <Dropdown
          className={classnames('dropdown-expanded', {
            show: expandedIndex === item.id,
          })}
        >
          <Text variant="paragraph2" style={{ opacity: 0.6 }} padding={'0 0 24px 0'} whiteSpace={'pre-wrap'}>
            {item.content}
          </Text>
        </Dropdown>
      </AccordionItemWrap>
    ));
  };

  return columns === 1 ? (
    <AccordionSection>{renderPanels(panels)}</AccordionSection>
  ) : (
    <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={isMobile ? '0px' : '80px'}>
      {splitHalf(panels).map((panelsGroup, index) => {
        return <AccordionSection key={index}>{renderPanels(panelsGroup)}</AccordionSection>;
      })}
    </Grid>
  );
};
