import { httpService } from 'src/config/http';
import { Feedback } from 'src/types/Feedback';
import { SubmitFeedbackRequestDTO } from './RequestDtos';
import { ApiResponse } from 'src/types/ApiResponse';

export default class FeedbackAPI {
  public getFeedback() {
    return httpService.get<ApiResponse<Feedback[]>>('/feedback');
  }

  public submitFeedback(data: SubmitFeedbackRequestDTO) {
    return httpService.post('/feedback', data);
  }
}

export const feedbackAPI = new FeedbackAPI();
