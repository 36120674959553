import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Question } from 'src/types/Question';
import { questionnaireAPI } from 'src/api/questionnaire/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { RolesEnum } from 'src/constants/RolesEnum';

export interface QuestionsState {
  loading: boolean;
  questions: Question[];
}

const initialState: QuestionsState = {
  loading: false,
  questions: [],
};

export const getQuestionsByRole = createAsyncThunk('getQuestionsByRole', async (role: RolesEnum, thunkAPI) => {
  try {
    return await questionnaireAPI.getQuestionsByRole(role);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getQuestionsByRole');
    return thunkAPI.rejectWithValue(error);
  }
});

export const questionsSlice = createSlice({
  name: 'questionsSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getQuestionsByRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionsByRole.fulfilled, (state, action: any) => {
        state.loading = false;
        state.questions = action.payload.data
          .filter((question: Question) => !question.hide)
          .sort()
          .reverse();
      })
      .addCase(getQuestionsByRole.rejected, (state) => {
        state.loading = false;
        state.questions = [];
      });
  },
});

export default questionsSlice.reducer;
