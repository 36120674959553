import React, { useContext, useMemo } from 'react';
import { RootState, useAppSelector } from 'src/store';
import { Button, Flex, Text, Card } from 'src/components/common';
import { AppointmentStatus } from 'src/constants/AppointmentStatus';
import { getAppointDate } from 'src/utils/date-parser';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Appointment } from 'src/types/Appointment';
import { InlineTextWrapper } from 'src/components/shared';
import { useTranslation } from 'react-i18next';

interface UpcomingSessionProps {
  startZoomSession: (session: Appointment) => void;
  startChat: (session: Appointment) => void;
  cancelSession: (session: Appointment) => void;
}

export const UpcomingSession = ({ startZoomSession, startChat, cancelSession }: UpcomingSessionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const oneHourMilliseconds = 60 * 60 * 1000; // One hour in milliseconds
  const minBeforeEnabled = 5; // Minutes before start button will be enabled until appointment has to start

  const upcomingSession = useMemo(() => {
    return (
      user?.appointments
        ?.slice()
        ?.filter((session) => session.paid)
        ?.sort((a, b) => a.date - b.date)
        ?.find((session) => sessionInProgress(session)) ?? null
    );
  }, [user]);

  function getDiffFromNowInMinutes(session: Appointment) {
    const diffMillisecons = new Date(session.date).getTime() - new Date().getTime();
    return diffMillisecons / 1000 / 60;
  }

  function sessionInProgress(session: Appointment) {
    return session.date > Date.now() || session.date + oneHourMilliseconds >= Date.now();
  }

  function sessionDidPass(session: Appointment) {
    const extraHour = 3600000; // Hide start session button after session expires
    const extra5min = 300000; // also add extra 5 minutes
    return new Date(session.date).getTime() + extraHour + extra5min >= new Date().getTime();
  }

  return (
    <>
      <Flex flexDirection="column" width={'100%'} gap="24px">
        <Text variant={'heading6'} text={t('sessions_10')} />

        <Card width={'100%'} borderRadius={'20px'}>
          {!upcomingSession && (
            <Flex width={'100%'} height={'100%'} flexDirection={'column'} gap="20px" justifyContent={'center'} alignItems={'center'}>
              <Text variant={'paragraph1'} text={t('sessions_11')} />
            </Flex>
          )}

          {upcomingSession && (
            <Flex flexDirection={'column'} gap={'16px'} width={'100%'}>
              <Text variant={'paragraph2'} text={t('sessions_12')} />

              <Flex
                flexDirection={isMobile ? 'column' : 'row'}
                width={'100%'}
                gap={'16px'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Flex flexDirection="column" gap={'8px'}>
                  <InlineTextWrapper>
                    <Text variant={'paragraph1'} text={`${t('sessions_5')} `} />
                    <Text variant={'paragraph1'} text={getAppointDate(upcomingSession.date)} style={{ fontFamily: 'Barlow-Bold' }} />
                  </InlineTextWrapper>
                  <InlineTextWrapper>
                    <Text
                      variant={'paragraph1'}
                      text={upcomingSession.therapist.id == user.id ? `${t('sessions_6')} ` : `${t('sessions_7')} `}
                    />
                    <Text
                      variant={'paragraph1'}
                      text={upcomingSession.therapist.id == user.id ? upcomingSession.client.username : upcomingSession.therapist.username}
                      style={{ fontFamily: 'Barlow-Bold' }}
                    />
                  </InlineTextWrapper>
                </Flex>

                <Flex flexDirection={isMobile ? 'column' : 'row'} gap="10px" width={isMobile ? '100%' : 'auto'}>
                  {upcomingSession.status === AppointmentStatus.ACCEPTED && sessionDidPass(upcomingSession) && (
                    <Button
                      variant={'accentDefault'}
                      text={t('btn_start_session')}
                      width={isMobile ? '100%' : 'auto'}
                      disabled={getDiffFromNowInMinutes(upcomingSession) > minBeforeEnabled}
                      onClick={() => startZoomSession(upcomingSession)}
                    />
                  )}

                  {upcomingSession.status === AppointmentStatus.PENDING && (
                    <Button
                      variant={'primaryDefault'}
                      text={t('btn_session_on_hold')}
                      minWidth="250px"
                      width={isMobile ? '100%' : 'auto'}
                      disabled
                    />
                  )}

                  {upcomingSession.status === AppointmentStatus.REJECTED && (
                    <Button
                      variant={'primaryDefault'}
                      text={t('btn_session_canceled')}
                      minWidth="250px"
                      width={isMobile ? '100%' : 'auto'}
                      disabled
                    />
                  )}

                  <Button
                    variant={'secondaryAccentDefault'}
                    text={t('btn_send_message')}
                    width={isMobile ? '100%' : 'auto'}
                    onClick={() => startChat(upcomingSession)}
                  />

                  <Button
                    variant={'secondaryRedDefault'}
                    text={t('btn_cancel')}
                    width={isMobile ? '100%' : 'auto'}
                    onClick={() => cancelSession(upcomingSession)}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </>
  );
};
