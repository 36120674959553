import styled, { css } from 'styled-components';

import { ModalSize } from 'src/types/Modal';

export const getModalStyles = (size: ModalSize, width?: string) => {
  switch (size) {
    case 'small':
      return css`
        max-width: ${width ? 'unset' : '300px'};
        width: ${width ?? ' 300px'};
        max-height: 95%;
      `;
    case 'large':
      return css`
        max-width: 800px;
        width: ${width ?? ' 800px'};
        max-height: 95%;
      `;
    case 'full-screen':
      return css`
        width: 100%;
        max-height: 100%;
      `;
    case 'medium':
      return css`
        max-width: 500px;
        width: ${width ?? ' 500px'};
        max-height: 95%;
      `;
  }
};

export const Backdrop = styled.div<{ backdropBlur: boolean }>`
  background-color: rgba(14, 14, 14, 0.7);
  backdrop-filter: ${(props) => (props.backdropBlur ? 'blur(8px)' : 'unset')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    align-items: flex-start;
  }
`;

export const StyledModal = styled.div<{
  modalStyles: any;
}>`
  z-index: 9999;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  min-height: 100px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  outline: none;
  -webkit-overflow-scrolling: touch;
  ${(props) => props.modalStyles};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 95%;
    border-radius: 8px;
    padding: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const CloseButton = styled.button`
  border: 0;
  padding: 0;
  outline: 0;
  top: 24px;
  right: 24px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  display: flex;
  z-index: 9999;
`;
