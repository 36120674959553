import React, { useContext, useState, useCallback, useRef } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { RootState, useAppSelector } from 'src/store';
import { useTheme } from 'styled-components';
import { Button, Flex, Text, Card } from 'src/components/common';
import { User } from 'src/types/User';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { ChatModal, InlineTextWrapper } from 'src/components/shared';
import { RoutesEnum } from 'src/routes';
import { GetMatchedModal } from 'src/pages/home/modals/GetMatchedModal';
import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { RecommendTherapistModal } from './modals/RecommendTherapistModal';
import { useTranslation } from 'react-i18next';

export const ClientTherapists = () => {
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>();
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAppSelector((state: RootState) => state.auth);
  const { isOpen: isChatModalOpen, close: closeChatModal, open: openChatModal } = useDisclosure(false);
  const [recomendTherapist, setRecommendTherapist] = useState(undefined);
  const [selectedUserForChat, setSelectedUserForChat] = useState(undefined);
  const [imgHeight, setImgHeight] = useState<any>('auto');

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const initiateChatWithClient = (user: User) => {
    setSelectedUserForChat(user);
    openChatModal();
  };

  const showTherapistDetails = useCallback(
    (id: string | number) => {
      if (id) {
        window.location.href = generatePath(RoutesEnum.THERAPIST_DETAILS, { id: id.toString() });
      }
    },
    [navigate]
  );

  const { isOpen: isGetMatchedModalOpen, close: closeGetMatchedModal, open: openGetMatchedModal } = useDisclosure(false);

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'} overflow={'scroll'}>
      {selectedUserForChat && <ChatModal isOpen={isChatModalOpen} onClose={closeChatModal} targetUser={selectedUserForChat} />}

      <Text variant={'heading6'} text={t('client_therapists_1')} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'} padding={'24px'}>
        <Flex flexDirection="column" width={'100%'} height={isMobile ? '65vh' : '75vh'} gap={'32px'}>
          {!user?.therapists ||
            (user?.therapists?.length === 0 && (
              <Flex width={'100%'} height={'100%'} flexDirection={'column'} gap="20px" justifyContent={'center'} alignItems={'center'}>
                <Button
                  onClick={openGetMatchedModal}
                  text={t('btn_connect_with_therapist')}
                  width={isMobile ? '100%' : '50%'}
                  margin={'0 0 20px 0'}
                />
              </Flex>
            ))}

          {user?.therapists && user?.therapists?.length > 0 && (
            <Flex flexDirection="column" width={'100%'} height={isMobile ? '65vh' : '75vh'} gap={'16px'}>
              <Text text={t('client_therapists_2')} variant={'paragraph1'} width={'100%'} margin={'16px 0 16px 0'} />

              {user?.therapists?.map((therapist, index) => (
                <Flex key={index} flexDirection="column" width={'100%'} gap={'16px'}>
                  <Flex
                    width="100%"
                    flexDirection={isMobile ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                    gap={isMobile ? '20px' : '0px'}
                  >
                    <Flex flexDirection="row" alignItems="center" gap="10px">
                      <img
                        ref={onImgRefChange}
                        src={therapist?.photo ?? ProfilePhotoPlaceholder}
                        alt=""
                        width={'50px'}
                        height={imgHeight ?? 'auto'}
                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                      />
                      <Text variant={'heading7'} text={therapist.username} />
                    </Flex>
                    <Flex flexDirection={isMobile ? 'column' : 'row'} gap="10px" width={isMobile ? '100%' : 'auto'}>
                      <Button
                        variant={'infoDefault'}
                        text={t('btn_refer')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => setRecommendTherapist(therapist)}
                      />
                      <Button
                        variant={'primaryDefault'}
                        text={t('btn_see_profile')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => showTherapistDetails(therapist.id)}
                      />
                      <Button
                        variant={'secondaryDefault'}
                        text={t('btn_send_message')}
                        width={isMobile ? '100%' : 'auto'}
                        onClick={() => initiateChatWithClient(therapist)}
                      />
                    </Flex>
                  </Flex>
                  <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
                </Flex>
              ))}
              <InlineTextWrapper margin={'0 0 32px 0'}>
                <Text variant={'paragraph1'} text={t('client_therapists_3')} />
                <Text
                  variant={'paragraph1'}
                  text={t('client_therapists_4')}
                  style={{ fontFamily: 'Barlow-Bold' }}
                  onClick={openGetMatchedModal}
                />
                <Text variant={'paragraph1'} text={t('client_therapists_5')} />
              </InlineTextWrapper>
            </Flex>
          )}
        </Flex>
      </Card>

      <GetMatchedModal isOpen={isGetMatchedModalOpen} onClose={closeGetMatchedModal} />

      <RecommendTherapistModal therapist={recomendTherapist} onClose={() => setRecommendTherapist(undefined)} />
    </Flex>
  );
};
