import { httpService } from 'src/config/http';
import { User } from 'src/types/User';
import { Inbox } from 'src/types/Inbox';
import { Message } from 'src/types/Message';
import { IStatisticsResponseDTO } from 'src/api/user/ResponseDtos';
import { ApiResponse } from 'src/types/ApiResponse';

export default class UserAPI {
  public getUserDetails(userId: string) {
    return httpService.get<ApiResponse<User>>(`/users/details/${userId}`);
  }

  public getUserStatistics(userId: number) {
    return httpService.get<ApiResponse<IStatisticsResponseDTO>>(`/statistics/${userId}`);
  }

  public resetPassword(email: string) {
    return httpService.post(`/users/login?email=${email}`);
  }

  public getTherapistClients() {
    return httpService.get<User[]>('/users/therapist/clients');
  }

  public getSuggestedTherapists() {
    return httpService.get<ApiResponse<User[]>>('/users/therapist/matches');
  }

  public getInbox() {
    return httpService.get<ApiResponse<Inbox[]>>('/messages/inbox');
  }

  public getMessages(fromUserId: number, toUserId: number) {
    return httpService.get<ApiResponse<Message[]>>(`/messages?fromUserId=${fromUserId}&toUserId=${toUserId}`);
  }

  public setTherapistAvailability(data: number[]) {
    return httpService.post<ApiResponse<any>>('/users/therapist/availability', data);
  }

  public updateUser(data: FormData) {
    return httpService.post<ApiResponse<User>>('/users/me', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public deleteProfile() {
    return httpService.delete<ApiResponse<any>>('/users/me');
  }

  public deleteTherapistAvailability(availabilityId: string) {
    return httpService.delete<ApiResponse<any>>(`/users/therapist/availability/${availabilityId}`);
  }

  public recommendFriend(email: string) {
    return httpService.post(`/referral/invite?email=${email}`);
  }

  public uploadFiles(data: FormData) {
    return httpService.post<ApiResponse<any>>('/users/upload', data, {
      noAuthentication: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export const userAPI = new UserAPI();
