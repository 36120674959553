import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const PasswordHide = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', className = '', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} style={style} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        d="M18.6 17.4C21.4 15.1 23 12 23 12s-4-8-11-8c-2 0-3.8.7-5.3 1.6L4.6 3.4c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l2 2C2.6 8.9 1 12 1 12s4 8 11 8c2 0 3.8-.7 5.3-1.6l2.2 2.2c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.8 0-1.1l-2.1-2.1zM12 8c2.2 0 4 1.8 4 4 0 .8-.2 1.6-.7 2.2l-1.5-1.5c.1-.2.1-.5.1-.7 0-1.1-.9-2-2-2-.3 0-.5.1-.7.1L9.8 8.7c.6-.5 1.4-.7 2.2-.7zm0 8c-2.2 0-4-1.8-4-4 0-.8.2-1.6.7-2.2l1.5 1.5c-.1.2-.2.4-.2.7 0 1.1.9 2 2 2 .3 0 .5-.1.7-.1l1.5 1.5c-.6.4-1.4.6-2.2.6z"
      />
    </svg>
  );
};
