export const headerHeight = '80px';
export const maxHorizontalPadding = '200px';

export const countriesList = [
  'Bosna i Hercegovina',
  'Crna Gora',
  'Hrvatska',
  'Srbija',
  'Makedonija',
  'Slovenija',
  'Njemačka',
  'Austrija',
  'Amerika',
  'Kanada',
  'Nije na listi',
];
