import React from 'react';

import { Flex, Text } from 'src/components/common';

export const Forbidden = () => {
  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} flex="1">
      <Text variant={'heading3'} text={'403 - Forbidden'} />
    </Flex>
  );
};
