import { ErrorMessagesEnum } from 'src/constants/ErrorMessagesEnum';

export const parseError = (error, fallbackErrorMessage = 'Internal server error.') => {
  if (typeof error === 'string') {
    return error;
  }

  // Catch common non-response errors
  if (!error.status && error.message === 'Network Error') {
    return ErrorMessagesEnum.NETWORK_ERROR;
  }

  // Catch response errors
  if (error?.response && error?.response?.data && error?.response?.data?.error) {
    return error.response.data.error;
  }

  return error.response.data.error ?? fallbackErrorMessage;
};
