import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { storageService } from 'src/utils/storage';
import { config } from 'src/config/config';
import { RoutesEnum } from 'src/routes';

interface IInvokeOptions {
  noAuthentication?: boolean;
  headers?: any;
  responseType?: any;
}

const getDefaultHeaders = () => {
  const token = storageService.getToken();

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

// response interceptor
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      storageService.clear();
      window.location.href = RoutesEnum.HOME;
    }

    return Promise.reject(error);
  }
);

const invoke = <T>(method: string, url: string, data?: T, options?: IInvokeOptions) => {
  let defaultHeaders = getDefaultHeaders();

  if (options && options.noAuthentication) {
    delete defaultHeaders['Authorization'];
  }

  if (options && options.headers) {
    defaultHeaders = { ...defaultHeaders, ...options.headers };
  }

  let axiosObject: any = {
    method,
    url: `${config.API_BASE_URL}${url}`,
    headers: defaultHeaders,
    data,
  };

  if (options && options.responseType) {
    axiosObject = { ...axiosObject, responseType: options.responseType };
  }

  return axios(axiosObject as AxiosRequestConfig) as Promise<any>;
};

export const httpService = {
  get: <T>(url: string, options?: IInvokeOptions): Promise<T> => {
    return invoke<T>('GET', url, null, options);
  },

  post: <T>(url: string, data: any = null, options?: IInvokeOptions): Promise<T> => {
    return invoke<T>('POST', url, data, options);
  },

  put: <T>(url: string, data?: any, options?: IInvokeOptions): Promise<T> => {
    return invoke<T>('PUT', url, data, options);
  },

  patch: <T>(url: string, data?: any, options?: IInvokeOptions): Promise<T> => {
    return invoke<T>('PATCH', url, data, options);
  },

  delete: <T>(url: string, options?: IInvokeOptions): Promise<T> => {
    return invoke<T>('DELETE', url, null, options);
  },
};
