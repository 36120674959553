import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import ProfilePhotoPlaceholder from 'src/assets/images/profile-avatar.png';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Button, Flex, Grid, Text } from 'src/components/common';
import { BookTherapistModal } from './modals/BookTherapistModal';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getTherapistDetails } from 'src/store/slices/userSlice';
import { setRedirectRouteAfterRegistration, toggleRegistrationModal } from 'src/store/slices/authSlice';
import { RoutesEnum } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { FreeSessionModal } from './modals/FreeSessionModal';
import { RolesEnum } from 'src/constants/RolesEnum';

const StatisticsCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${(props) => props.theme.colors.background.secondary.BG4};
`;

export const TherapistDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const imgRef = useRef<HTMLImageElement>();

  const { isAuthenticated, user } = useAppSelector((state: RootState) => state.auth);
  const { therapistDetails } = useAppSelector((state: RootState) => state.user);

  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const [imgHeight, setImgHeight] = useState<any>('auto');

  const [searchParams] = useSearchParams();

  const { isOpen: showBookSession, close: closeBookSession, open: openBookSession } = useDisclosure(false);
  const { isOpen: showFeeSession, close: closeFreeSessionModal, open: openFreeSessionModal } = useDisclosure(false);

  const availableDatesToBook = useMemo(() => {
    return therapistDetails?.availabilities.map((a) => new Date(a.date));
  }, [therapistDetails]);

  const onBookSessionClick = useCallback(() => {
    if (isAuthenticated) {
      return openBookSession();
    }

    dispatch(setRedirectRouteAfterRegistration(`/details/${id}`));
    dispatch(toggleRegistrationModal(true));
  }, [isAuthenticated]);

  const onBackClick = useCallback(() => {
    return isAuthenticated ? navigate(-1) : navigate(RoutesEnum.HOME);
  }, [isAuthenticated]);

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const calculatePrice = () => {
    const price = Number(therapistDetails?.price ?? 0) + Number(process.env.REACT_APP_PRICE_FEE ?? 0);
    return price ? Number(price).toFixed(2) : 'N/A';
  };

  const calculatePriceEUR = () => {
    const price = Number(therapistDetails?.price ?? 0) + Number(process.env.REACT_APP_PRICE_FEE ?? 0);
    const eur = price / 1.95;
    return eur ? Number(eur).toFixed(2) : 'N/A';
  };

  useEffect(() => {
    if (id) {
      dispatch(getTherapistDetails(id));
    }

    if (searchParams.get('bookSession')) {
      openBookSession();
    }
  }, [searchParams, id]);

  useEffect(() => {
    if (therapistDetails && therapistDetails?.role?.role !== RolesEnum.THERAPIST) {
      navigate(RoutesEnum.HOME)
    }
  }, [therapistDetails]);

  if (!therapistDetails) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      position={'relative'}
      padding={isMobile ? '20px' : '50px 160px'}
    >
      <BookTherapistModal
        isOpen={showBookSession}
        onClose={closeBookSession}
        therapist={therapistDetails}
        clientId={user?.id}
        availableDatesToBook={availableDatesToBook}
        redirectRoute={RoutesEnum.DASHBOARD}
        buttonLabel={t('btn_book_and_pay')}
      />

      <FreeSessionModal isOpen={showFeeSession} onClose={closeFreeSessionModal} therapist={therapistDetails} />

      <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1.5fr'} gridTemplateRows={'1fr'} width={'100%'} gap={isMobile ? '8px' : '60px'}>
        <Flex flexDirection="column" width={'100%'} gap={isMobile ? '32px' : '16px'} alignItems={'center'} padding={'32px'}>
          <img
            ref={onImgRefChange}
            src={therapistDetails?.photo ?? ProfilePhotoPlaceholder}
            alt=""
            width={'100%'}
            height={imgHeight ?? 'auto'}
            style={{ objectFit: 'cover', borderRadius: '50%' }}
          />
          <StatisticsCard>
            <Grid gridTemplateColumns={'1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'32px'}>
              <Flex flexDirection="column" width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Text
                  variant={isMobile ? 'heading4' : 'heading1'}
                  text={`${calculatePrice()} BAM`}
                  margin={'0 0 8px 0'}
                  color={theme.colors.palette.purple}
                  textAlign={'center'}
                />
                <Text variant={'heading7'} text={`${t('therapist_details_1')} ${calculatePriceEUR()}`} textAlign={'center'} />
                <Text variant={'paragraph1'} text={t('therapist_details_2')} textAlign={'center'} />
              </Flex>
              {/* 
              <Flex flexDirection="column" width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Text
                  variant={isMobile ? 'heading4' : 'heading1'}
                  text={therapistDetails?.clients?.length ?? 0}
                  margin={'0 0 8px 0'}
                  color={theme.colors.palette.purple}
                  textAlign={'center'}
                />
                <Text variant={'paragraph1'} text={'zadovoljnih korisnika'} textAlign={'center'} />
              </Flex> */}
            </Grid>
          </StatisticsCard>
          {therapistDetails?.freeSession && (
            <Button
              variant={'accentDefault'}
              text={t('btn_free_session')}
              minWidth="250px"
              onClick={openFreeSessionModal}
              width={isMobile ? '100%' : 'auto'}
            />
          )}
        </Flex>

        <Flex flexDirection="column" width={'100%'} alignItems={isMobile ? 'center' : 'flex-start'} margin={isMobile ? '' : '32px 0 0 0'}>
          <Text variant={'heading3'} text={therapistDetails?.username} margin={isMobile ? '16px 0 16px 0' : '24px 0 24px 0'} />

          <Text variant={'paragraph1'} text={therapistDetails?.details} whiteSpace={'pre-line'} margin={'0 0 24px 0'} />

          <Flex flexDirection="column" width={'100%'} gap="16px" margin={'24px 0 24px 0'}>
            <Button
              variant={isMobile ? 'primaryDefault' : 'primaryLarge'}
              text={t('btn_book')}
              minWidth="250px"
              onClick={onBookSessionClick}
              width={isMobile ? '100%' : 'auto'}
            />

            <Button
              variant={isMobile ? 'secondaryDefault' : 'secondaryLarge'}
              text={t('btn_back')}
              width={isMobile ? '100%' : 'auto'}
              minWidth="250px"
              onClick={onBackClick}
            />
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  );
};
