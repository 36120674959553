import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { Accordion, Button, Flex, Grid, Text } from 'src/components/common';
import { SendApplicationModal } from './modals/SendApplicationModal';
import { VideoModal } from './modals/VideoModal';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { useAppDispatch } from 'src/store';
import { getQuestionsByRole } from 'src/store/slices/questionsSlice';
import { RolesEnum } from 'src/constants/RolesEnum';

import Sekcija1Ilustracija from 'src/assets/images/for-therapists/for-therapists-sekcija1-ilustracija.svg';
import Sekcija2Ilustracija from 'src/assets/images/for-therapists/for-therapists-sekcija2-ilustracija.svg';
import Sekcija3Ilustracija from 'src/assets/images/for-therapists/for-therapists-sekcija3-ilustracija.svg';

import { faqTherapists } from 'src/assets/data/faq';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';

export const ForTherapists = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const { isMobile } = useContext(IsMobileContext);
  const { isOpen: isOpenApplicationModal, close: closeApplicationModal, open: openApplicationModal } = useDisclosure(false);
  const { isOpen: isOpenVideModal, close: closeVideoModal, open: openVideModal } = useDisclosure(false);

  useEffect(() => {
    dispatch(getQuestionsByRole(RolesEnum.THERAPIST));
  }, []);

  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
      <SendApplicationModal isOpen={isOpenApplicationModal} onClose={closeApplicationModal} />
      <VideoModal isOpen={isOpenVideModal} onClose={closeVideoModal} />

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1.5fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'20px'}
        padding={isMobile ? '20px' : `48px ${maxHorizontalPadding}`}
      >
        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading2'} text={t('for_therapists_11')} margin={'0 0 40px 0'} width={'80%'} />
          <Text variant={'paragraph1'} width={'90%'} text={t('for_therapists_12')} />
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} alignItems={'flex-end'} flexDirection={'column'}>
          <img src={Sekcija1Ilustracija} alt="Ilustracija" width={'90%'} height={'auto'} />
        </Flex>
      </Grid>

      <Flex flexDirection={isMobile ? 'column' : 'row'} gap={'16px'} margin={'32px 0 32px 0'}>
        <Button onClick={openVideModal} text={t('for_therapists_13')} width={isMobile ? 'auto' : '350px'} variant={'accentLarge'} />
        <Button onClick={openApplicationModal} text={t('for_therapists_14')} width={isMobile ? 'auto' : '350px'} variant={'primaryLarge'} />
      </Flex>

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1.5fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'20px'}
        padding={isMobile ? '20px' : `16px ${maxHorizontalPadding}`}
      >
        <Flex width={'100%'} justifyContent={'center'} alignItems={'flex-start'} flexDirection={'column'}>
          <img src={Sekcija2Ilustracija} alt="Ilustracija" width={'80%'} height={'auto'} />
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading2'} text={t('for_therapists_15')} margin={'0 0 40px 0'} width={'80%'} />
          <Text variant={'paragraph1'} width={'90%'} text={t('for_therapists_16')} />
        </Flex>
      </Grid>

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1.5fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'20px'}
        padding={isMobile ? '20px' : `20px ${maxHorizontalPadding}`}
        margin={isMobile ? '20px 0 0 0' : '24px 0 48px 0'}
      >
        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading2'} text={t('for_therapists_17')} margin={'0 0 40px 0'} width={'80%'} />
          <Text variant={'paragraph1'} width={'90%'} text={t('for_therapists_18')} margin={'0 0 40px 0'} />
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} alignItems={'flex-end'} flexDirection={'column'}>
          <img src={Sekcija3Ilustracija} alt="Ilustracija" width={'95%'} height={'auto'} />
        </Flex>
      </Grid>

      <Flex
        width={'100%'}
        flexDirection={'column'}
        backgroundColor={theme.colors.palette.lightPurple}
        padding={isMobile ? '20px' : `48px ${maxHorizontalPadding}`}
      >
        <Text variant={'heading4'} text={t('for_therapists_19')} width={'100%'} textAlign={'center'} margin={'0 0 64px 0'} />

        <Accordion panels={faqTherapists()} columns={2} />
      </Flex>
    </Flex>
  );
};
