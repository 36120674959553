import React, { useEffect, useMemo, useRef } from 'react';

import { CheckboxProps, CheckboxReturnValue } from 'src/types/Checkbox';
import { Flex, Text } from 'src/components/common';
import { CheckboxWrapper, getCheckboxSize, getCheckMarkStyles, getIndeterminatekMarkStyles } from './styles';

export const Checkbox = (props: CheckboxProps) => {
  const {
    label = '',
    note = '',
    disabled = false,
    checked = false,
    variant = 'primary',
    withIndeterminateState = false,
    onChange = undefined,
  } = props;

  const inputRef = useRef(null);
  const checkedRef = useRef(checked);

  const checkboxSize = useMemo(() => {
    return getCheckboxSize(variant);
  }, [variant]);

  const checkmarkStyles = useMemo(() => {
    return getCheckMarkStyles(variant);
  }, [variant]);

  const indeterminateMarkStyles = useMemo(() => {
    return getIndeterminatekMarkStyles(variant);
  }, [variant]);

  const updateInput = (ref, checked) => {
    const input = ref.current;

    if (input) {
      input.checked = checked;

      if (withIndeterminateState) {
        input.indeterminate = checked == null;
      } else {
        input.indeterminate = false;
      }
    }
  };

  useEffect(() => {
    checkedRef.current = checked;
    updateInput(inputRef, checked);
  }, [checked]);

  const handleChange = () => {
    if (disabled) {
      return;
    }

    if (withIndeterminateState) {
      switch (checkedRef.current) {
        case true:
          checkedRef.current = false;
          break;
        case false:
          checkedRef.current = null;
          break;
        default:
          checkedRef.current = true;
          break;
      }
    } else {
      switch (checkedRef.current) {
        case true:
          checkedRef.current = false;
          break;
        default:
          checkedRef.current = true;
          break;
      }
    }

    updateInput(inputRef, checkedRef.current);

    if (typeof onChange === 'function') {
      onChange(checkedRef.current as CheckboxReturnValue);
    }
  };

  return (
    <CheckboxWrapper checkboxSize={checkboxSize} checkmarkStyles={checkmarkStyles} indeterminateMarkStyles={indeterminateMarkStyles}>
      <input type={'checkbox'} ref={inputRef} checked={checkedRef.current} disabled={disabled} onChange={() => {}} />

      <span onClick={handleChange} />

      {label || note ? (
        <Flex flexDirection={'column'} margin={'0 0 0 8px'}>
          {label && <Text text={label} variant="paragraph1" style={{ lineHeight: '16px' }} margin={'0 0 4px 0'} />}
          {note && <Text text={note} variant="paragraph1" style={{ fontFamily: 'Barlow-Light', lineHeight: '16px' }} />}
        </Flex>
      ) : null}
    </CheckboxWrapper>
  );
};
