import React from 'react';
import { components, MenuProps } from 'react-select';

import { SearchIcon } from 'src/components/icons/ui-icons';
import { Input, Flex } from 'src/components/common';
import { CustomSelectProps, SelectDropdownOption } from '../SelectDropdown.types';

export const CustomMenu = ({ selectProps, innerProps, children, ...rest }: MenuProps<SelectDropdownOption>) => {

  const { onInputChange, inputValue, onMenuInputFocus, includesSearch } =
    selectProps as CustomSelectProps;

  return (
    <components.Menu innerProps={innerProps} selectProps={selectProps} {...rest}>
      {includesSearch && (
        <Flex padding='8px 12px'>
          <Input
            style={{ borderRadius: '50px' }}
            width='100%'
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange &&
              onInputChange(e.currentTarget.value, {
                action: 'input-change',
                prevInputValue: String(inputValue),
              })
            }
            onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => {
              e.stopPropagation();
              (e.target as HTMLInputElement).focus();
            }}
            onTouchEnd={(e: React.TouchEvent<HTMLInputElement>) => {
              e.stopPropagation();
              (e.target as HTMLInputElement).focus();
            }}
            onFocus={onMenuInputFocus}
            leftElement={<SearchIcon />}
            placeholder={'Traži'}
            variant='secondary'
          />
        </Flex>
      )}
      {children}
    </components.Menu>
  );
};
