import React from 'react';
import { MultiValue, MultiValueGenericProps } from 'react-select';

import { SelectDropdownOption } from '../SelectDropdown.types';

export const CustomMultiValueContainer = ({
  selectProps: { value: multiSelectValue },
  data,
}: MultiValueGenericProps<SelectDropdownOption>) => {
  const castedValue = multiSelectValue as MultiValue<SelectDropdownOption>;
  if (castedValue) {
    return <>{data.label}, </>;
  }
  return null;
};
