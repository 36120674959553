import React from 'react';
import styled from 'styled-components';

import { CardProps } from 'src/types/Card';

const StyledCard = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  color: ${(props) => props.theme.colors.text.primary};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.backgroundColor ?? props.theme.colors.background.main};
  box-shadow: rgba(99, 99, 99, 0.2) 0 0px 1px 0;
`;

export const Card = (props: CardProps) => {
  const {
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    width = 'fit-content',
    minWidth = 'unset',
    padding = '24px',
    margin = '0px',
    height = 'auto',
    direction = 'column',
    border = 'unset',
    borderRadius = '16px',
    children,
  } = props;

  return (
    <StyledCard
      {...props}
      padding={padding}
      margin={margin}
      width={width}
      minWidth={minWidth}
      height={height}
      direction={direction}
      border={border}
      borderRadius={borderRadius}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      {children}
    </StyledCard>
  );
};
