import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

export interface SendQuestionState {
  name: string;
  phone: string;
  email: string;
  question: string;
}

const formDefaultValues: SendQuestionState = {
  name: '',
  phone: '',
  email: '',
  question: '',
};

const useSendQuestionForm = () => {
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const useSendQuestionFormSchema = yup.object().shape({
    name: yup.string().required(t('error_name')),
    phone: yup.string().required(t('error_phone')),
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
    question: yup.string().required(t('error_answer')),
  });

  const sendQuestionForm = useForm({
    resolver: yupResolver(useSendQuestionFormSchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: SendQuestionState) => {
    const values = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: 'Kontakt forma',
      message: data.question,
    };

    setIsSubmittingForm(true);
    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        values,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (result.status === 200) {
        ToastNotifications.success(t('msg_question_sent'));
        setIsSubmittingForm(true);
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'sendQuestionsForm');
    } finally {
      setIsSubmittingForm(false);
    }
  };

  return {
    formDefaultValues,
    sendQuestionForm,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useSendQuestionForm };
