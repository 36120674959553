import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useKeyPressEvent, useClickAway } from 'react-use';
import { useTheme } from 'styled-components';

import { ModalProps } from 'src/types/Modal';
import { CloseButton, StyledModal, Backdrop, getModalStyles } from './styles';
import { X } from 'src/components/icons/ui-icons';

export const Modal = (props: ModalProps) => {
  const ref = useRef(null);
  const theme = useTheme();

  const {
    isOpen = false,
    hideCloseButton = false,
    mountNode = document.body,
    size = 'medium',
    width = undefined,
    preventOnClickOutside = false,
    onClose = () => undefined,
    backgroundColor = theme.colors.palette.white,
    backdropBlur = false,
    children,
    ...rest
  } = props;

  const modalStyles = useMemo(() => {
    return getModalStyles(size, width);
  }, [size, width]);

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    return () => {
      enableBodyScroll();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      if (isOpen) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    } else {
      enableBodyScroll();
    }
  }, [isOpen, ref?.current]);

  useKeyPressEvent('Escape', onClose);
  useClickAway(ref, preventOnClickOutside ? () => {} : onClose);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <Backdrop backdropBlur={backdropBlur}>
      <StyledModal modalStyles={modalStyles} backgroundColor={backgroundColor} {...rest} ref={ref}>
        {!hideCloseButton && (
          <CloseButton onClick={onClose}>
            <X size={20} color={theme.colors.text.tertiary} />
          </CloseButton>
        )}

        {children}
      </StyledModal>
    </Backdrop>,
    mountNode
  );
};
