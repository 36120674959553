import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button, Flex, Grid, Text } from 'src/components/common';
import { BurgerMenu } from 'src/components/core';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getAppRoutes, RoutesEnum } from 'src/routes';
import { headerHeight, maxHorizontalPadding } from 'src/constants/common';
import { logout, toggleLoginModal } from 'src/store/slices/authSlice';
import { useTranslation } from 'react-i18next';

import FullLogo from 'src/assets/images/full-logo.svg';
import LanguageSelector from 'src/components/common/language-picker/LanguagePicker';

const HeaderContainer = styled.header`
  position: fixed;
  z-index: 5000;
  height: ${headerHeight};
  width: 100%;
  background-color: ${(props) => props.theme.colors.palette.lightPurple};
  padding: 0 ${maxHorizontalPadding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.text.disabled};

  a {
    cursor: pointer;
    white-space: nowrap;

    > div {
      white-space: nowrap;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 24px;
  }
`;

export const Header = () => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user } = useAppSelector((state: RootState) => state.auth);
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const openLoginModal = () => {
    dispatch(toggleLoginModal(true));
  };

  const profileNavLink = useMemo(() => {
    if (!user || (user && !user?.role?.role)) {
      return undefined;
    }

    return RoutesEnum.DASHBOARD;
  }, [user]);

  const onLogutClick = () => {
    dispatch(logout());
  };

  return (
    <HeaderContainer>
      <BurgerMenu />

      <Flex flexDirection="row" alignItems="center" width="100%">
        {isMobile && <LanguageSelector />}

        <Grid
          gridTemplateColumns={isMobile ? '1fr' : isAuthenticated ? 'auto 1fr' : 'auto 3fr 1fr'}
          gridTemplateRows={'1fr'}
          width={isMobile ? 'fit-content' : '100%'}
          margin={isMobile ? '0 auto' : '0'}
          alignItems="center"
        >
          <NavLink to={RoutesEnum.HOME} style={isMobile ? {} : { marginRight: 10, marginLeft: -25 }}>
            <Flex width={'100%'} justifyContent={'center'} cursor={'pointer'}>
              <img src={FullLogo} alt="psihoterapija.app" width={isMobile ? '80%' : '100%'} height={'auto'} style={{ maxWidth: '300px' }} />
            </Flex>
          </NavLink>

          {!isMobile && (
            <>
              {!isAuthenticated && (
                <Flex
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={'20px'}
                  style={isMobile ? {} : { marginRight: 30 }}
                >
                  <a href="https://www.psihoterapija.blog" target="_blank" rel="noreferrer">
                    <Text variant="paragraph1" text={'Blog'} />
                  </a>
                  {getAppRoutes(t).map((r, index) => {
                    if (r.inHeader) {
                      return (
                        <NavLink key={index} to={r.path}>
                          <Text variant="paragraph1" text={r.name} />
                        </NavLink>
                      );
                    }
                  })}
                </Flex>
              )}

              <Flex flexDirection="row" alignItems={'center'} justifyContent={'center'} gap={'5px'} margin={'0 0 0 auto'}>
                <LanguageSelector />

                <Flex width="8px" />

                {!isAuthenticated && <Button text={t('btn_login')} margin={'0 0 0 auto'} width={'140px'} onClick={openLoginModal} />}

                {isAuthenticated && (
                  <NavLink to={profileNavLink}>
                    <Button text={t('btn_profile')} width={'140px'} />
                  </NavLink>
                )}

                {isAuthenticated && <Button text={t('btn_logout')} width={'140px'} onClick={onLogutClick} />}
              </Flex>
            </>
          )}
        </Grid>
      </Flex>
    </HeaderContainer>
  );
};
