import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

export const getFontStyles = (theme: DefaultTheme) => {
  return css`
    font-size: ${theme.typography.variants.paragraph1.fontSize};
    line-height: ${theme.typography.variants.paragraph1.lineHeight};
    font-family: ${theme.typography.variants.paragraph1.fontFamily};
  `;
};

export const getColorStyles = (isInvalid: boolean, notEditable: boolean, theme: DefaultTheme) => {
  if (isInvalid) {
    return css`
      color: ${theme.colors.text.primary};
      border-color: ${theme.colors.palette.red} !important;
    `;
  }

  if (notEditable) {
    return css`
      color: ${theme.colors.text.primary};
      border-color: ${theme.colors.background.secondary.BG1} !important;
      background-color: ${theme.colors.background.secondary.BG1} !important;
      user-select: none;
    `;
  }

  return css`
    color: ${theme.colors.text.primary};
    border-color: ${theme.colors.palette.lightPurple};
  `;
};

export const StyledInputContainer = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width ?? 'fit-content'};
  margin: ${(props) => props.margin ?? '0px'};
`;

export const InputWrapper = styled.div`
  position: relative;

  :focus-within,
  :focus,
  :hover {
    input {
      border-color: ${(props) => props.theme.colors.palette.purple};
    }
  }
`;

export const LeftElementWrapper = styled.div`
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg:hover {
    > path[stroke] {
      stroke: ${(props) => props.theme.colors.palette.purple};
    }

    > path[fill] {
      fill: ${(props) => props.theme.colors.palette.purple};
    }
  }
`;

export const RightElementWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg:hover {
    > path[stroke] {
      stroke: ${(props) => props.theme.colors.palette.purple};
    }

    > path[fill] {
      fill: ${(props) => props.theme.colors.palette.purple};
    }
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: contents;
  position: absolute;
  bottom: -20px;
  left: 0;
`;

export const StyledInput = styled.input<{
  colorStyles: boolean;
  fontStyles: boolean;
  width: string;
  margin: string | number;
  leftElement: React.ReactNode | null;
  rightElement: React.ReactNode | null;
}>`
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
  outline: 0;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${(props) => (props.leftElement ? '42px' : '12px')};
  padding-right: ${(props) => (props.rightElement || props.type === 'password' ? '42px' : '12px')};
  height: ${(props) => (props.variant === 'secondary' ? '40px' : '48px')};
  ${(props) => props.colorStyles};
  ${(props) => props.fontStyles};

  ::placeholder {
    color: ${(props) => props.theme.colors.text.disabled};
    font-size: ${(props) => props.theme.typography.variants.paragraph2.fontSize};
    line-height: ${(props) => props.theme.typography.variants.paragraph2.fontWeight};
    font-family: ${(props) => props.theme.typography.variants.paragraph2.fontFamily};
  }

  :disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.text.disabled} !important;
    border-color: ${(props) => props.theme.colors.border.darker} !important;
    background-color: ${(props) => props.theme.colors.background.secondary.BG1} !important;
  }

  :disabled::placeholder {
    color: ${(props) => props.theme.colors.text.disabled};
  }
`;
