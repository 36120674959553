import styled, { DefaultTheme } from 'styled-components';

export const ToggleButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ToggleButtonLabel = styled.label`
  position: ${(props) => props.position ?? 'absolute'};
  top: 0;
  left: 0;
  width: 38px;
  height: 24px;
  border-radius: 28px;
  background: ${(props) => props.theme.colors.border.lighter};
  border: 1px solid ${(props) => props.theme.colors.border.darker};
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin: 1.5px;
    background: ${(props) => props.theme.colors.palette.white};
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input<{
  theme: DefaultTheme;
}>`
  opacity: 0;
  z-index: 1;
  width: 38px;
  height: 24px;
  border-radius: 28px;
  margin: 0;
  cursor: pointer;

  &:checked + ${ToggleButtonLabel} {
    background: ${(props) => props.theme.colors.palette.purple};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      margin-left: 16px;
      transition: 0.2s;
    }
  }
`;
