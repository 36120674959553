import { useEffect, useState } from 'react';
import useFontFaceObserver from 'use-font-face-observer';

export const useIsFontLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  const isFontLoaded = useFontFaceObserver([
    { family: 'Barlow-Thin' },
    { family: 'Barlow-ExtraLight' },
    { family: 'Barlow-Light' },
    { family: 'Barlow-Regular' },
    { family: 'Barlow-Medium' },
    { family: 'Barlow-SemiBold' },
    { family: 'Barlow-Bold' },
    { family: 'Barlow-ExtraBold' },
    { family: 'Barlow-Black' },
  ]);

  useEffect(() => {
    setLoaded(isFontLoaded);
  }, [isFontLoaded]);

  return { loaded };
};