import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const Facebook = (props: UIIconProps) => {
  const { size = 28, color = 'currentColor', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} fill="none" viewBox="0 0 28 28">
      <path
        fill={color}
        d="M15.16 14.255h2.605l.41-2.663h-3.016v-1.455c0-1.106.36-2.087 1.388-2.087H18.2V5.726c-.29-.04-.905-.126-2.065-.126-2.424 0-3.844 1.287-3.844 4.22v1.772H9.8v2.663h2.49v7.32c.494.074.994.125 1.507.125.463 0 .916-.043 1.363-.104v-7.34z"
      />
    </svg>
  );
};
