import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Button, Flex, Modal, Text } from 'src/components/common';
import { RoutesEnum } from 'src/routes';
import { useTranslation } from 'react-i18next';

interface GetMatchedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GetMatchedModal = ({ isOpen, onClose }: GetMatchedModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'20px'} padding={'20px 40px'}>
        <Text variant="heading4" text={t('get_matched_1')} margin={'0 0 20px 0'} width={'100%'} textAlign={'center'} />

        <Button
          onClick={() => {
            navigate(RoutesEnum.QUESTIONNAIRE_FOR_INDIVIDUALS);
          }}
          width="90%"
          text={t('get_matched_2')}
        />

        <Button
          onClick={() => {
            navigate(RoutesEnum.QUESTIONNAIRE_FOR_CHILDREN_ADOLESCENTS);
          }}
          width="90%"
          text={t('get_matched_3')}
        />
      </Flex>
    </Modal>
  );
};
