import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Text } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';

export const TermsOfUse = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flexDirection="column" flex="1" padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}>
      <Text variant={'heading2'} text={t('terms_of_use_1')} margin={'0 0 32px 0'} />

      <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={t('terms_of_use_2')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={'Opis Platforme'} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_3')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_4')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_5')} margin={'0 0 32px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_6')} margin={'0 0 32px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_7')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_8')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_9')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_10')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_11')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_12')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_13')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_14')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_15')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_16')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_17')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_18')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_19')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_20')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_21')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_22')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_23')} margin={'0 0 32px 0'} />

      <Text variant={'paragraph1'} text={t('terms_of_use_24')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_25')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_26')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_27')} margin={'0 0 16px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_28')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_29')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_30')} margin={'0 0 16px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_31')} margin={'0 0 16px 0'} />
      <InlineTextWrapper margin={'0 0 16px 0'}>
        <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('terms_of_use_32')} `} />
        <Text
          margin="0 4px 0 0"
          variant={'paragraph1'}
          text={t('terms_of_use_33')}
          color={theme.colors.palette.blue}
          style={{ textDecoration: 'underline' }}
          onClick={() => goToUrl(config.PRIVACY_POLICY_URL)}
        />
      </InlineTextWrapper>
      <Text variant={'paragraph1'} text={t('terms_of_use_34')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_35')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_36')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_37')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_38')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_39')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_40')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_41')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_42')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_43')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_44')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_45')} margin={'0 0 16px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_46')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_47')} whiteSpace={'pre-wrap'} margin={'0 0 16px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_48')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_49')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_50')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_51')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_52')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_53')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_54')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_56')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_57')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_58')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_59')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_60')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_61')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_62')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_63')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_64')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_65')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_66')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_67')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_68')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_69')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_70')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_71')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_72')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_73')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_74')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('terms_of_use_75')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_76')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('terms_of_use_77')} margin={'0 0 32px 0'} />
    </Flex>
  );
};
