import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { Button, Flex, Textarea, Modal, Text } from 'src/components/common';
import StarIconFilled from 'src/assets/images/about-us/star-icon-filled.svg';
import StarIconOutline from 'src/assets/images/about-us/star-icon-outline.svg';

import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { submitFeedback, toggleFeedbackModal } from 'src/store/slices/feedbackSlice';
import { Appointment } from 'src/types/Appointment';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { useTranslation } from 'react-i18next';

interface FeedbackModalProps {
  session: Appointment;
}

const MAX_FEEDBACK_RATING = 5;

export const FeedbackModal = ({ session }: FeedbackModalProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { showFeedbackModal } = useAppSelector((state: RootState) => state.feedback);

  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState('');

  const onCloseHandler = () => {
    setMessage('');
    setRating(0);
    dispatch(toggleFeedbackModal(false));
  };

  const isRatingAlreadySet = (index) => {
    return index <= rating;
  };

  const feedbackSubmitHandler = async () => {
    const response: any = await dispatch(
      submitFeedback({
        rating,
        text: message,
        therapistId: session?.therapist?.id,
      })
    );

    if (response.payload.success) {
      ToastNotifications.success(response.payload.data, 'editProfileSuccess');
      onCloseHandler();
    }
  };

  return (
    <Modal isOpen={showFeedbackModal} onClose={onCloseHandler} size="medium">
      <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
        <Flex padding={isMobile ? '10px' : '0px'} flexDirection="column" borderRadius="16px" width="100%">
          <Text variant={'heading5'} text={t('feedback_1')} margin="64px auto 16px auto" />

          <Textarea
            value={message}
            placeholder={t('hint_feedback')}
            rows={4}
            margin="0 0 24px 0"
            width="100%"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />

          <Flex alignItems={'center'} justifyContent="center" width="100%" gap={isMobile ? '4px' : '8px'}>
            {_.times(MAX_FEEDBACK_RATING, (i) => (
              <img
                key={i}
                src={isRatingAlreadySet(i + 1) ? StarIconFilled : StarIconOutline}
                onClick={() => {
                  if (isRatingAlreadySet(i + 1)) {
                    setRating(i);
                  } else {
                    setRating(i + 1);
                  }
                }}
                alt="Star"
                width={'15%'}
                height={'auto'}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </Flex>

          <Button
            disabled={rating === 0}
            type="button"
            onClick={feedbackSubmitHandler}
            variant="primaryDefault"
            text={t('btn_rate')}
            minWidth={isMobile ? '100%' : '250px'}
            margin={'40px auto 0 auto'}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
