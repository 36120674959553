import React, { useState, useEffect } from 'react';
import { Button, Text, Input, Textarea, UploadPhotoWithPreview, Flex } from 'src/components/common';
import { EditProfileFormState, useEditProfileForm } from './useEditProfileForm';
import { User } from 'src/types/User';
import { urlToFile } from 'src/utils/url-to-file';
import { RolesEnum } from 'src/constants/RolesEnum';
import { CheckBox, ToggleButtonLabel } from 'src/components/common/toggle-button/styles';
import { RootState, useAppSelector } from 'src/store';
import { useTranslation } from 'react-i18next';

interface FormProps {
  closeModal: () => void;
  profileData: User;
  openDetails: () => void;
  deleteProfile: () => void;
}

const EditProfileForm = ({ closeModal, profileData, openDetails, deleteProfile }: FormProps) => {
  const { t } = useTranslation();

  const [optOutEnabled, setOptOutEnabled] = useState(profileData?.status == 'opt-out');
  const [freeSessionEnabled, setFreeSessionEnabled] = useState(profileData?.freeSession === true);
  const [earlyBookingEnabled, setEarlyBookingEnabled] = useState(profileData?.earlyBooking === true);
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { editProfileForm, onFormSubmit, isSubmittingForm } = useEditProfileForm({
    profileData,
    closeModal,
    setSelectedImage,
    optOutEnabled,
    freeSessionEnabled,
    earlyBookingEnabled,
  });
  const { register, handleSubmit, formState, setValue } = editProfileForm;
  const { errors } = formState;

  const { user } = useAppSelector((state: RootState) => state.auth);

  const onSubmit = async (data: EditProfileFormState) => {
    await onFormSubmit(data, selectedImage);
  };

  const loadProfilePhoto = async (url: string) => {
    setProfilePhotoLoading(true);

    try {
      const file = await urlToFile(url);
      setSelectedImage(file);
    } catch (err) {
    } finally {
      setTimeout(() => {
        setProfilePhotoLoading(false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (profileData?.photo) {
      loadProfilePhoto(profileData.photo);
    }
  }, [profileData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} encType="multipart/form-data">
      <UploadPhotoWithPreview
        externalImageLoading={profilePhotoLoading}
        size={'200px'}
        margin="0 auto 32px auto"
        onChange={(file: File | null) => {
          setSelectedImage(file);
        }}
        photoAdded={selectedImage}
        allowedFormats={'image/png, image/jpg, image/jpeg'}
      />

      {user?.role?.role == RolesEnum.THERAPIST && (
        <Button variant="primaryDefault" text={t('btn_see_public_profile')} margin={'0 auto 32px auto'} onClick={openDetails} />
      )}

      {profileData && profileData?.role?.role == RolesEnum.THERAPIST && (
        <Flex flexDirection={'column'} gap={'8px'} margin="0 0 32px 4px">
          <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
            <Text variant={'paragraph1'} text={t('edit_profile_2')} />
            <CheckBox id="checkbox1" type="checkbox" checked={optOutEnabled} onChange={() => setOptOutEnabled(!optOutEnabled)} />
            <ToggleButtonLabel htmlFor="checkbox1" position={'relative'} />
          </Flex>
          <Text variant={'paragraph3'} text={t('edit_profile_3')} margin="0 0 32px 4px" />

          <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
            <Text variant={'paragraph1'} text={t('edit_profile_5')} />
            <CheckBox
              id="checkbox2"
              type="checkbox"
              checked={earlyBookingEnabled}
              onChange={() => setEarlyBookingEnabled(!earlyBookingEnabled)}
            />
            <ToggleButtonLabel htmlFor="checkbox2" position={'relative'} />
          </Flex>
          <Text variant={'paragraph3'} text={t('edit_profile_6')} margin="0 0 32px 4px" />
          <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
            <Text variant={'paragraph1'} text={t('edit_profile_4')} />
            <CheckBox
              id="checkbox3"
              type="checkbox"
              checked={freeSessionEnabled}
              onChange={() => setFreeSessionEnabled(!freeSessionEnabled)}
            />
            <ToggleButtonLabel htmlFor="checkbox3" position={'relative'} />
          </Flex>
        </Flex>
      )}

      <Input
        {...register('username')}
        margin="0 0 16px 0"
        placeholder={t('placeholder_username')}
        errorMessage={errors.username?.message}
        width="100%"
      />

      <Input
        {...register('email')}
        margin="0 0 16px 0"
        placeholder={t('placeholder_email')}
        errorMessage={errors.email?.message}
        width="100%"
      />

      <Input
        {...register('phone')}
        margin="0 0 16px 0"
        placeholder={t('placeholder_phone')}
        errorMessage={errors.phone?.message}
        width="100%"
      />

      <Input
        {...register('password')}
        type="password"
        margin="0 0 16px 0"
        placeholder={t('placeholder_password')}
        errorMessage={errors.password?.message}
        width="100%"
      />

      <Input
        {...register('repeatPassword')}
        type="password"
        margin="0 0 32px 0"
        placeholder={t('placeholder_password_repeat')}
        errorMessage={errors.repeatPassword?.message}
        width="100%"
      />

      {profileData?.role?.role == RolesEnum.THERAPIST && (
        <>
          <Input
            {...register('price')}
            margin="0 0 16px 0"
            placeholder={t('placeholder_price')}
            errorMessage={errors.price?.message}
            type="number"
            width="100%"
          />

          <Textarea
            {...register('details')}
            placeholder={t('placeholder_details')}
            minChars={undefined}
            rows={7}
            width="100%"
            margin="0 0 16px 0"
            errorMessage={errors.details?.message}
            onChange={(e) => {
              setValue('details', e.target.value);
            }}
          />
        </>
      )}

      <Text variant={'paragraph1'} text={t('btn_delete_profile')} margin="0 0 32px 4px" onClick={deleteProfile} />

      <Button
        type="submit"
        variant="primaryDefault"
        text={t('btn_save')}
        minWidth={'200px'}
        margin={'0 auto'}
        isLoading={isSubmittingForm}
      />
    </form>
  );
};

export default EditProfileForm;
