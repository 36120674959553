import React from 'react';
import { useTheme } from 'styled-components';

import { Button, Flex, Input, Modal, Text } from 'src/components/common';
import { RecommendFriendState, useRecommendFriendForm } from '../forms/recommend-friend-form/useRecommendFriendForm';
import { ModalProps } from 'src/types/Modal';

import FullLogo from 'src/assets/images/logo.png';

export const RecommendFriendModal = ({ isOpen, onClose }: ModalProps) => {
  const theme = useTheme();
  const { recommendFriendForm, onFormSubmit } = useRecommendFriendForm();
  const { register, handleSubmit, formState } = recommendFriendForm;
  const { errors } = formState;

  const onSubmit = async (data: RecommendFriendState) => {
    await onFormSubmit(data, onClose);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection="column" width="100%" justifyContent="center" alignItems="center">
        <img src={FullLogo} alt="psihoterapija.app" width={'80px'} height={'auto'} style={{ marginBottom: 16 }} />
      </Flex>
      <Text variant={'heading5'} text={'Pozovite kolegu/icu'} margin={'0 0 32px 0'} />

      <Text
        variant={'paragraph2'}
        text={
          'Pozivamo vas da podijelite informaciju o našoj platformi s kolegom time omogućite sebi i kolegi dodatnih mjesec dana besplatnog korištenja svih funkcionalnosti koje platforma nudi.'
        }
        margin={'0 0 32px 0'}
      />

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Input {...register('email')} margin="0 0 24px 0" placeholder={'Unesite email'} errorMessage={errors.email?.message} width="100%" />

        <Button type="submit" variant="primaryDefault" text={'Pošalji'} width={'100%'} />
      </form>
    </Modal>
  );
};
