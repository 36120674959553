import { httpService } from 'src/config/http';
import { RolesEnum } from 'src/constants/RolesEnum';
import { Question } from 'src/types/Question';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { CreateQuestionRequestDTO, UpdateQuestionRequestDTO, StoreAnswersForMultipleQuestionsRequestDTO } from './RequestDtos';

export default class QuestionnaireAPI {
  public getQuestionsByRole(role: RolesEnum) {
    return httpService.get<Question[]>(`/questions/${role}`);
  }

  public createQuestion(data: CreateQuestionRequestDTO) {
    return httpService.post<Question>('/questions', data);
  }

  public updateQuestion(data: UpdateQuestionRequestDTO) {
    return httpService.put<Question>(`/questions/${data.id}`, data);
  }

  public deleteQuestion(questionId: string) {
    return httpService.delete(`/questions/delete/${questionId}`);
  }

  public storeAnswerForQuestion(data: QuestionAnswer) {
    return httpService.post('/questions/answer', data);
  }

  public storeAnswersForMultipleQuestions(data: StoreAnswersForMultipleQuestionsRequestDTO) {
    return httpService.post('/questions/answers', data);
  }
}

export const questionnaireAPI = new QuestionnaireAPI();
