import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

interface SpinnerProps {
  color?: string;
  size?: number;
  inline?: boolean;
  style?: React.CSSProperties;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div<{ size: string }>`
  animation: ${rotate} 1s linear infinite;
  border-radius: 50%;
  border-left: 4px solid transparent;
  cursor: wait;
  z-index: 9000;
  border-top: 4px solid ${(props) => props.color};
  border-right: 4px solid ${(props) => props.color};
  border-bottom: 4px solid ${(props) => props.color};
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin: ${(props) => (props.inline ? '0 auto' : 'unset')};
  position: ${(props) => (!props.inline ? 'absolute' : 'relative')};
  top: ${(props) => (!props.inline ? '50%' : 'unset')};
  left: ${(props) => (!props.inline ? '50%' : 'unset')};
  transform: ${(props) => (!props.inline ? 'translate(-50%, -50%)' : 'unset')};
`;

export const Spinner = (props: SpinnerProps) => {
  const theme = useTheme();
  const { color = theme.colors.accent, size = 60, style = {}, inline = false } = props;

  return <StyledSpinner {...props} color={color} size={size} style={style} inline={inline} />;
};
