import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Button, Flex, Input, Textarea, Modal, Text, SelectDropdown, UploadButton } from 'src/components/common';
import { SendApplicationState, useSendApplicationForm } from '../forms/send-application-form/useSendApplicationForm';
import { QuestionnaireList } from 'src/components/shared/questionnaire-list/QuestionnaireList';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { InlineTextWrapper } from 'src/components/shared';
import { QuestionChoiceTypeEnum } from 'src/constants/QuestionChoiceTypeEnum';
import { Question } from 'src/types/Question';
import { COUNTRIES_OPTIONS_FOR_DROPDOWN_BS } from 'src/assets/data/countries-options-for-dropdown';
import { citiesAPI } from 'src/api/cities/ApiRequests';
import { RootState, useAppSelector } from 'src/store';
import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { RolesEnum } from 'src/constants/RolesEnum';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { useTranslation } from 'react-i18next';

interface SendApplicationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SendApplicationModal = ({ isOpen, onClose }: SendApplicationModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { questions } = useAppSelector((state: RootState) => state.questions);
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState<Question[]>([]);
  const [documentsAdded, setDocumentsAdded] = useState<File[]>([]);

  const [loading, setLoading] = useState(false);
  const { sendApplicationForm, onFormSubmit } = useSendApplicationForm({ setLoading });
  const { register, handleSubmit, formState, watch, clearErrors, setValue } = sendApplicationForm;
  const { errors } = formState;
  const countryWatched = watch('country');
  const [cities, setCities] = useState([]);
  const cityWatched = watch('city');

  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    setQuestionsWithAnswers(
      questions.map((question) => {
        return { ...question, answered: '' };
      })
    );
  }, [questions]);

  useEffect(() => {
    if (countryWatched) {
      clearErrors('country');
    }
  }, [countryWatched]);

  useEffect(() => {
    if (cityWatched) {
      clearErrors('city');
    }
  }, [cityWatched]);

  const documentChangeHandler = (files: any) => {
    setDocumentsAdded(files);
  };

  const handleAnswering = (value: string | number, questionId: number) => {
    const updatedQuestions = questionsWithAnswers.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          answered: value,
        };
      }
      return question;
    });
    setQuestionsWithAnswers(updatedQuestions);
  };

  const getOutputAnswers = (): QuestionAnswer[] => {
    const result: QuestionAnswer[] = [];
    questionsWithAnswers.forEach((question: Question) => {
      if (question.answer_type.type === QuestionChoiceTypeEnum.TEXT) {
        result.push({
          questionId: question.id,
          answerText: question.answered,
          answerIds: null,
        });
      } else {
        result.push({
          questionId: question.id,
          answerText: null,
          answerIds: question.answered as number[],
        });
      }
    });
    return result;
  };

  const onSubmit = async (data: SendApplicationState) => {
    setLoading(true);
    const outputAnswers = getOutputAnswers();
    await onFormSubmit(data, outputAnswers, RolesEnum.THERAPIST, documentsAdded, onClose);
  };

  const loadCities = async (country) => {
    setCities([]);
    setValue('city', '');

    const response = await citiesAPI.getCities(country);
    const options = response?.data
      ? response?.data?.map((city) => {
          return { label: city, value: city };
        })
      : null;

    setCities(options ?? []);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Text variant={'heading5'} text={t('for_therapists_1')} margin={'0 0 48px 0'} />

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Flex width="100%" flexDirection="column">
          <Input
            {...register('name')}
            margin="0 0 24px 0"
            placeholder={t('placeholder_name')}
            errorMessage={errors.name?.message}
            width="100%"
          />

          <Input
            {...register('email')}
            margin="0 0 24px 0"
            placeholder={t('placeholder_email')}
            errorMessage={errors.email?.message}
            width="100%"
          />

          <SelectDropdown
            isSearchable={true}
            margin="0 0 24px 0"
            placeholder={t('placeholder_country')}
            errorMessage={errors.country?.message}
            options={COUNTRIES_OPTIONS_FOR_DROPDOWN_BS}
            value={countryWatched}
            width={'100%'}
            onChange={(value: string) => {
              setValue('country', value);
              loadCities(value);
            }}
          />

          {cities && cities.length > 0 && (
            <SelectDropdown
              isSearchable={true}
              margin="0 0 24px 0"
              placeholder={t('placeholder_city')}
              errorMessage={errors.city?.message}
              options={cities}
              value={cityWatched}
              width={'100%'}
              onChange={(value: string) => setValue('city', value)}
            />
          )}

          <Input
            {...register('phone')}
            margin="0 0 24px 0"
            placeholder={t('placeholder_phone')}
            errorMessage={errors.phone?.message}
            width="100%"
          />

          <Input
            {...register('password')}
            type="password"
            margin="0 0 24px 0"
            placeholder={t('placeholder_password')}
            errorMessage={errors.password?.message}
            width="100%"
          />

          <Input
            {...register('repeatPassword')}
            type="password"
            margin="0 0 24px 0"
            placeholder={t('placeholder_password_repeat')}
            errorMessage={errors.repeatPassword?.message}
            width="100%"
          />

          <Input
            {...register('price')}
            type="number"
            margin="0 0 24px 0"
            placeholder={t('placeholder_price')}
            errorMessage={errors.price?.message}
            width="100%"
          />

          <Textarea
            {...register('details')}
            placeholder={t('placeholder_details')}
            minChars={undefined}
            rows={5}
            width="100%"
            errorMessage={errors.details?.message}
            onChange={(e) => {
              setValue('details', e.target.value);
            }}
          />

          <Text variant={'paragraph3'} text={t('for_therapists_2')} margin={'8px 4px 32px 4px'} />
        </Flex>

        <QuestionnaireList handleAnswering={handleAnswering} questions={questionsWithAnswers} showEmptyMessage={false} />

        <Text variant={'paragraph1'} text={t('for_therapists_3')} margin={'8px 0 24px 0'} />

        <Text variant={'paragraph1'} text={t('for_therapists_4')} margin={'0 0 8px 0'} />
        <Text variant={'paragraph1'} text={t('for_therapists_5')} margin={'0 0 8px 0'} />
        <Text variant={'paragraph1'} text={t('for_therapists_6')} margin={'0 0 24px 0'} />
        <Text variant={'paragraph1'} text={t('for_therapists_7')} margin={'0 0 24px 0'} />

        <UploadButton
          width={'100%'}
          margin={'0 0 12px 0'}
          color={theme.colors.palette.purple}
          onChange={documentChangeHandler}
          text={t('for_therapists_8')}
          multiple={true}
          documentsAdded={documentsAdded}
          allowedFormats={'application/pdf'}
        />

        <ToggleButtonWrapper>
          <CheckBox id="checkbox" type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
          <ToggleButtonLabel htmlFor="checkbox" />
          <InlineTextWrapper margin={'0 0 32px 0'}>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('registration_1')} />
            <Text
              margin="0 4px 0 0"
              variant={'paragraph1'}
              text={t('registration_2')}
              color={theme.colors.palette.purple}
              style={{ textDecoration: 'underline' }}
              onClick={() => goToUrl(config.PRIVACY_POLICY_URL, '_blank')}
            />
          </InlineTextWrapper>
        </ToggleButtonWrapper>

        <Button
          type="submit"
          variant="accentDefault"
          text={t('for_therapists_9')}
          width={'100%'}
          isLoading={loading}
          disabled={!termsAccepted}
        />
      </form>
    </Modal>
  );
};
