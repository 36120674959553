import { Option } from 'src/types/Option';

interface ExtendedOption extends Option {
  label_en: string
}

export const COUNTRIES_OPTIONS_FOR_DROPDOWN_BS: ExtendedOption[] = [
  { label_en: 'Albania', label: 'Albanija', value: 'Albanija' },
  { label_en: 'Australia', label: 'Australija', value: 'Australija' },
  { label_en: 'Austria', label: 'Austrija', value: 'Austrija' },
  { label_en: 'Belarus', label: 'Bjelorusija', value: 'Bjelorusija' },
  { label_en: 'Belgium', label: 'Belgija', value: 'Belgija' },
  { label_en: 'Bosnia and Herzegovina', label: 'Bosna i Hercegovina', value: 'Bosna i Hercegovina' },
  { label_en: 'Brazil', label: 'Brazil', value: 'Brazil' },
  { label_en: 'Bulgaria', label: 'Bugarska', value: 'Bugarska' },
  { label_en: 'Croatia', label: 'Hrvatska', value: 'Hrvatska' },
  { label_en: 'Canada', label: 'Kanada', value: 'Kanada' },
  { label_en: 'China', label: 'Kina', value: 'Kina' },
  { label_en: 'Cyprus', label: 'Kipar', value: 'Kipar' },
  { label_en: 'Czech Republic', label: 'Češka', value: 'Češka' },
  { label_en: 'Denmark', label: 'Danska', value: 'Danska' },
  { label_en: 'Egypt', label: 'Egipat', value: 'Egipat' },
  { label_en: 'Estonia', label: 'Estonija', value: 'Estonija' },
  { label_en: 'Finland', label: 'Finska', value: 'Finska' },
  { label_en: 'France', label: 'Francuska', value: 'Francuska' },
  { label_en: 'Georgia', label: 'Gruzija', value: 'Gruzija' },
  { label_en: 'Germany', label: 'Njemačka', value: 'Njemačka' },
  { label_en: 'Greece', label: 'Grčka', value: 'Grčka' },
  { label_en: 'Hungary', label: 'Mađarska', value: 'Mađarska' },
  { label_en: 'Iceland', label: 'Island', value: 'Island' },
  { label_en: 'India', label: 'Indija', value: 'Indija' },
  { label_en: 'Indonesia', label: 'Indonezija', value: 'Indonezija' },
  { label_en: 'Iran', label: 'Iran', value: 'Iran' },
  { label_en: 'Iraq', label: 'Irak', value: 'Irak' },
  { label_en: 'Ireland', label: 'Irska', value: 'Irska' },
  { label_en: 'Italy', label: 'Italija', value: 'Italija' },
  { label_en: 'Japan', label: 'Japan', value: 'Japan' },
  { label_en: 'Jordan', label: 'Jordan', value: 'Jordan' },
  { label_en: 'Kazakhstan', label: 'Kazahstan', value: 'Kazahstan' },
  { label_en: 'North Korea', label: 'Sjeverna Koreja', value: 'Sjeverna Koreja' },
  { label_en: 'South Korea', label: 'Južna Koreja', value: 'Južna Koreja' },
  { label_en: 'Kosovo', label: 'Kosovo', value: 'Kosovo' },
  { label_en: 'Kuwait', label: 'Kuvajt', value: 'Kuvajt' },
  { label_en: 'Kyrgyzstan', label: 'Kirgistan', value: 'Kirgistan' },
  { label_en: 'Latvia', label: 'Latvija', value: 'Latvija' },
  { label_en: 'Lebanon', label: 'Liban', value: 'Liban' },
  { label_en: 'Liberia', label: 'Liberija', value: 'Liberija' },
  { label_en: 'Libya', label: 'Libija', value: 'Libija' },
  { label_en: 'Liechtenstein', label: 'Lihtenštajn', value: 'Lihtenštajn' },
  { label_en: 'Lithuania', label: 'Litvanija', value: 'Litvanija' },
  { label_en: 'Luxembourg', label: 'Luksemburg', value: 'Luksemburg' },
  { label_en: 'Macedonia', label: 'Sjeverna Makedonija', value: 'Sjeverna Makedonija' },
  { label_en: 'Malaysia', label: 'Malezija', value: 'Malezija' },
  { label_en: 'Maldives', label: 'Maldivi', value: 'Maldivi' },
  { label_en: 'Malta', label: 'Malta', value: 'Malta' },
  { label_en: 'Mexico', label: 'Meksiko', value: 'Meksiko' },
  { label_en: 'Moldova', label: 'Moldavija', value: 'Moldavija' },
  { label_en: 'Monaco', label: 'Monako', value: 'Monako' },
  { label_en: 'Mongolia', label: 'Mongolija', value: 'Mongolija' },
  { label_en: 'Montenegro', label: 'Crna Gora', value: 'Crna Gora' },
  { label_en: 'Morocco', label: 'Maroko', value: 'Maroko' },
  { label_en: 'Nepal', label: 'Nepal', value: 'Nepal' },
  { label_en: 'Netherlands', label: 'Holandija', value: 'Holandija' },
  { label_en: 'New Zealand', label: 'Novi Zeland', value: 'Novi Zeland' },
  { label_en: 'Norway', label: 'Norveška', value: 'Norveška' },
  { label_en: 'Oman', label: 'Oman', value: 'Oman' },
  { label_en: 'Pakistan', label: 'Pakistan', value: 'Pakistan' },
  { label_en: 'Panama', label: 'Panama', value: 'Panama' },
  { label_en: 'Paraguay', label: 'Paragvaj', value: 'Paragvaj' },
  { label_en: 'Peru', label: 'Peru', value: 'Peru' },
  { label_en: 'Philippines', label: 'Filipini', value: 'Filipini' },
  { label_en: 'Poland', label: 'Poljska', value: 'Poljska' },
  { label_en: 'Portugal', label: 'Portugal', value: 'Portugal' },
  { label_en: 'Qatar', label: 'Katar', value: 'Katar' },
  { label_en: 'Romania', label: 'Rumunija', value: 'Rumunija' },
  { label_en: 'Russia', label: 'Rusija', value: 'Rusija' },
  { label_en: 'Saudi Arabia', label: 'Saudijska Arabija', value: 'Saudijska Arabija' },
  { label_en: 'Serbia', label: 'Srbija', value: 'Srbija' },
  { label_en: 'Seychelles', label: 'Sejšeli', value: 'Sejšeli' },
  { label_en: 'Singapore', label: 'Singapur', value: 'Singapur' },
  { label_en: 'Slovakia', label: 'Slovačka', value: 'Slovačka' },
  { label_en: 'Slovenia', label: 'Slovenija', value: 'Slovenija' },
  { label_en: 'Spain', label: 'Španija', value: 'Španija' },
  { label_en: 'Sweden', label: 'Švedska', value: 'Švedska' },
  { label_en: 'Switzerland', label: 'Švicarska', value: 'Švicarska' },
  { label_en: 'Taiwan', label: 'Tajvan', value: 'Tajvan' },
  { label_en: 'Thailand', label: 'Tajland', value: 'Tajland' },
  { label_en: 'Tunisia', label: 'Tunis', value: 'Tunis' },
  { label_en: 'Turkey', label: 'Turska', value: 'Turska' },
  { label_en: 'Turkmenistan', label: 'Turkmenistan', value: 'Turkmenistan' },
  { label_en: 'Ukraine', label: 'Ukrajina', value: 'Ukrajina' },
  { label_en: 'United Arab Emirates', label: 'Ujedinjeni Arapski Emirati', value: 'Ujedinjeni Arapski Emirati' },
  { label_en: 'United Kingdom', label: 'Ujedinjeno Kraljevstvo Velike Britanije (UK)', value: 'Ujedinjeno Kraljevstvo Velike Britanije (UK)' },
  { label_en: 'United States', label: 'Sjedinjene Američke Države (SAD)', value: 'Sjedinjene Američke Države (SAD)' },
  { label_en: 'Uruguay', label: 'Urugvaj', value: 'Urugvaj' },
  { label_en: 'Uzbekistan', label: 'Uzbegistan', value: 'Uzbegistan' },
  { label_en: 'Venezuela', label: 'Venecuela', value: 'Venecuela' },
  { label_en: 'Vietnam', label: 'Vijetnam', value: 'Vijetnam' },
];