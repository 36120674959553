import React, { useCallback } from 'react';

import { Button, Input, Textarea } from 'src/components/common';
import { SendQuestionState, useSendQuestionForm } from './useSendQuestionForm';
import { useTranslation } from 'react-i18next';

const SendQuestionForm = () => {
  const { t } = useTranslation();
  const { sendQuestionForm, onFormSubmit, isSubmittingForm, formDefaultValues } = useSendQuestionForm();
  const { register, handleSubmit, formState, reset } = sendQuestionForm;
  const { errors } = formState;

  const onSubmit = useCallback(
    async (data: SendQuestionState) => {
      if (isSubmittingForm) {
        return;
      }

      await onFormSubmit(data);
      reset(formDefaultValues);
    },
    [isSubmittingForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Input
        {...register('name')}
        margin="0 0 32px 0"
        placeholder={t('placeholder_name')}
        errorMessage={errors.name?.message}
        width="100%"
      />

      <Input
        {...register('phone')}
        margin="0 0 32px 0"
        placeholder={t('placeholder_phone')}
        errorMessage={errors.phone?.message}
        width="100%"
      />

      <Input
        {...register('email')}
        margin="0 0 32px 0"
        placeholder={t('placeholder_email')}
        errorMessage={errors.email?.message}
        width="100%"
      />

      <Textarea
        {...register('question')}
        margin="0 0 64px 0"
        placeholder={t('placeholder_question')}
        errorMessage={errors.question?.message}
        width="100%"
      />

      <Button type="submit" variant="primaryDefault" text={t('btn_send')} minWidth={'200px'} margin={'0 auto'} />
    </form>
  );
};

export default SendQuestionForm;
