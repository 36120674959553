import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const CheckCircle = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', className = '', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} style={style} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        d="M10.7 15.6c-.3 0-.5-.1-.7-.3l-2.6-2.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l1.9 1.9 4.5-4.5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-5.2 5.2c-.2.2-.4.3-.7.3z"
      />
      <path fill={color} d="M12 23C5.9 23 1 18.1 1 12S5.9 1 12 1s11 4.9 11 11-4.9 11-11 11zm0-20c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9z" />
    </svg>
  );
};
