import { getYear, setHours, setMinutes } from 'date-fns';

export const config = {
  APP_NAME: 'Psihoterapija',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  MIN_HOUR_TIMESLOT: 8,
  MAX_HOUR_TIMESLOT: 25,
  CALENDAR_TIME_INTERVAL_IN_MINUTES: 60,
  CALENDAR_MAX_YEARS: getYear(new Date()) + 1,
  CALENDAR_MIN_TIME: setHours(setMinutes(new Date(), 0), 8),
  CALENDAR_MAX_TIME: setHours(setMinutes(new Date(), 0), 22),
  MIN_TIME_BEFORE_BOOKING_24: 86400000, // 24h in milliseconds
  MIN_TIME_BEFORE_BOOKING_48: 172800000, // 48h in milliseconds
  WHO_PAGE: 'https://www.who.int/publications/i/item/9789240057944',
  HIPPA_URL: 'https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html',
  PRIVACY_POLICY_URL: 'http://psihoterapija.app/terms-of-use',
  PAYMENT_POLICY_URL: 'http://psihoterapija.app/payment-policy',
  CONTACT_URL: 'http://psihoterapija.app/contact',
  COMPANY_NAME: 'Psihološko savjetovalište "Healing Hub"',
  MUNICIPAL_COURT_DECISION: '02/03-17-6892/23',
  ID_NUMBER: '4303904070006',
  CONTACT_PHONE: '+387 60 30 54 991',
  CONTACT_MAIL: 'info@psihoterapija.app',
};
