import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Card, Flex, Text, Grid } from 'src/components/common';
import { Spinner } from 'src/components/shared';
import { getTherapistStatistics } from 'src/store/slices/userSlice';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { StatisticsLabels } from 'src/constants/StatisticsLabels';
import { generateStatisticsData } from 'src/utils/generate-statistics-data';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';
ChartJS.register(...registerables);

const chartOptions: any = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      ticks: {
        precision: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const TherapistStatistics = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { therapistStatisticsLoading, therapistStatistics } = useAppSelector((state: RootState) => state.user);

  const [selectedLabelTab, setSelectedLabelTab] = useState(StatisticsLabels.COMPLETED_SESSIONS);

  const statisticsData = useMemo(() => {
    return generateStatisticsData(therapistStatistics);
  }, [therapistStatistics]);

  const activeLabelTabChartData = useMemo(() => {
    const chartData = statisticsData?.datasets?.find((item) => item.label === selectedLabelTab);

    return { ...statisticsData, datasets: [chartData] };
  }, [selectedLabelTab, statisticsData]);

  const totalForSelectedLabelTab = useCallback(
    (tab) => {
      const chartData = statisticsData?.datasets?.find((item) => item.label === tab);
      return chartData?.data.reduce((partialSum, a) => partialSum + a, 0);
    },
    [selectedLabelTab, statisticsData]
  );

  useEffect(() => {
    if (user) {
      dispatch(getTherapistStatistics(user.id));
    }
  }, [user]);

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'}>
      <Text variant={'heading6'} text={t('therapist_statistics_1')} />

      <Card width={'100%'} borderRadius={'20px'}>
        {therapistStatisticsLoading ? (
          <Spinner inline />
        ) : (
          <Grid
            gridTemplateColumns={isMobile ? '1fr' : `${'1fr '.repeat(Object.keys(StatisticsLabels).length)}`}
            margin="0 0 20px 0"
            gridTemplateRows={'1fr'}
            width={'100%'}
          >
            {Object.values(StatisticsLabels).map((tab, index) => (
              <Flex
                key={index}
                flexDirection="column"
                gap="10px"
                width={'100%'}
                boxShadow={'rgba(99, 99, 99, 0.2) 0 -1px 8px 0'}
                borderTop={tab === selectedLabelTab ? `4px solid ${theme.colors.palette.green}` : `3px solid transparent`}
                backgroundColor={tab === selectedLabelTab ? `${theme.colors.background.secondary.BG1}` : `transparent`}
                borderBottom={`1px solid ${theme.colors.border.darker}`}
                padding="24px 12px"
                onClick={() => setSelectedLabelTab(tab)}
              >
                <Text variant={'heading7'} text={tab} />
                <Text variant={'paragraph2'} text={`${t('therapist_statistics_2')} ${totalForSelectedLabelTab(tab)}`} />
              </Flex>
            ))}
          </Grid>
        )}
        <Line data={activeLabelTabChartData} options={chartOptions} />
      </Card>
    </Flex>
  );
};
