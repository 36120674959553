import React from 'react';
import styled from 'styled-components';

import { GridProps } from 'src/types/Grid';

const StyledGrid = styled.div<GridProps>`
  display: grid;
  width: ${(props) => props.width ?? 'auto'};
  min-width: ${(props) => props.minWidth ?? 'unset'};
  height: ${(props) => props.height ?? 'auto'};
  min-height: ${(props) => props.minHeight ?? 'unset'};
  margin: ${(props) => props.margin ?? '0px'};
  padding: ${(props) => props.padding ?? '0px'};
  grid-template-columns: ${(props) => props.gridTemplateColumns ?? 'none'};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  gap: ${(props) => props.gap ?? 'unset'};
  column-gap: ${(props) => props.columnGap};
  flex-direction: ${(props) => props.flexDirection};
  justify-items: ${(props) => props.justifyItems ?? 'legacy'};
  justify-content: ${(props) => props.justifyContent ?? 'start'};
  justify-self: ${(props) => props.justifySelf ?? 'auto'};
  align-content: ${(props) => props.alignContent ?? 'start'};
  align-items: ${(props) => props.alignItems ?? 'normal'};
  align-self: ${(props) => props.alignSelf ?? 'auto'};
  border: ${(props) => props.border ?? 'none'};
  border-top: ${(props) => (props.border ? props.border : props.borderTop ? props.borderTop : 'none')};
  border-right: ${(props) => (props.border ? props.border : props.borderRight ? props.borderRight : 'none')};
  border-bottom: ${(props) => (props.border ? props.border : props.borderBottom ? props.borderBottom : 'none')};
  border-left: ${(props) => (props.border ? props.border : props.borderLeft ? props.borderLeft : 'none')};
  background-color: ${(props) => props.backgroundColor ?? 'none'};
  max-width: ${(props) => props.maxWidth ?? 'unset'};
  position: ${(props) => props.position ?? 'relative'};
  flex: ${(props) => props.flex ?? '0 1 auto'};
`;

export const Grid = (props: GridProps): React.ReactElement => {
  const { children, ...rest } = props;

  return <StyledGrid {...rest}>{children}</StyledGrid>;
};
