import React from 'react';
import styled from 'styled-components';
import TimeAgoLib from 'react-timeago';
import bosnianStrings from 'react-timeago/lib/language-strings/bs';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(bosnianStrings);

const Wrapper = styled.div`
  display: flex;

  .timeago {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Barlow-Regular';
    width: max-content;
  }
`;

interface Props {
  date: string | Date;
}

export const TimeAgo = ({ date }: Props) => {
  return (
    <Wrapper>
      <TimeAgoLib className="timeago" date={new Date(date)} formatter={formatter} />
    </Wrapper>
  );
};
