import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const X = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        d="M13.4 12l5.3-5.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L12 10.6 6.7 5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L13.4 12z"
      />
    </svg>
  );
};
