import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTheme } from 'styled-components';

import { Text } from 'src/components/common';

interface AlertPopupProps {
  title?: string;
  message?: string;
  confirmAction: () => void;
  cancelAction: () => void;
}

export const AlertPopup = ({
  title = null,
  message = '',
  confirmAction,
  cancelAction,
}: AlertPopupProps) => {
  const theme = useTheme();

  return (
    <SweetAlert
      showCancel
      focusCancelBtn
      customIcon={undefined}
      cancelBtnText={'Ne'}
      confirmBtnText={'Da'}
      confirmBtnStyle={{
        width: '45%',
        padding: '12px 0',
        borderRadius: 12,
        color: 'black',
        boxShadow: 'none',
        border: `1px solid ${theme.colors.border.darker}`,
      }}
      cancelBtnStyle={{
        width: '45%',
        padding: '12px 0',
        borderRadius: 12,
        border: 'none',
        backgroundColor: theme.colors.palette.purple,
        color: 'white',
      }}
      title={title}
      onConfirm={confirmAction}
      onCancel={cancelAction}
      style={{
        zIndex: 9999,
        boxShadow: '0px 2px 54px rgba(9, 84, 146, 0.145012)',
        borderRadius: 12,
      }}
    >
      <Text width="75%" textAlign="center" variant="paragraph1" margin="24px auto 24px auto">
        {message}
      </Text>
    </SweetAlert>
  );
};
