import React, { useCallback } from 'react';

import { Button, Input } from 'src/components/common';
import { FormState, useResetPasswordForm } from 'src/components/shared/login-modal/form/reset-password/useResetPasswordForm';
import { useTranslation } from 'react-i18next';

interface FormProps {
  toggleReset: () => void;
}

const ResetPasswordForm = ({ toggleReset }: FormProps) => {
  const { t } = useTranslation();
  const { form, onFormSubmit, isSubmittingForm } = useResetPasswordForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const onSubmit = useCallback(
    async (data: FormState) => {
      if (isSubmittingForm) {
        return;
      }
      await onFormSubmit(data);
    },
    [isSubmittingForm]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Input
        {...register('email')}
        placeholder={t('placeholder_email')}
        errorMessage={errors.email?.message}
        width="100%"
        margin={'0 0 64px 0'}
      />
      <Button
        type="submit"
        variant="primaryDefault"
        text={t('btn_send')}
        minWidth={'200px'}
        margin={'auto auto 8px auto'}
        isLoading={isSubmittingForm}
      />
      <Button type="submit" variant="secondaryDefault" text={t('btn_back')} minWidth={'200px'} margin={'0 auto'} onClick={toggleReset} />
    </form>
  );
};

export default ResetPasswordForm;
