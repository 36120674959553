import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SubscriptionPlan } from 'src/types/SubscriptionPlan';
import { subscriptionsAPI } from 'src/api/subscription/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { IPayRequestDTO, ISubscribeRequestDTO, ISubscribeWithReferralRequestDTO } from 'src/api/subscription/RequestDtos';
import { storageService } from 'src/utils/storage';

export interface SubscriptionsState {
  loading: boolean;
  subscriptions: SubscriptionPlan[];
  selectedPlan: SubscriptionPlan;
}

const initialState: SubscriptionsState = {
  loading: false,
  subscriptions: [],
  selectedPlan: localStorage.getItem('selectedPlan') ? JSON.parse(localStorage.getItem('selectedPlan')) : undefined,
};

export const getSubscriptionPlans = createAsyncThunk('getSubscriptionPlans', async (_, thunkAPI) => {
  try {
    return await subscriptionsAPI.getAllSubscriptionPlans();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getSubscriptionPlans');
    return thunkAPI.rejectWithValue(error);
  }
});

export const subscribe = createAsyncThunk('subscribe', async (data: ISubscribeRequestDTO, thunkAPI) => {
  try {
    return await subscriptionsAPI.subscribe(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'subscribe');
    return thunkAPI.rejectWithValue(error);
  }
});

export const subscribeWithReferral = createAsyncThunk('subscribeWithReferral', async (data: ISubscribeWithReferralRequestDTO, thunkAPI) => {
  try {
    return await subscriptionsAPI.subscribeWithReferral(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'subscribeWithReferral');
    return thunkAPI.rejectWithValue(error);
  }
});

export const pay = createAsyncThunk('pay', async (data: IPayRequestDTO, thunkAPI) => {
  try {
    return await subscriptionsAPI.pay(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'pay');
    return thunkAPI.rejectWithValue(error);
  }
});

export const cancelSubscription = createAsyncThunk('cancelSubscription', async (_, thunkAPI) => {
  try {
    return await subscriptionsAPI.cancelSubscription();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'cancelSubscription');
    return thunkAPI.rejectWithValue(error);
  }
});

export const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;

      if (action.payload) {
        localStorage.setItem('selectedPlan', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('selectedPlan');
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscriptionPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptionPlans.fulfilled, (state, action: any) => {
        state.loading = false;
        state.subscriptions = action.payload.data;
      })
      .addCase(getSubscriptionPlans.rejected, (state) => {
        state.loading = false;
        state.subscriptions = [];
      })
      .addCase(pay.fulfilled, (state, action: any) => {
        storageService.clearAppointment();
      });
  },
});

export const { setSelectedPlan } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
