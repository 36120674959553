import React from 'react';
import { useTheme } from 'styled-components';

import { InlineTextWrapper } from 'src/components/shared';
import { Flex, Modal, Text } from 'src/components/common';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { useTranslation } from 'react-i18next';

interface GetMatchedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutPrivacyModal = ({ isOpen, onClose }: GetMatchedModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const visitWHOPage = () => {
    goToUrl(config.HIPPA_URL, '_blank');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} padding={'40px 10px'}>
        <InlineTextWrapper>
          <Text variant="paragraph1" text={t('hippa_privacy_1')} margin="0 4px 0 0" />
          <Text
            variant="paragraph1"
            text={'HIPPA'}
            margin="0 4px 0 0"
            color={theme.colors.palette.blue}
            style={{ textDecoration: 'underline' }}
            onClick={visitWHOPage}
          />
          <Text variant="paragraph1" text={t('hippa_privacy_2')} />
        </InlineTextWrapper>
      </Flex>
    </Modal>
  );
};
