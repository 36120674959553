import { Appointment } from 'src/types/Appointment';
import * as CryptoJS from 'crypto-js';

export default class StorageService {
  setToken(value: string) {
    localStorage.setItem('token', value);
  }

  getToken() {
    return localStorage.getItem('token') ?? null;
  }

  setAppointment(value: Appointment) {
    const json = JSON.stringify(value);
    const crypt = CryptoJS.AES.encrypt(json, process.env.REACT_APP_SECRET_KEY).toString();
    localStorage.setItem('appointment', crypt);
  }

  getAppointment() {
    const json = localStorage.getItem('appointment');
    if (json == null || json == undefined) return null;
    const bytes = CryptoJS.AES.decrypt(json, process.env.REACT_APP_SECRET_KEY);
    const decrypt = bytes.toString(CryptoJS.enc.Utf8);
    const model = JSON.parse(decrypt);
    return model ?? null;
  }

  clearAppointment() {
    localStorage.removeItem('appointment');
  }

  clear() {
    localStorage.clear();
  }
}

export const storageService = new StorageService();
