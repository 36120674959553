import React, { useMemo, useContext, useEffect, useCallback, useState, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import classnames from 'classnames';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { AlertPopup, InlineTextWrapper } from 'src/components/shared';
import { Card, Flex, Text, Button } from 'src/components/common';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { SubscriptionPlan } from 'src/types/SubscriptionPlan';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { getSubscriptionPlans, setSelectedPlan, cancelSubscription } from 'src/store/slices/subscriptionsSlice';
import { MonriPaymentInputs } from 'src/pages/dashboard/MonriPaymentInputs';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { UseReferralModal } from './modals/UseReferralModal';
import { useTranslation } from 'react-i18next';

export const SubscriptionPlanCard = styled.div`
  min-height: ${(props) => props.minHeight || '100%'};
  width: 100%;
  background-color: ${(props) => props.theme.colors.palette.lightPurple};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  outline-offset: 2px;
  outline: 5px solid transparent;
  transition: all 0.2s;

  * {
    cursor: pointer;
  }

  &.selected {
    transform: scale(1.1);
    transition: all 0.2s;
    background-color: ${(props) => props.theme.colors.palette.purple};
  }

  &.active {
    outline: 5px solid ${(props) => props.theme.colors.palette.green};
    outline-offset: 2px;
  }
`;

export const SubscriptionPlanCardTitle = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
`;

export const TherapistSubscriptions = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    therapistDashboard: { activeTab },
  } = useAppSelector((state: RootState) => state.global);

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { subscriptions, selectedPlan } = useAppSelector((state: RootState) => state.subscriptions);

  const formRef = useRef<HTMLFormElement>();
  const [cancelSubscriptionAlert, setCancelSubscriptionAlert] = useState(null);
  const { isOpen: isReferralModalOpen, close: closeReferralModal, open: openReferralModal } = useDisclosure(false);

  const activeSubscription = useMemo(() => {
    const found = subscriptions.find((s) => s.id === user?.subscription?.subscriptionPackageId);
    found && dispatch(setSelectedPlan(found));
    return found;
  }, [user, subscriptions]);

  const isSubscriptionActive = useCallback(
    (id: number) => {
      return user?.subscription?.subscriptionPackageId === id;
    },
    [user]
  );

  const setSelectedSubbscriptionPlan = async (plan: SubscriptionPlan) => {
    await dispatch(setSelectedPlan(plan));
  };

  const startPayment = () => {
    formRef.current && formRef.current.submit();
    close();
  };

  const cancelActiveSubscription = async () => {
    try {
      const response: any = await dispatch(cancelSubscription());
      if (response.payload?.success) {
        ToastNotifications.success(response.payload?.data);
        setSelectedPlan(null);
        hideDeleteSessionAlert();
        await dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'cancelSubscripion');
    }
  };

  const showCancelSessionAlert = () => {
    setCancelSubscriptionAlert(
      <AlertPopup
        title={t('therapist_subscription_1')}
        message={t('therapist_subscription_2')}
        confirmAction={() => cancelActiveSubscription()}
        cancelAction={hideDeleteSessionAlert}
      />
    );
  };

  const hideDeleteSessionAlert = () => {
    setCancelSubscriptionAlert(null);
  };

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, [activeTab]);

  return (
    <Flex flexDirection="column" width={'100%'} gap={'16px'}>
      <Text variant={'heading6'} text={t('therapist_subscription_3')} margin={isMobile ? '0 0 24px 0' : '0'} />

      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={'100%'}
          height={isMobile ? 'auto' : '75vh'}
          padding={isMobile ? '48px 24px' : '24px'}
          gap={'32px'}
        >
          <Flex
            width={'100%'}
            height={'100%'}
            gap={isMobile ? '80px' : '20px'}
            flex={'1'}
            alignItems="center"
            justifyContent="center"
            flexDirection={isMobile ? 'column' : 'row'}
            flexWrap={isMobile ? 'unset' : 'wrap'}
          >
            {subscriptions.map((item, index) => (
              <Flex
                key={index}
                flexDirection={'column'}
                width={isMobile ? '100%' : `${100 / (subscriptions.length + 1)}%`}
                height={'50%'}
                alignItems="center"
                justifyContent="center"
                onClick={() => setSelectedSubbscriptionPlan(item)}
              >
                <SubscriptionPlanCard
                  minHeight={isMobile ? '200px' : '100%'}
                  className={classnames('', {
                    selected: selectedPlan?.id === item.id,
                    active: isSubscriptionActive(item.id),
                  })}
                >
                  <SubscriptionPlanCardTitle>
                    <Text variant={'heading6'} width={'100%'} text={item.name} margin={'0 0 8px 0'} />
                  </SubscriptionPlanCardTitle>

                  <InlineTextWrapper>
                    <Text
                      variant={'heading2'}
                      text={item.price}
                      color={selectedPlan?.id === item.id ? theme.colors.palette.white : theme.colors.palette.purple}
                    />
                    <Text
                      variant={'heading5'}
                      text={'BAM'}
                      margin={'0 0 0 4px'}
                      color={selectedPlan?.id === item.id ? theme.colors.palette.white : theme.colors.palette.purple}
                    />
                  </InlineTextWrapper>

                  {activeSubscription && item.id === activeSubscription.id && (
                    <Flex width="100%" margin={'12px 0 0 0'} alignItems="center" justifyContent="center" flexDirection="column">
                      <Text
                        variant={isMobile ? 'paragraph3' : 'paragraph4'}
                        text={t('therapist_subscription_4')}
                        margin="0 4px 0 0"
                        color={selectedPlan?.id === item.id ? theme.colors.palette.white : theme.colors.palette.purple}
                      />
                      <Text
                        variant={isMobile ? 'paragraph2' : 'paragraph3'}
                        text={moment(user?.subscription?.endDate).format('DD/MM/YYYY HH:mm')}
                        color={selectedPlan?.id === item.id ? theme.colors.palette.white : theme.colors.palette.purple}
                      />
                    </Flex>
                  )}
                </SubscriptionPlanCard>
              </Flex>
            ))}
          </Flex>

          <form ref={formRef} action={process.env.REACT_APP_MONRI_URL} id="monri-integration-form-prepare" method="POST">
            <MonriPaymentInputs user={user} onPaymentSuccessful={() => {}} />
          </form>

          {selectedPlan && (
            <>
              {selectedPlan?.id === activeSubscription?.id ? (
                <Button
                  type="button"
                  variant={'redDefault'}
                  text={t('btn_cancel_subscription')}
                  minWidth="200px"
                  width={isMobile ? '100%' : 'auto'}
                  onClick={() => showCancelSessionAlert()}
                />
              ) : (
                <Button
                  type="button"
                  variant={'primaryDefault'}
                  text={t('btn_subscribe')}
                  minWidth="200px"
                  width={isMobile ? '100%' : 'auto'}
                  onClick={() => startPayment()}
                />
              )}
            </>
          )}

          <Text
            text={t('therapist_subscription_5')}
            onClick={openReferralModal}
            width={'100%'}
            textAlign={'center'}
            margin={'0 0 32px 0'}
          />
        </Flex>
      </Card>

      {cancelSubscriptionAlert}

      <UseReferralModal isOpen={isReferralModalOpen} onClose={closeReferralModal} />
    </Flex>
  );
};
