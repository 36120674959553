import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import { Button, Grid, Flex, Modal, Text } from 'src/components/common';
import { getDefaultAvailableTimesForDatepicker } from 'src/utils/get-default-available-times-for-datepicker';
import { getTimeFromDate, checkIfDateAndTimestampAreEqualByHourDayMonthYear } from 'src/utils/date-parser';
import { Availability } from 'src/types/Availability';
import { userAPI } from 'src/api/user/ApiRequests';
import { useAppDispatch } from 'src/store';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { Appointment } from 'src/types/Appointment';

const LegendIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
`;

const TimeslotCard = styled.div`
  display: flex;
  position: relative;
  z-index: 1000;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 12px 24px;
  border: 1px solid
    ${(props) =>
      props.isAppointmentDate
        ? props.theme.colors.palette.red
        : props.alreadySetAsAvailable
        ? props.theme.colors.palette.green
        : props.disabled
        ? props.theme.colors.border.darker
        : props.theme.colors.palette.darkPurple};
  background-color: ${(props) =>
    props.isAppointmentDate
      ? props.theme.colors.palette.red
      : props.alreadySetAsAvailable
      ? props.theme.colors.palette.green
      : props.disabled
      ? props.theme.colors.background.secondary.BG1
      : props.selected
      ? props.theme.colors.palette.purple
      : props.theme.colors.palette.white};
  color: ${(props) =>
    props.isAppointmentDate || props.alreadySetAsAvailable || props.selected
      ? props.theme.colors.palette.white
      : props.disabled
      ? props.theme.colors.text.tertiary
      : props.theme.colors.palette.black};
  cursor: ${(props) => (props.isAppointmentDate || props.alreadySetAsAvailable || props.disabled ? 'not-allowed' : 'pointer')} !important;

  * {
    cursor: ${(props) => (props.isAppointmentDate || props.alreadySetAsAvailable || props.disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

const TimeSlotCardRemoveIcon = styled.div`
  position: absolute;
  z-index: 1001;
  top: -4px;
  right: -4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }
`;

interface SetAvailabilityTimesModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: Date;
  currentAvailabilities: Availability[];
  upcomingAppointments: Appointment[];
}

export const SetAvailabilityTimesModal = ({
  isOpen,
  onClose,
  selectedDate,
  currentAvailabilities,
  upcomingAppointments,
}: SetAvailabilityTimesModalProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [selectedAvailabilityDates, setSelectedAvailabilityDates] = useState([]);
  const [saveAvailabilitiesLoading, setSaveAvailabilitiesLoading] = useState(false);

  const isDateSelected = (date: Date) => {
    return selectedAvailabilityDates.map((d) => d.getTime()).includes(date.getTime());
  };

  const isDateAlreadySetAsAvailable = (date: Date) => {
    return !!currentAvailabilities?.find((item) => {
      return checkIfDateAndTimestampAreEqualByHourDayMonthYear(date, item.date);
    });
  };

  const isDateAppointmentDate = (date: Date) => {
    return !!upcomingAppointments?.find((appointment) => {
      return checkIfDateAndTimestampAreEqualByHourDayMonthYear(date, appointment.date);
    });
  };

  const isDateInPast = (date: Date) => {
    return date.getTime() <= Date.now();
  };

  const handleDateSelect = (date: Date) => {
    const found = selectedAvailabilityDates.find((d) => d.getTime() === date.getTime());

    if (!found) {
      setSelectedAvailabilityDates([...selectedAvailabilityDates, date]);
    } else {
      const filtered = selectedAvailabilityDates.filter((d) => d.getTime() !== date.getTime());
      setSelectedAvailabilityDates(filtered);
    }
  };

  const dayInTitle = useMemo(() => {
    return selectedDate.toLocaleDateString('en-GB');
  }, [selectedDate]);

  const availableOnSelectedDate = useMemo(() => {
    return getDefaultAvailableTimesForDatepicker(selectedDate);
  }, [selectedDate]);

  const saveAvailabilities = async () => {
    setSaveAvailabilitiesLoading(true);

    try {
      const currentAvailabilityTimestamps = (currentAvailabilities ?? []).map((item) => item.date);
      const selectedAvailabilityTimestamps = selectedAvailabilityDates.map((d) => {
        const date = new Date(d.getTime());
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.getTime();
      });
    
      const totalAvailabilityTimestamps = [...currentAvailabilityTimestamps, ...selectedAvailabilityTimestamps];
      const response = await userAPI.setTherapistAvailability(totalAvailabilityTimestamps);

      if (response.success) {
        ToastNotifications.success(response.data);

        setSelectedAvailabilityDates([]);
        onClose();
        dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'saveAvailabilities');
    } finally {
      setSaveAvailabilitiesLoading(false);
    }
  };

  const deleteAvailability = async (availabilityId: string) => {
    if (!availabilityId) {
      return ToastNotifications.error('Greška. Nedostaje "availabilityId" parametar', 'missingAvailabilityId');
    }

    try {
      const response = await userAPI.deleteTherapistAvailability(availabilityId);

      if (response.success) {
        ToastNotifications.success(response.data);
        dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'deleteAvailability');
    }
  };

  const setAllDayAvailable = () => {
    if (selectedAvailabilityDates == availableOnSelectedDate) {
      setSelectedAvailabilityDates([]);
    } else {
      setSelectedAvailabilityDates(availableOnSelectedDate);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'20px'} padding={'20px 30px'}>
        <Text
          variant="heading6"
          text={`Postavite vremena dostupnosti za dan ${dayInTitle}`}
          margin={'0 0 16px 0'}
          width={'100%'}
          textAlign={'center'}
        />

        <Flex flexDirection="column" width="100%" gap="10px">
          <Flex alignItems="center" gap="8px" width="100%">
            <LegendIndicator backgroundColor={theme.colors.palette.red} />
            <Text variant="paragraph2" text={'Termini sa zakazanim sesijama'} />
          </Flex>

          <Flex alignItems="center" gap="8px" width="100%">
            <LegendIndicator backgroundColor={theme.colors.palette.green} />
            <Text variant="paragraph2" text={'Termini označeni već kao dostupni'} />
          </Flex>
        </Flex>

        <Grid width="100%" gridTemplateRows="1fr" gridTemplateColumns="1fr 1fr" gap="8px">
          {availableOnSelectedDate.map((date, index) => {
            return (
              <TimeslotCard
                key={index}
                disabled={isDateInPast(date)}
                alreadySetAsAvailable={isDateAlreadySetAsAvailable(date)}
                isAppointmentDate={isDateAppointmentDate(date)}
                selected={isDateSelected(date)}
                onClick={() => {
                  if (isDateAlreadySetAsAvailable(date)) {
                    return;
                  }

                  handleDateSelect(date);
                }}
              >
                {getTimeFromDate(date)}
                {isDateAlreadySetAsAvailable(date) && !isDateAppointmentDate(date) && (
                  <TimeSlotCardRemoveIcon
                    onClick={async () => {
                      const availabilityToDelete = currentAvailabilities?.find((item) => {
                        return checkIfDateAndTimestampAreEqualByHourDayMonthYear(date, item.date);
                      });

                      await deleteAvailability(availabilityToDelete?.id ? availabilityToDelete?.id.toString() : null);
                    }}
                  >
                    <FontAwesomeIcon size="xs" icon={faX} color={theme.colors.palette.white} />
                  </TimeSlotCardRemoveIcon>
                )}
              </TimeslotCard>
            );
          })}
        </Grid>

        <Text variant="paragraph1" text={'Kliknite ovdje kako bi označili sve termine'} onClick={setAllDayAvailable} />

        <Button
          isLoading={saveAvailabilitiesLoading}
          variant="primaryLarge"
          margin="16px 0 0 0"
          onClick={saveAvailabilities}
          width="90%"
          text={'Spremi'}
        />
      </Flex>
    </Modal>
  );
};
