import React, { useMemo, useContext, useState, useEffect } from 'react';
import { RootState, useAppSelector } from 'src/store';
import { Card, Flex, Text } from 'src/components/common';
import { DateTimePicker } from 'src/components/shared';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { checkIfDateAndTimestampAreEqualByHourDayMonthYear } from 'src/utils/date-parser';
import { setYear, setMonth, setDay, setHours, setMinutes, setSeconds } from 'date-fns';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { config } from 'src/config/config';
import { SetAvailabilityTimesModal } from './modals/SetAvailabilityTimesModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const TherapistCalendar = () => {
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [selectedDate, setSelectedDate] = useState(new Date(config.CALENDAR_MIN_TIME));
  const { isOpen, close, open } = useDisclosure(false);

  const upcomingAppointments = useMemo(() => {
    return user?.appointments?.filter((appointment) => appointment.date > Date.now()) ?? [];
  }, [user]);

  const bookedDates = useMemo(() => {
    return upcomingAppointments.map((a) => new Date(Number(a.date))) ?? [];
  }, [upcomingAppointments]);

  const availabilityDates = useMemo(() => {
    return user?.availabilities.map((a) => new Date(Number(a.date)));
  }, [user]);

  const availablilityAndAppointmentMatchDates = useMemo(() => {
    return upcomingAppointments
      .map((appointment) => {
        const isMatched = !!availabilityDates?.find((date) => checkIfDateAndTimestampAreEqualByHourDayMonthYear(date, appointment.date));
        return isMatched ? new Date(Number(appointment.date)) : null;
      })
      .filter(Boolean);
  }, [upcomingAppointments, availabilityDates]);

  const highlightedDates = useMemo(() => {
    return [
      {
        'react-datepicker__day--highlighted-available-to-book': availabilityDates ?? [],
      },
      {
        'react-datepicker__day--highlighted-booked': bookedDates ?? [],
      },
      {
        'react-datepicker__day--highlighted-availability-booked-match': availablilityAndAppointmentMatchDates ?? [],
      },
    ];
  }, [availabilityDates, bookedDates, availablilityAndAppointmentMatchDates]);

  const getExcludedTimes = (date: Date) => {
    let sameDates = [];

    if (upcomingAppointments && upcomingAppointments.length > 0) {
      for (let i = 0; i < upcomingAppointments.length; i++) {
        const formatted = moment(date, moment.ISO_8601).format('YYYY/MM/DD');
        const formattedAppointment = moment(upcomingAppointments[i].date, moment.ISO_8601).format('YYYY/MM/DD');
        if (formatted === formattedAppointment) {
          const sameDate = new Date(upcomingAppointments[i].date);
          sameDates = [...sameDates, sameDate];
        }
      }
    }

    let excludedTimes = [];
    if (sameDates && sameDates.length > 0) {
      for (let i = 0; i < sameDates.length; i++) {
        const time = setYear(
          setMonth(
            setDay(setHours(setMinutes(setSeconds(new Date(), 0), 0), sameDates[i].getHours()), sameDates[i].getDay() + 7),
            sameDates[i].getMonth()
          ),
          sameDates[i].getFullYear()
        );
        excludedTimes = [...excludedTimes, time];
      }
    }
  };

  useEffect(() => {
    getExcludedTimes(selectedDate);
  }, [selectedDate]);

  return (
    <Flex flexDirection="column" width={'100%'} gap={'24px'}>
      <SetAvailabilityTimesModal
        isOpen={isOpen}
        onClose={close}
        selectedDate={selectedDate}
        currentAvailabilities={user?.availabilities}
        upcomingAppointments={upcomingAppointments}
      />
      <Text variant={'heading6'} text={t('therapist_calendar')} />
      <Card width={'100%'} borderRadius={'20px'} margin={isMobile ? '0 0 24px 0' : '0 0 48px 0'}>
        <DateTimePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onSelect={(date: Date) => {
            getExcludedTimes(date);
            open();
          }}
          highlightDates={highlightedDates}
          timeIntervalInMinutes={config.CALENDAR_TIME_INTERVAL_IN_MINUTES}
          minTime={config.CALENDAR_MIN_TIME}
          maxTime={config.CALENDAR_MAX_TIME}
          maxYears={config.CALENDAR_MAX_YEARS}
          excludeOutOfBoundsTimes={true}
          dayWrapperSize={isMobile ? '2rem' : '5rem'}
          dayTextSize={18}
          monthWidth={'30%'}
          timeTrackWidth={isMobile ? '15%' : '20%'}
          timeTrackVisible={false}
          monthTrackWidth={'100%'}
          monthYearHeaderMargin={isMobile ? 0 : 10}
        />
      </Card>{' '}
    </Flex>
  );
};
