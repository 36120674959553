import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import _ from 'lodash';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Card, Flex, Grid, Text } from 'src/components/common';
import { recommendations } from 'src/assets/data/recommendations';
import GlavnaIlustracija from 'src/assets/images/about-us/o-nama-glavna-ilustracija.svg';
import StarIconFilled from 'src/assets/images/about-us/star-icon-filled.svg';
import StarIconOutline from 'src/assets/images/about-us/star-icon-outline.svg';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';
import { InlineTextWrapper } from 'src/components/shared';

export const AboutUs = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const maxStars = _.maxBy(recommendations(), 'stars').stars;

  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'40px'}
        padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}
      >
        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading4'} text={t('about_us_1')} margin={'0 0 40px 0'} textAlign={isMobile ? 'center' : 'left'} />
          <Text variant={'paragraph1'} text={t('about_us_2')} margin={'0 0 32px 0'} textAlign={isMobile ? 'center' : 'left'} />
          <Text variant={'paragraph1'} text={t('about_us_3')} margin={'0 0 32px 0'} textAlign={isMobile ? 'center' : 'left'} />
          <InlineTextWrapper margin={'0 0 16px 0'}>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('about_us_4')} `} style={{ fontFamily: 'Barlow-Bold' }} />
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('about_us_5')} />
          </InlineTextWrapper>
          <InlineTextWrapper margin={'0 0 16px 0'}>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('about_us_6')} `} style={{ fontFamily: 'Barlow-Bold' }} />
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('about_us_7')} />
          </InlineTextWrapper>
          <InlineTextWrapper margin={'0 0 16px 0'}>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('about_us_8')} `} style={{ fontFamily: 'Barlow-Bold' }} />
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('about_us_9')} />
          </InlineTextWrapper>
          <InlineTextWrapper margin={'0 0 16px 0'}>
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('about_us_10')} `} style={{ fontFamily: 'Barlow-Bold' }} />
            <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('about_us_11')} />
          </InlineTextWrapper>
          <Text variant={'paragraph1'} text={t('about_us_12')} margin={'0 0 32px 0'} textAlign={isMobile ? 'center' : 'left'} />
          <Text variant={'paragraph1'} text={t('about_us_13')} margin={'0 0 0 0'} textAlign={isMobile ? 'center' : 'left'} />
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} alignItems={'flex-end'} flexDirection={'column'}>
          <img src={GlavnaIlustracija} alt="o-nama-glavna-ilustracija" width={'90%'} height={'auto'} />
        </Flex>
      </Grid>

      <Text
        variant={'heading4'}
        text={t('about_us_14')}
        margin={'20px 0'}
        padding={isMobile ? '0 20px' : `0 ${maxHorizontalPadding}`}
        textAlign={isMobile ? 'center' : 'left'}
      />

      <Grid
        padding={isMobile ? '20px' : `20px ${maxHorizontalPadding} 48px ${maxHorizontalPadding}`}
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'20px'}
      >
        {recommendations().map((item, index) => (
          <Card
            key={index}
            width={'100%'}
            border={`1px solid ${theme.colors.palette.purple}`}
            backgroundColor={'rgb(250, 250, 250)'}
            borderRadius={'20px'}
            padding={'24px'}
            alignItems={'center'}
          >
            <Flex width={'100%'} gap={'16px'} justifyContent={'center'}>
              <img src={item.icon} alt="" height={'100px'} />

              <Flex flexDirection={'column'} gap={'4px'}>
                <Flex alignItems={'center'}>
                  {_.times(item.stars, (i) => (
                    <img key={i} src={StarIconFilled} alt="star-icon" width={'24px'} height={'auto'} />
                  ))}
                  {_.times(maxStars - item.stars, (i) => (
                    <img key={i} src={StarIconOutline} alt="star-icon" width={'24px'} height={'auto'} />
                  ))}
                </Flex>

                <Text variant={'heading5'} text={item.name} />

                <Text variant={'paragraph2'} text={item.position} />
              </Flex>
            </Flex>

            <Flex width={'100%'} margin={'24px 0 0 0'}>
              <Text width={'100%'} variant={'paragraph1'} text={item.text} />
            </Flex>
          </Card>
        ))}
      </Grid>
    </Flex>
  );
};
