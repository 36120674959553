import { createSlice } from '@reduxjs/toolkit';
import { Appointment } from 'src/types/Appointment';
import { storageService } from 'src/utils/storage';

export interface SessionState {
  loading: boolean;
  session: Appointment;
}

const initialState: SessionState = {
  loading: false,
  session: storageService.getAppointment(),
};

export const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.session = action.payload;
      storageService.setAppointment(action.payload);
    },
  },
  extraReducers() {},
});

export const { setSelectedSession } = sessionSlice.actions;

export default sessionSlice.reducer;
