import styled, { DefaultTheme } from 'styled-components';

import { RadioButtonStyles } from 'src/types/RadioButton';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: start;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const RadioBox = styled.div<{
  theme: DefaultTheme;
  styles: RadioButtonStyles;
}>`
  height: ${(props) => props.styles.size};
  width: ${(props) => props.styles.size};
  border: 1px solid ${(props) => props.theme.colors.palette.purple};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.4rem;
  transition: background 0.15s, border-color 0.15s;
  padding: 2px;

  &::after {
    content: '';
    width: ${(props) => props.styles.radioSize};
    height: ${(props) => props.styles.radioSize};
    display: block;
    background: ${(props) => props.theme.colors.palette.purple};
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
      &::after {
        transform: scale(1);
      }
`;
