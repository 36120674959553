import React from 'react';
import { components, PlaceholderProps, SingleValueProps, ValueContainerProps } from 'react-select';
import { SelectDropdownOption } from '../SelectDropdown.types';

export const CustomValueContainer = ({ children, ...props }: ValueContainerProps<SelectDropdownOption>) => {
  return (
    <components.ValueContainer {...props}>
      {React.Children.map(children, (child) => {
        if (child) {
          return child;
        } else if (props.hasValue) {
          return (
            <components.SingleValue {...(props as SingleValueProps<SelectDropdownOption>)}>
              {props.selectProps.getOptionLabel(props.getValue()[0])}
            </components.SingleValue>
          );
        } else {
          return (
            <components.Placeholder {...(props as PlaceholderProps<SelectDropdownOption>)}>
              {props.selectProps.placeholder}
            </components.Placeholder>
          );
        }
      })}
    </components.ValueContainer>
  );
};
