import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { FlexProps } from 'src/types/Flex';

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  overflow: ${(props) => props.overflow ?? 'visible'};
  overflow-x: ${(props) => (props.overflowX && !props.overflow ? props.overflowX : 'none')};
  overflow-y: ${(props) => (props.overflowY && !props.overflow ? props.overflowY : 'none')};
  flex-wrap: ${(props) => props.flexWrap ?? 'unset'};
  width: ${(props) => props.width ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  min-height: ${(props) => props.minHeight ?? 'unset'};
  margin: ${(props) => props.margin ?? '0px'};
  padding: ${(props) => props.padding ?? '0px'};
  gap: ${(props) => props.gap ?? 'unset'};
  grid-row: ${(props) => props.gridRow ?? 'auto / auto'};
  grid-column: ${(props) => props.gridColumn ?? 'auto / auto'};
  flex-direction: ${(props) => props.flexDirection ?? 'row'};
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  align-items: ${(props) => props.alignItems ?? 'flex-start'};
  border-radius: ${(props) => props.borderRadius ?? '0px'};
  border: ${(props) => props.border ?? 'none'};
  border-top: ${(props) => (props.border ? props.border : props.borderTop ? props.borderTop : 'none')};
  border-right: ${(props) => (props.border ? props.border : props.borderRight ? props.borderRight : 'none')};
  border-bottom: ${(props) => (props.border ? props.border : props.borderBottom ? props.borderBottom : 'none')};
  border-left: ${(props) => (props.border ? props.border : props.borderLeft ? props.borderLeft : 'none')};
  cursor: ${(props) => props.cursor};
  background: ${(props) => props.backgroundColor ?? 'unset'};
  max-width: ${(props) => props.maxWidth ?? 'unset'};
  position: ${(props) => props.position ?? 'relative'};
  top: ${(props) => (props.position && props.position === 'absolute' && props.top ? props.top : 'unset')};
  right: ${(props) => (props.position && props.position === 'absolute' && props.right ? props.right : 'unset')};
  bottom: ${(props) => (props.position && props.position === 'absolute' && props.bottom ? props.bottom : 'unset')};
  left: ${(props) => (props.position && props.position === 'absolute' && props.left ? props.left : 'unset')};
  z-index: ${(props) => props.zIndex ?? 'auto'};
  flex: ${(props) => props.flex ?? '0 1 auto'};
  order: ${(props) => props.order ?? '0'};
  box-shadow: ${(props) => props.boxShadow ?? 'none'};

  * {
    cursor: ${(props) => (props.cursor === 'pointer' ? 'pointer !important' : 'initial')};
  }
`;

export const Flex = forwardRef((props: FlexProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
  const { onClick = undefined, children, cursor = onClick ? 'pointer' : 'auto', ...rest } = props;

  return (
    <StyledFlex
      ref={ref}
      cursor={cursor}
      onClick={() => {
        typeof onClick === 'function' && onClick();
      }}
      {...rest}
    >
      {children}
    </StyledFlex>
  );
});
