import React, { useState, useContext } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { IsMobileContext } from 'src/context/IsMobileContext';
import './styles.css';

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LocalBA from 'src/assets/images/locales/ba.png';
import translationBA from '../../../locales/ba.json';
import LocalEN from 'src/assets/images/locales/en.png';
import translationEN from '../../../locales/en.json';

import { setLanguage } from 'src/store/slices/globalSlice';

const resources = {
  ba: { translation: translationBA },
  en: { translation: translationEN },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 'ba',
  fallbackLng: 'ba',
  interpolation: {
    escapeValue: false,
  },
});

const languages = [
  { code: 'ba', name: 'Bosanski', flag: LocalBA },
  { code: 'en', name: 'English', flag: LocalEN },
  // Add more languages as needed
];

export default function LanguageSelector() {
  const dispatch = useAppDispatch();

  const { isMobile } = useContext(IsMobileContext);
  const { language } = useAppSelector((state: RootState) => state.global);

  const [isActive, setActive] = useState(false);

  function changeLanguage(langauge) {
    dispatch(setLanguage(langauge.code));
    setActive(false);
    i18n.changeLanguage(langauge.code);
  }

  return (
    <div className="dropdown" style={{ display: 'none' }}>
      <div onClick={() => setActive(!isActive)} className="dropdown-btn">
        <img
          src={languages.find((lang) => lang.code == language).flag}
          alt="Locale"
          width={isMobile ? '30px' : '40px'}
          height={isMobile ? '30px' : '40px'}
        />
        <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
      </div>
      <div className="dropdown-content" style={{ display: isActive ? 'block' : 'none' }}>
        {languages.map((language) => {
          return (
            <div key={language.code} onClick={() => changeLanguage(language)} className="item">
              <img src={language.flag} alt="Locale" width={isMobile ? '30px' : '40px'} height={isMobile ? '30px' : '40px'} />
              {language.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
