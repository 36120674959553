import axios, { AxiosRequestConfig } from 'axios';
import { ICitiesResponseDTO } from './ResponseDtos';
import { COUNTRIES_OPTIONS_FOR_DROPDOWN_BS } from 'src/assets/data/countries-options-for-dropdown';

export default class CitiesAPI {
  public getCities(country: string): Promise<ICitiesResponseDTO> {
    const country_eng = COUNTRIES_OPTIONS_FOR_DROPDOWN_BS.find(o => o.value === country)?.label_en;

    const axiosObject: AxiosRequestConfig = {
      method: 'POST',
      url: 'https://countriesnow.space/api/v0.1/countries/cities',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { country: country_eng ?? '' },
    };

    return axios(axiosObject);
  }
}

export const citiesAPI = new CitiesAPI();
