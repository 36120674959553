import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const ChevronRightIcon = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', style = {}, className = '' } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      width={size}
      height={size}
      className={className}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill={color}
        d='M9.5 20c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l6.3-6.3-6.3-6.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l7 7c.4.4.4 1 0 1.4l-7 7c-.2.2-.4.3-.7.3z'
      />
    </svg>
  );
};
