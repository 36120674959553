import React from 'react';
import { useTheme } from 'styled-components';
import { Modal, Text, Flex, Button } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';

import DostupnostImg from 'src/assets/images/tutorial/dostupnost.png';
import RezervacijaiImg from 'src/assets/images/tutorial/rezervacija.png';
import ProfilImg from 'src/assets/images/tutorial/priofil2.png';
import RegistracijaImg from 'src/assets/images/tutorial/resitracija.png';
import OpisImg from 'src/assets/images/tutorial/opis.png';
import VidljivostImg from 'src/assets/images/tutorial/vidljivost.png';

export interface TutorialModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const TutorialModal = ({ isOpen, onClose }: TutorialModalProps) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large" backgroundColor={theme.colors.background.secondary.BG4}>
      <Text variant={'heading5'} text={'Kako efikasno koristiti platformu'} margin={'0 0 32px 0'} />

      <Text variant={'heading6'} text={'Kako podesiti vašu dostupnost na platformi?'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={'Nakon što se prijavite u svoj profil, posjetite donji dio početne stranice gdje ćete pronaći kalendar.'}
        />
        <Text
          variant={'paragraph1'}
          whiteSpace="pre-wrap"
          text={'\nU kalendaru možete definisati svoju dostupnost.'}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={DostupnostImg} alt="" width={'80%'} height={'auto'} />
      </Flex>

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Ovo je ključni faktor koji utiče na broj klijenata koji će biti povezani s vama. Ako ne odredite dostupnost, ' +
            'nećete biti uzeti u obzir prilikom uparivanja sa novim klijentima. Preporučujemo da postavite ' +
            'dostupnost za što duži vremenski period.'
          }
        />
        <Text variant={'paragraph1'} text={' Redovno ažurirajte '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text
          variant={'paragraph1'}
          text={
            'kalendar i oslobađajte termine zauzete ' +
            'privatnim i profesionalnim obavezama. Vaša dostupnost je od velike važnosti jer klijenti mogu direktno ' +
            'rezervisati i platiti sesije. Ako niste dostupni u terminu koji ste naveli kao dostupan, klijentu će biti ' +
            'vraćen novac. Da biste imali dovoljno vremena za organizaciju,'
          }
        />
        <Text
          variant={'paragraph1'}
          text={' ograničili smo rezervacije na minimum 48 sati unaprijed. '}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
        <Text
          variant={'paragraph1'}
          text={
            'To znači da klijenti ne mogu rezervisati termin za sutra ili prekosutra, već samo one ' +
            'dostupne 48 sati unaprijed. Ako klijent otkaže sesiju 24 sata prije njenog početka, dobiće povrat novca. ' +
            'Za zaštitu vašeg vremena,'
          }
        />
        <Text
          variant={'paragraph1'}
          text={' sesije otkazane u roku od 24 sata prije početka neće biti refundirane, a puni iznos sesije biće prebačen na vaš račun. '}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
      </InlineTextWrapper>

      <Text variant={'heading6'} text={'Postupak zakazivanja nove sesije sa postojećim klijentom'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Klijent ima mogućnost da sam zakazuje sesije s vama na osnovu vaše dostupnosti. To može uraditi tako ' +
            'što će posjetiti vaš profil i rezervisati novi termin. Ako vam je jednostavnije,'
          }
        />
        <Text
          variant={'paragraph1'}
          whiteSpace="pre-wrap"
          text={' sesiju možete zakazati i sami. '}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
        <Text
          variant={'paragraph1'}
          text={'Nakon što se dogovorite sa klijentom o vremenu naredne sesije, pristupite svom profilu i idite na odjeljak'}
        />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Moji klijenti“. '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={'Tu ćete vidjeti listu svih vaših klijenata. Klikom na opciju'} />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Zakaži sesiju“ '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text
          variant={'paragraph1'}
          text={
            ',možete odabrati datum i vrijeme za sljedeću sesiju (obavezno provjerite da li je taj termin dostupan u vašem kalendaru na profilu).'
          }
        />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={RezervacijaiImg} alt="" width={'80%'} height={'auto'} />
      </Flex>

      <Text variant={'heading6'} text={'Kako dijeliti link vašeg profila za direktno zakazivanje sesija?'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Iako će većina klijenata biti upareni s vama putem našeg algoritma zasnovanog na vašim podacima i dostupnosti, ponekad možete htjeti da sami dijelite svoj profil. Bilo da želite omogućiti nekome'
          }
        />
        <Text
          variant={'paragraph1'}
          whiteSpace="pre-wrap"
          text={' da  direktno rezerviše sesiju sa vama'}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
        <Text variant={'paragraph1'} text={', ili ako neko želi da vas preporuči, možete iskoristiti svoj'} />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' jedinstveni link profila.'} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={'Otiđite na svoj profil, nakon toga na'} />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Vidi profil“ '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={', te kopirajte'} />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' url '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={'koji možete dijeliti dalje.'} />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={ProfilImg} alt="" width={'80%'} height={'auto'} />
      </Flex>

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Ovaj link možete objaviti na svojim društvenim mrežama ili ga direktno poslati potencijalnim klijentima. U tom slučaju, klijent se može registrovati'
          }
        />
        <Text
          variant={'paragraph1'}
          whiteSpace="pre-wrap"
          text={' bez prolaska kroz standardni proces uparivanja, '}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
        <Text variant={'paragraph1'} text={'i direktno  rezervisati i platiti sesiju sa vama.'} />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={RegistracijaImg} alt="" width={'80%'} height={'auto'} />
      </Flex>

      <Text variant={'heading6'} text={'Koraci za promjenu opisa na vašem javnom profilu'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Vaš opis profila igra ključnu ulogu u privlačenju potencijalnih klijenata, pa je važno da precizno i detaljno ' +
            'predstavite svoj stil rada, psihoterapijski pristup i metode. Da biste izmijenili opis, otiđite svoj profil i ' +
            'izaberite opciju'
          }
        />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Uredi profil“. '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={'i direktno  rezervisati i platiti sesiju sa vama.'} />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={OpisImg} alt="" width={'80%'} height={'auto'} />
      </Flex>

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={'U tom dijelu možete promijeniti tekst opisa. Kada završite s izmijenama, preporučujemo da posjetite opciju'}
        />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Vidi profil“ '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text
          variant={'paragraph1'}
          text={'kako biste vidjeli kako vaš profil izgleda klijentima i osigurali da je sve prema vašim željama.'}
        />
      </InlineTextWrapper>

      <Text variant={'heading6'} text={'Kako izmijeniti cijenu vaše sesije'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text variant={'paragraph1'} text={'Da biste promijenili cijenu sesije, pristupite opciji'} />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Uredi profil“ '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text
          variant={'paragraph1'}
          text={
            'na vašem profilu i u odgovarajućem ' +
            'polju unesite novu cijenu vaše sesije. Bitno je napomenuti da ova cijena nije ono što klijenti vidjeti kao' +
            'konačnu cijenu. Na vašu osnovnu cijenu dodaje se provizija koju klijenti plaćaju prilikom rezervacije' +
            'sesije. Ova provizija se ne oduzima od vaše zarade. Da biste vidjeli kako se konačna cijena sa provizijom' +
            'prikazuje klijentima, posjetite opciju'
          }
        />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Vidi profil“ '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text variant={'paragraph1'} text={'i pregledajte kako izgleda vaš javni profil sa ažuriranom cijenom.'} />
      </InlineTextWrapper>

      <Text variant={'heading6'} text={'Kako se ne prikazivati novim klijentima, a nastaviti rad sa postojećima?'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Ukoliko želite da nastavite rad sa trenutnim klijentima, ali ne želite da budete dostupni za nove klijente, ' +
            'naša platforma nudi rješenje. Za regulisanje ove opcije, idite na vaš profil, zatim odaberite „Uredi profil“. ' +
            'Tu ćete pronaći opciju koja vam omogućava da se'
          }
        />
        <Text
          variant={'paragraph1'}
          whiteSpace="pre-wrap"
          text={' ne prikazujete novim klijentima '}
          style={{ fontFamily: 'Barlow-Bold' }}
        />
        <Text variant={'paragraph1'} text={'– jednostavno aktivirajte tu opciju.'} margin={'0 0 16px 0'} />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} margin={'0 0 32px 0'}>
        <img src={VidljivostImg} alt="" width={'60%'} height={'auto'} />
      </Flex>

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Kada je aktivirana, nećete biti vidljivi u procesu spajanja sa novim klijentima. Međutim, i dalje možete ' +
            'postavljati dostupnost u vašem kalendaru kako bi vaši postojeći klijenti mogli da vas rezervišu. Kada ' +
            'budete spremni da ponovo prihvatate nove klijente, jednostavno deaktivirajte ovu opciju.'
          }
        />
      </InlineTextWrapper>

      <Text variant={'heading6'} text={'Koraci za zapocinjanje video sesije'} margin={'0 0 16px 0'} />

      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text
          variant={'paragraph1'}
          text={
            'Na početnoj stranici vašeg profila lako možete vidjeti kada je zakazana vaša naredna sesija. Za pregled ' +
            'svih budućih sesija, otiđite na odjeljak'
          }
        />
        <Text variant={'paragraph1'} whiteSpace="pre-wrap" text={' „Moje sesije“. '} style={{ fontFamily: 'Barlow-Bold' }} />
        <Text
          variant={'paragraph1'}
          text={
            'Primjetićete da je dugme za početak video poziva ' +
            'inicijalno neaktivno. Međutim, dugme će postati aktivno 5 minuta prije zakazanog početka sesije. Tada ' +
            'ćete biti u mogućnosti da pokrenete video poziv i započnete sesiju sa klijentom.'
          }
        />
      </InlineTextWrapper>

      <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <Button variant="secondaryDefault" text={'Zatvori'} width={'30%'} onClick={onClose} margin={'32px'} />
      </Flex>
    </Modal>
  );
};
