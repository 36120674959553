import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';

interface SpinnerProps {
  color?: string;
  size?: number;
  inline?: boolean;
  style?: React.CSSProperties;
}

const StyledSpinner = styled.div<{ size: string }>`
  border-radius: 50%;
  border-left: 4px solid transparent;
  cursor: wait;
  z-index: 9000;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin: ${(props) => (props.inline ? '0 auto' : 'unset')};
  position: ${(props) => (!props.inline ? 'absolute' : 'relative')};
  top: ${(props) => (!props.inline ? '50%' : 'unset')};
  left: ${(props) => (!props.inline ? '50%' : 'unset')};
  transform: ${(props) => (!props.inline ? 'translate(-50%, -50%)' : 'unset')};
`;

export const DotsSpinner = (props: SpinnerProps) => {
  const theme = useTheme();
  const { color = theme.colors.accent, size = 80, style = {}, inline = false } = props;

  return (
    <StyledSpinner {...props} color={color} size={size} style={style} inline={inline}>
      <ThreeDots height="100%" width="100%" radius="20" color={'rgb(179, 142, 255)'} ariaLabel="three-dots-loading" visible={true} />
    </StyledSpinner>
  );
};
