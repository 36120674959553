import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { Questionnaire } from 'src/components/shared';
import { Grid, Flex } from 'src/components/common';

import Ilustracija from 'src/assets/images/questionnaire/upitnik-ilustracija.svg';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getQuestionsByRole } from 'src/store/slices/questionsSlice';
import { RolesEnum } from 'src/constants/RolesEnum';
import { maxHorizontalPadding } from 'src/constants/common';

export const QuestionnaireForIndividuals = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const dispatch = useAppDispatch();

  const { loading, questions } = useAppSelector((state: RootState) => state.questions);

  useEffect(() => {
    dispatch(getQuestionsByRole(RolesEnum.CLIENT_INDIVIDUAL));
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Flex flexDirection="column" width={'100%'} flex={'1'}>
      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridTemplateRows={isMobile ? 'auto 1fr' : '1fr'}
        width={'100%'}
        flex={'1'}
        gap={isMobile ? '40px' : '0px'}
        padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}
        justifyContent={'center'}
        backgroundColor={theme.colors.palette.lightPurple}
      >
        <Flex flexDirection={'column'} width={'100%'} height="100%" justifyContent="center">
          <Questionnaire questions={questions} height={'100%'} role={RolesEnum.CLIENT_INDIVIDUAL} />
        </Flex>

        {!isMobile && (
          <Flex
            width={'100%'}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={isMobile ? 'center' : 'flex-end'}
            order={isMobile ? '-1' : '0'}
          >
            <img src={Ilustracija} alt="" width={isMobile ? '100%' : '90%'} height={'auto'} />
          </Flex>
        )}
      </Grid>
    </Flex>
  );
};
