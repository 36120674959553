import React, { useCallback, useContext } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate, generatePath } from 'react-router-dom';
import { RoutesEnum } from 'src/routes';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Modal, Text } from 'src/components/common';
import { User } from 'src/types/User';
import { setShowDeleteProfileAlert } from 'src/store/slices/globalSlice';
import EditProfileForm from 'src/components/shared/edit-profile-modal/form/edit-profile-form/EditProfileForm';
import { useTranslation } from 'react-i18next';

interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  profileData: User;
}

export const EditProfileModal = ({ isOpen, onClose, profileData }: EditProfileModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);
  const { user } = useAppSelector((state: RootState) => state.auth);

  const therapistDetailsClicked = useCallback(() => {
    if (!user.id) return;

    const pathWithUpdatedId = generatePath(RoutesEnum.THERAPIST_DETAILS, { id: user.id.toString() });
    navigate(pathWithUpdatedId);
    onClose();
  }, [navigate, user]);

  const deleteProfile = () => {
    dispatch(setShowDeleteProfileAlert(true));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
        <Flex
          padding={isMobile ? '10px' : '0px'}
          flexDirection="column"
          backgroundColor={theme.colors.background.secondary.BG4}
          borderRadius="16px"
          width="100%"
        >
          <Text variant={'heading5'} text={t('edit_profile_1')} margin="0 auto 48px auto" />

          <EditProfileForm
            closeModal={onClose}
            profileData={profileData}
            openDetails={therapistDetailsClicked}
            deleteProfile={deleteProfile}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
