import React, { useState } from 'react';
import { useAppDispatch } from 'src/store';
import { useTheme } from 'styled-components';
import { Button, Modal, Text, Input } from 'src/components/common';
import { ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { InlineTextWrapper } from 'src/components/shared';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { subscribeWithReferral } from 'src/store/slices/subscriptionsSlice';

export interface ConfirmPaymentPolicyModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const UseReferralModal = ({ isOpen, onClose }: ConfirmPaymentPolicyModalProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState<string>();

  const subscribe = async () => {
    setLoading(true);

    try {
      const response: any = await dispatch(subscribeWithReferral({ referralCode }));
      if (response.payload?.success) {
        ToastNotifications.success(response.payload?.data);
        onClose();
        await dispatch(refreshCurrentUser());
      }
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'cancelSubscripion');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Text variant={'heading5'} text={'Iskoristi referral kod'} margin={'0 0 32px 0'} />
      <ToggleButtonWrapper>
        <InlineTextWrapper margin={'0 0 32px 0'}>
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            whiteSpace={'pre-wrap'}
            text={'Koristeći referal kod koji ste dobili na osnovu preporuke kolege, imate 30 dana besplatnog korištenja '}
          />
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            text={'psihoterapija.app'}
            color={theme.colors.palette.purple}
            style={{ textDecoration: 'underline' }}
            onClick={() => goToUrl(config.PRIVACY_POLICY_URL, '_blank')}
          />
          <Text margin="0 4px 0 0" variant={'paragraph1'} whiteSpace={'pre-wrap'} text={' Radujemo se da postanete dio našeg tima! \n\n'} />
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            whiteSpace={'pre-wrap'}
            text={
              'Nadamo se da ćete po isteku ovog perioda odlučiti nastaviti koristiti pogodnosti platforme - tada će biti potrebno da kupite jedan od ponuđenih modela subskripcije. Za više informacija, budite slobodni obratiti nam se na '
            }
          />
          <Text
            margin="0 4px 0 0"
            variant={'paragraph1'}
            text={'info@psihoterapija.app'}
            color={theme.colors.palette.purple}
            style={{ textDecoration: 'underline' }}
          />
        </InlineTextWrapper>
      </ToggleButtonWrapper>

      <Input placeholder={'Unesi referral kod'} value={referralCode} width={'100%'} onChange={(e) => setReferralCode(e.target.value)} />

      {referralCode && referralCode.length > 0 && (
        <Button variant="primaryDefault" text={'Potvrdi'} width={'100%'} margin={'32px 0 0 0'} isLoading={loading} onClick={subscribe} />
      )}
    </Modal>
  );
};
