import Ana from 'src/assets/images/about-us/ana.png';
import Yamen from 'src/assets/images/about-us/yaman.png';
import Mia from 'src/assets/images/about-us/mia.png';
import { useTranslation } from 'react-i18next';

interface Item {
  icon: string;
  stars: number;
  name: string;
  position: string;
  text: string;
}

export const recommendations = (): Item[] => {
  const { t } = useTranslation();
  return [
    {
      icon: Ana,
      stars: 5,
      name: 'Ana Perić',
      position: t('recommendation_1'),
      text: t('recommendation_2'),
    },
    {
      icon: Yamen,
      stars: 5,
      name: 'Yamen Hrekes',
      position: t('recommendation_3'),
      text: t('recommendation_4'),
    },
    {
      icon: Mia,
      stars: 5,
      name: 'Mia Komšić',
      position: t('recommendation_5'),
      text: t('recommendation_6'),
    },
  ];
};
