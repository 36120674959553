import React from 'react';
import { useTheme } from 'styled-components';
import { Button, Flex, Modal, Text } from 'src/components/common';
import { User } from 'src/types/User';
import { ToastNotifications } from 'src/utils/toast-notifications';
import FullLogo from 'src/assets/images/logo.png';
import { useTranslation } from 'react-i18next';

export interface RecommendTherapistModalProps {
  therapist?: User;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const RecommendTherapistModal = ({ therapist, onClose }: RecommendTherapistModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function copyLink() {
    navigator.clipboard.writeText(`https://www.psihoterapija.app/details/${therapist.id}`);
    ToastNotifications.success(t('msg_link_copied'), 'recommendTherapist');
    onClose();
  }

  return (
    <Modal isOpen={therapist != null} onClose={onClose} size="medium" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex flexDirection="column" width="100%" justifyContent="center" alignItems="center">
        <img src={FullLogo} alt="psihoterapija.app" width={'80px'} height={'auto'} style={{ marginBottom: 16 }} />
      </Flex>
      <Text variant={'heading5'} text={t('recommend_therapist_1')} margin={'0 0 32px 0'} />
      <Text variant={'paragraph2'} text={t('recommend_therapist_2')} margin={'0 0 32px 0'} />
      <Button variant="primaryDefault" text={t('btn_copy_link')} width={'100%'} onClick={copyLink} />
    </Modal>
  );
};
