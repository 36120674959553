import React, { useMemo, useRef, useState } from 'react';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { useTheme } from 'styled-components';
import { Flex, Text, Card, Button } from 'src/components/common';
import { getAppointDate } from 'src/utils/date-parser';
import { MonriPaymentInputs } from 'src/pages/dashboard/MonriPaymentInputs';
import { ConfirmPaymentPolicyModal } from './therapist/modals/ConfirmPaymentPolicyModal';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import { parseError } from 'src/utils/error-parser';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import { Appointment } from 'src/types/Appointment';
import { useTranslation } from 'react-i18next';

interface UnpaidSessionsProps {
  cancelSession: (session: Appointment) => void;
}

export const UnpaidSessions = ({ cancelSession }: UnpaidSessionsProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useAppSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(false);
  const { isOpen, close, open } = useDisclosure(false);
  const { session } = useAppSelector((state: RootState) => state.session);
  const formRef = useRef<HTMLFormElement>();

  const unpaidSessions = useMemo(() => {
    return user?.appointments?.filter((session) => !session.paid)?.sort((a, b) => a.date - b.date) ?? [];
  }, [user]);

  if (unpaidSessions.length === 0) return null;

  // eslint-disable-next-line
  function startPayment(voucher: string) {
    if (voucher && voucher.length > 0) {
      payWithVoucher(voucher);
    } else {
      payWithMonri();
    }
  }

  async function payWithVoucher(voucher: string) {
    setLoading(true);

    try {
      const response = await appointmentsAPI.payAppointmentWithVoucher({
        appointmentId: session.id,
        voucher: voucher,
      });
      if (response.success) {
        ToastNotifications.success(t('msg_session_paid'), 'createAppointment');
        await dispatch(refreshCurrentUser());
        close();
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'createAppointment');
    } finally {
      setLoading(false);
    }
  }

  function payWithMonri() {
    setTimeout(() => {
      formRef.current && formRef.current.submit();
      close();
    }, 200);
  }

  return (
    <Flex flexDirection={'column'} width={'100%'} gap={'16px'} overflow={'scroll'}>
      <Text variant={'heading6'} text={t('sessions_9')} />

      <Card width={'100%'} borderRadius={'20px'}>
        <Flex flexDirection={'column'} width={'100%'} gap={'16px'}>
          {unpaidSessions?.map((appointment, index) => (
            <Flex key={index} flexDirection={'column'} width={'100%'} gap={'16px'}>
              {appointment.therapist.id != user.id ? (
                <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'15px'}>
                  <Text
                    variant={'paragraph1'}
                    text={`${t('sessions_4')} ${appointment.therapist.username}, ${getAppointDate(appointment.date)}`}
                  />
                  <Flex flexDirection="row" gap="10px">
                    <Button
                      type="button"
                      variant={'primaryDefault'}
                      text={t('btn_pay_for_session')}
                      onClick={() => {
                        dispatch(setSelectedSession(appointment));
                        open();
                      }}
                    />
                    <Button
                      type="button"
                      variant={'secondaryRedDefault'}
                      text={t('btn_cancel')}
                      onClick={() => cancelSession(appointment)}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Text
                  variant={'paragraph1'}
                  text={`${t('sessions_4')} ${appointment.client.username}, ${getAppointDate(appointment.date)}`}
                />
              )}

              <Flex width={'100%'} height={'0px'} border={`0.05px solid ${theme.colors.border.darker}`} />
            </Flex>
          ))}
        </Flex>
      </Card>

      <form ref={formRef} action={process.env.REACT_APP_MONRI_URL} id="monri-integration-form-prepare" method="POST">
        <MonriPaymentInputs user={user} onPaymentSuccessful={() => {}} />
      </form>

      <ConfirmPaymentPolicyModal isOpen={isOpen} loading={loading} onClose={close} onConfirm={startPayment} />
    </Flex>
  );
};
