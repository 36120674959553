import styled, { css } from 'styled-components';
import { Variant } from 'src/types/Checkbox';

export const getCheckboxSize = (variant: Variant) => {
  if (variant === 'secondary') {
    return css`
      width: 16px;
      height: 16px;
    `;
  }

  return css`
    width: 20px;
    height: 20px;
  `;
};

export const getCheckMarkStyles = (variant: Variant) => {
  if (variant === 'secondary') {
    return css`
      width: 5px;
      height: 8px;
      top: 2px;
      left: 4px;
    `;
  }

  return css`
    width: 6px;
    height: 10px;
    top: 3px;
    left: 6px;
  `;
};

export const getIndeterminatekMarkStyles = (variant: Variant) => {
  if (variant === 'secondary') {
    return css`
      width: 7px;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
  }

  return css`
    width: 9px;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
};

export const CheckboxWrapper = styled.div<{
  checkboxSize: any;
  checkmarkStyles: any;
  indeterminateMarkStyles: any;
}>`
  display: flex;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    margin: 0 !important;
    ${(props) => props.checkboxSize};
  }

  span {
    cursor: pointer;
    ${(props) => props.checkboxSize};
    display: flex;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: ${(props) => props.theme.colors.border.lighter};
    background-color: ${(props) => props.theme.colors.palette.white};

    &:hover {
      border-color: ${(props) => props.theme.colors.palette.purple};
    }

    &:active {
      border-color: ${(props) => props.theme.colors.palette.purple};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.palette.lightPurple};
    }
  }

  [type='checkbox']:checked + span {
    border-color: ${(props) => props.theme.colors.palette.purple};
    background-color: ${(props) => props.theme.colors.palette.purple};

    &:before {
      content: '';
      border-style: solid;
      border-color: ${(props) => props.theme.colors.text.primary};
      border-width: 0 2px 2px 0;
      position: absolute;
      transform: rotate(45deg);
      ${(props) => props.checkmarkStyles};
    }
  }

  [type='checkbox']:indeterminate + span {
    border-color: ${(props) => props.theme.colors.palette.purple};
    background-color: ${(props) => props.theme.colors.palette.purple};

    &:before {
      content: '';
      border-style: solid;
      border-color: ${(props) => props.theme.colors.text.primary};
      border-width: 2px 0 0 0;
      position: absolute;
      ${(props) => props.indeterminateMarkStyles};
    }
  }

  [type='checkbox']:disabled + span {
    cursor: not-allowed;
    border-color: ${(props) => props.theme.colors.border.lighter} !important;
    background-color: ${(props) => props.theme.colors.border.lighter} !important;

    &:before {
      border-color: ${(props) => props.theme.colors.text.disabled};
    }
  }
`;
