import { DefaultTheme } from 'styled-components';

const typography = {
  fontFamily: `"Barlow-Regular", sans-serif`,
  variants: {
    paragraph1: { fontSize: '16px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    paragraph2: { fontSize: '14px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    paragraph3: { fontSize: '12px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    paragraph4: { fontSize: '10px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    heading1: { fontSize: '40px', fontWeight: 600, fontFamily: 'Barlow-SemiBold' },
    heading2: { fontSize: '36px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    heading3: { fontSize: '32px', fontWeight: 600, fontFamily: 'Barlow-SemiBold' },
    heading4: { fontSize: '28px', fontWeight: 400, fontFamily: 'Barlow-Regular' },
    heading5: { fontSize: '24px', fontWeight: 600, fontFamily: 'Barlow-SemiBold' },
    heading6: { fontSize: '20px', fontWeight: 600, fontFamily: 'Barlow-SemiBold' },
    heading7: { fontSize: '18px', fontWeight: 600, fontFamily: 'Barlow-SemiBold' },
    subheading: { fontSize: '20px', fontWeight: 700, fontFamily: 'Barlow-Bold' },
    displaySmall: { fontSize: '48px', fontWeight: 800, fontFamily: 'Barlow-ExtraBold' },
    displayLarge: { fontSize: '64px', fontWeight: 800, fontFamily: 'Barlow-ExtraBold' },
  },
};

const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
};

export const theme: DefaultTheme = {
  colors: {
    palette: {
      white: '#FFFFFF',
      black: '#000000',
      purple: '#B38EFF',
      lightPurple: '#E6E6FA',
      darkPurple: '#7e3fff',
      green: 'rgba(75,192,192,1)',
      lightGreen: 'rgba(75,192,192,0.2)',
      darkGreen: '#304E16',
      red: '#F65C5C',
      lightRed: '#FFEBED',
      blue: '#0000ED',
      lightBlue: '#E6EFFA',
      info: '#5aacd0',
    },
    background: {
      main: '#FFFFFF',
      secondary: {
        BG1: '#F6F6F6',
        BG2: '#F2F2F2',
        BG3: '#EFEFEF',
        BG4: '#ededed',
      },
    },
    border: {
      lighter: '#EBEBEB',
      darker: '#E0E0E0',
    },
    overlay: {
      1: '#F6F6F6',
      2: '#F2F2F2',
      3: '#EFEFEF',
      4: '#E8E8E8',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#222222',
      tertiary: '#AAAAAA',
      disabled: '#bfbdbd',
    },
  },
  typography,
  breakpoints,
};
