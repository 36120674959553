import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const SearchIcon = (props: UIIconProps) => {
  const { size = 16, color = 'currentColor', style = {} } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill={color}
        d='M22.1 20.7l-4.5-4.5c1.2-1.5 1.9-3.3 1.9-5.4 0-4.8-3.9-8.6-8.6-8.6-4.8 0-8.6 3.9-8.6 8.6 0 4.8 3.9 8.6 8.6 8.6 2 0 3.9-.7 5.4-1.9l4.5 4.5 1.3-1.3zM4.3 10.9c0-3.7 3-6.6 6.6-6.6 3.6 0 6.6 3 6.6 6.6 0 3.6-3 6.6-6.6 6.6-3.6 0-6.6-2.9-6.6-6.6z'
      />
    </svg>
  );
};
