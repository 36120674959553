import React, { Suspense, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

import { useIsFontLoaded } from 'src/hooks/useIsFontLoaded';
import { useScrollToTop } from 'src/hooks/useScrollToTop';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { SocketContext, socket } from 'src/context/SocketContext';
import { store } from 'src/store';
import { theme } from 'src/theme';
import App from 'src/App';

import * as serviceWorker from 'src/serviceWorker';
import './styles/styles.css';

import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_MEASURMENT_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWrapper = () => {
  useScrollToTop();

  const isMobile = useBreakpoint(down('lg'));
  const { loaded } = useIsFontLoaded();

  useLayoutEffect(() => {
    const root = document.getElementById('root');

    if (loaded && root) {
      root.classList.remove('wf-loading');
    }
  }, [loaded]);

  return (
    <Suspense fallback={false}>
      <IsMobileContext.Provider value={{ isMobile }}>
        <SocketContext.Provider value={socket}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </Provider>
        </SocketContext.Provider>
      </IsMobileContext.Provider>
    </Suspense>
  );
};

root.render(<AppWrapper />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers here: https://bit.ly/CRA-PWA
serviceWorker.unregister();
