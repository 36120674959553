import styled from 'styled-components';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

export const STabs = styled(Tabs)`
  width: 100%;
`;

export const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin: 0;
  border: none;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

STabList.tabsRole = 'TabList';

export const STab = styled(Tab)`
  margin-right: 24px;
  border: none;
  padding: 0 0 16px 0;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  transition: all 0.5s ease-in;

  &.is-selected {
    color: ${(props) => props.theme.colors.palette.purple};
    border-bottom: 2px solid ${(props) => props.theme.colors.palette.purple};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

STab.tabsRole = 'Tab';

export const STabPanel = styled(TabPanel)`
  display: none;
  border: none;
  margin-top: 20px;

  &.is-selected {
    display: flex;
    width: 100%;
    border: none;
  }
`;

STabPanel.tabsRole = 'TabPanel';
