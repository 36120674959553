import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { DotsSpinner } from 'src/components/shared';
import { GlobalStyles } from 'src/styles/globalStyles';
import { getAppRoutes, isPublicRoute, isRouteAllowed, RoutesEnum } from 'src/routes';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { getCurrentUser, logout } from 'src/store/slices/authSlice';
import { setSelectedPlan } from './store/slices/subscriptionsSlice';
import {
  setClientDashboardActiveTab,
  setTherapistDashboardActiveTab,
  setShowEditProfileModal,
  setClientDashboardNavItems,
  setTherapistDashboardNavItems,
  setClientDashboardBurgerMenuItems,
  setTherapistDashboardBurgerMenuItems,
  setShowRecommendFriendModal,
  setShowTutorialModal,
} from './store/slices/globalSlice';
import { ClientDashboardTabsEnum } from 'src/constants/ClientDashboardTabsEnum';
import { TherapistDashboardTabsEnum } from 'src/constants/TherapistDashboardTabsEnum';
import {
  faCalendar,
  faEdit,
  faHome,
  faMessage,
  faMoneyCheckDollar,
  faPhone,
  faUserPlus,
  faUsers,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import { Flex, Text } from './components/common';
import ProfilePhotoPlaceholder from './assets/images/profile-avatar.png';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { RolesEnum } from './constants/RolesEnum';
import { Analytics } from '@vercel/analytics/react';
import { useTranslation } from 'react-i18next';

const App = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { isAuthenticated, loading, user } = useAppSelector((state: RootState) => state.auth);
  const [imgHeight, setImgHeight] = useState<any>('auto');
  const imgRef = useRef<HTMLImageElement>();

  const onImgRefChange = useCallback(
    (node) => {
      if (node !== null && node.width) {
        setImgHeight(node.width);
      }
    },
    [imgRef, isMobile]
  );

  const checkForDashboardRoute = () => {
    if (window.location.href !== RoutesEnum.DASHBOARD) {
      navigate(RoutesEnum.DASHBOARD);
    }
  };

  const CLIENT_DASHBOARD_NAV_ITEMS = [
    {
      title: t('navigation_1'),
      icon: faHome,
      tab: ClientDashboardTabsEnum.HOME,
      action: () => {
        checkForDashboardRoute();
        dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.HOME));
      },
    },
    {
      title: t('navigation_2'),
      icon: faCalendar,
      tab: ClientDashboardTabsEnum.MY_SESSIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.MY_SESSIONS));
      },
    },
    {
      title: t('navigation_3'),
      icon: faUsers,
      tab: ClientDashboardTabsEnum.MY_THERAPISTS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.MY_THERAPISTS));
      },
    },
    {
      title: t('navigation_4'),
      icon: faMessage,
      tab: ClientDashboardTabsEnum.MY_MESSAGES,
      action: () => {
        checkForDashboardRoute();
        dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.MY_MESSAGES));
      },
    },
    {
      title: t('navigation_5'),
      icon: faEdit,
      action: () => dispatch(setShowEditProfileModal(true)),
    },
  ];

  const CLIENT_DASHBOARD_BURGER_MENU_ITEMS = [
    {
      id: 0,
      customContent: (
        <Flex flexDirection={'column'} width={'100%'} alignItems={'center'}>
          <img
            ref={onImgRefChange}
            src={user?.photo ?? ProfilePhotoPlaceholder}
            alt="Slika"
            width={'40%'}
            height={imgHeight ?? 'auto'}
            style={{ objectFit: 'cover', borderRadius: '50%', marginBottom: 16 }}
          />
          <Text variant={'heading7'} text={user?.username} />
          <Text variant={'paragraph2'} text={user?.email} />
        </Flex>
      ),
    },
    ...CLIENT_DASHBOARD_NAV_ITEMS,
  ];

  const THERAPITS_DASHBOARD_NAV_ITEMS = [
    {
      id: 1,
      title: t('navigation_1'),
      icon: faCalendar,
      tab: TherapistDashboardTabsEnum.HOME,
      action: () => {
        checkForDashboardRoute();
        dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.HOME));
      },
    },
    {
      id: 5,
      title: t('navigation_6'),
      icon: faBook,
      tab: TherapistDashboardTabsEnum.TUTORIAL,
      action: () => dispatch(setShowTutorialModal(true)),
    },
    {
      id: 2,
      title: t('navigation_4'),
      icon: faMessage,
      tab: TherapistDashboardTabsEnum.MY_MESSAGES,
      action: () => {
        checkForDashboardRoute();
        dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.MY_MESSAGES));
      },
    },
    {
      id: 3,
      title: t('navigation_2'),
      icon: faUsers,
      tab: TherapistDashboardTabsEnum.MY_SESSIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.MY_SESSIONS));
      },
    },
    {
      id: 4,
      title: t('navigation_7'),
      icon: faUsers,
      tab: TherapistDashboardTabsEnum.MY_CLIENTS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.MY_CLIENTS));
      },
    },
    {
      id: 5,
      title: t('navigation_8'),
      icon: faMoneyCheckDollar,
      tab: TherapistDashboardTabsEnum.SUBSCRIPTIONS,
      action: () => {
        checkForDashboardRoute();
        dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.SUBSCRIPTIONS));
      },
    },
    {
      id: 6,
      title: t('navigation_9'),
      icon: faUserPlus,
      action: () => dispatch(setShowRecommendFriendModal(true)),
    },
    {
      id: 7,
      title: t('navigation_16'),
      icon: faEdit,
      action: () => dispatch(setShowEditProfileModal(true)),
    },
    {
      id: 8,
      title: t('navigation_10'),
      icon: faPhone,
      action: () => navigate(RoutesEnum.CONTACT),
    },
  ];

  const THERAPIST_DASHBOARD_BURGER_MENU_ITEMS = [
    {
      id: 0,
      customContent: (
        <Flex flexDirection={'column'} width={'100%'} alignItems="center">
          <img
            ref={onImgRefChange}
            src={user?.photo ?? ProfilePhotoPlaceholder}
            alt="Slika"
            width={'40%'}
            height={imgHeight ?? 'auto'}
            style={{ objectFit: 'cover', borderRadius: '50%', marginBottom: 16 }}
          />
          <Text variant={'heading7'} text={user?.username} />
          <Text variant={'paragraph2'} text={user?.email} />
        </Flex>
      ),
    },
    ...THERAPITS_DASHBOARD_NAV_ITEMS,
  ];

  useEffect(() => {
    if (!isAuthenticated && !isPublicRoute(pathname)) {
      return navigate(RoutesEnum.HOME);
    }

    if (isAuthenticated) {
      if (!user) {
        dispatch(getCurrentUser());
      }

      if (user) {
        if (!isRouteAllowed(pathname, user?.role?.role, t)) {
          navigate(RoutesEnum.FORBIDDEN);
        }

        if (user?.role?.role === RolesEnum.THERAPIST) {
          dispatch(setTherapistDashboardBurgerMenuItems(THERAPIST_DASHBOARD_BURGER_MENU_ITEMS));
          dispatch(setTherapistDashboardNavItems(THERAPITS_DASHBOARD_NAV_ITEMS));
        } else {
          dispatch(setClientDashboardBurgerMenuItems(CLIENT_DASHBOARD_BURGER_MENU_ITEMS));
          dispatch(setClientDashboardNavItems(CLIENT_DASHBOARD_NAV_ITEMS));
        }
      }
    }
  }, [isAuthenticated, location, user, pathname, isRouteAllowed]);

  useEffect(() => {
    window.addEventListener(
      'storage',
      async (event) => {
        if ((event.key === 'token' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          await dispatch(logout());
        }

        if ((event.key === 'selectedPlan' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          dispatch(setSelectedPlan(null));
        }

        if ((event.key === 'clientDashboardActiveTab' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.HOME));
        }

        if ((event.key === 'therapistDashboardActiveTab' && event.newValue === null) || (event.key === null && event.newValue === null)) {
          dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.HOME));
        }
      },
      false
    );
    // eslint-disable-next-line
  }, [window]);

  useEffect(() => {
    if (location.pathname !== RoutesEnum.DASHBOARD) {
      dispatch(setTherapistDashboardActiveTab(TherapistDashboardTabsEnum.HOME));
      dispatch(setClientDashboardActiveTab(ClientDashboardTabsEnum.HOME));
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Analytics />
      <GlobalStyles />
      <Routes>
        {getAppRoutes(t).map(({ path, Layout, Component }, index) => (
          <Route
            key={index}
            path={path}
            element={<Layout {...props}>{loading ? <DotsSpinner inline style={{ margin: 'auto' }} /> : <Component />}</Layout>}
          />
        ))}
      </Routes>
    </>
  );
};

export default App;
