import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import Card1 from 'src/assets/images/clients-about-us/clients-about-us-1.png';
import Card2 from 'src/assets/images/clients-about-us/clients-about-us-2.png';
import Card3 from 'src/assets/images/clients-about-us/clients-about-us-3.png';
import Card4 from 'src/assets/images/clients-about-us/clients-about-us-4.png';

import { Flex, Text } from 'src/components/common';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/components/icons/ui-icons';
import { theme } from 'src/theme';
import { useTranslation } from 'react-i18next';

interface CardItem {
  icon: any
}

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-left: 200px;
  padding-right: 200px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .slick-slider {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 64px;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 20px;
    }

    .slick-dots {
      margin: 32px 0 0 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: unset !important;
      left: 50%;
      transform: translateX(-50%);

      li {
        position: relative;
        display: flex;
        width: auto;
        height: auto;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
          width: 6.6px;
          height: 6.6px;
          background: rgba(0, 0, 0, 0.33);
          padding: unset !important;
          border-radius: 10px;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            width: 13px !important;
            height: 13px !important;
            background: ${theme.colors.palette.darkPurple} !important;
          }
        }
      }
    }

    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
      cursor: pointer;

      * {
        cursor: pointer;
      }
    }

    .slick-list {
      display: flex;
    }

    .slick-track {
      display: flex;
      gap: 0px;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        gap: unset;
      }
    }

    .slick-slide {
      display: flex;
      align-items: stretch;
      justify-content: center;

      > div:first-of-type {
        width: 100% !important;
      }
    }
  }
`;

const PrevArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;

    * {
      display: none;
    }
  }
`;

const NextArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;

    * {
      display: none;
    }
  }
`;

function NextArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <NextArrowWrapper onClick={onClick} style={style}>
      <ChevronRightIcon className={className} color={theme.colors.palette.darkPurple} />
    </NextArrowWrapper>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <PrevArrowWrapper onClick={onClick} style={style}>
      <ChevronLeftIcon className={className} color={theme.colors.palette.darkPurple} />
    </PrevArrowWrapper>
  );
}

const sliderSettings = {
  dots: true,
  draggable: false,
  infinite: true,
  swipeToSlide: true,
  lazyLoad: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const HomeClientsAboutUs = () => {
  const { t } = useTranslation();

  const cards: CardItem[] = [
    { icon: Card1 },
    { icon: Card2 },
    { icon: Card3 },
    { icon: Card4 },
  ];

  const renderCards = () => {
    return cards.map((item, index) => (
      <Flex key={index} flexDirection={'column'} width={'100%'} height={'100%'}>
        <img src={item.icon} alt="icon" width={'100%'} height={'auto'}  />
      </Flex>
    ));
  };

  return (
    <SliderWrapper>
      <Slider {...sliderSettings}>{renderCards()}</Slider>
    </SliderWrapper>
  );
};
