import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Text, Grid, Button } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';
import { config } from 'src/config/config';
import { maxHorizontalPadding } from 'src/constants/common';
import { NavLink } from 'react-router-dom';

import Ilustracija1 from 'src/assets/images/for-therapists/workshop-ilustracija.png';
import Ilustracija2 from 'src/assets/images/for-therapists/company-ilustracija.png';
import { RoutesEnum } from 'src/routes';
import { useTranslation } from 'react-i18next';

export const ForCompanies = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems={'flex-start'}
      justifyContent={'center'}
      width={'100%'}
      position={'relative'}
      padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}
      gap="32px"
    >
      <Grid gridTemplateColumns={isMobile ? '1fr' : '1.5fr 1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'40px'}>
        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading2'} text={t('for_companies_1')} width={'80%'} />
          <Text variant={'heading2'} width={'90%'} text={t('for_companies_2')} style={{ fontFamily: 'Barlow-Thin' }} />
          <NavLink to={RoutesEnum.CONTACT}>
            <Button text={t('for_companies_3')} width={isMobile ? '100%' : 'max-content'} margin={'32px 0'} />
          </NavLink>
          <Text variant={'paragraph1'} width={'90%'} text={t('for_companies_4')} />
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} alignItems={isMobile ? 'center' : 'flex-end'} flexDirection={'column'}>
          <img src={Ilustracija1} alt="landing" width={'90%'} height={'auto'} />
        </Flex>
      </Grid>

      <Text variant={'paragraph1'} text={t('for_companies_5')} margin={'0 0 24px 0'} style={{ fontSize: 24 }} />

      <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'40px'}>
        <Flex width={'100%'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
          <img src={Ilustracija2} alt="landing-glavna-ilustracija" width={'100%'} height={'auto'} />
        </Flex>

        <Flex flexDirection="column" gap={isMobile ? '24px' : '32px'} alignItems="flex-start" justifyContent="center">
          <Grid gridTemplateColumns={'1fr 8fr'} gridTemplateRows={'1fr'} width={'100%'} justifyContent="center" alignItems="center">
            <Text variant={'heading1'} text={'1'} color={theme.colors.palette.purple} style={{ fontSize: 80 }} />
            <InlineTextWrapper>
              <Text variant={'paragraph1'} text={t('for_companies_6')} margin="0 4px 0 0" />
              <Text variant={'paragraph1'} text={` ${t('for_companies_7')} `} margin="0 4px 0 0" style={{ fontFamily: 'Barlow-Bold' }} />
              <Text variant={'paragraph1'} text={t('for_companies_8')} />
            </InlineTextWrapper>
          </Grid>

          <Grid gridTemplateColumns={'1fr 8fr'} gridTemplateRows={'1fr'} width={'100%'} justifyContent="center" alignItems="center">
            <Text variant={'heading1'} text={'2'} color={theme.colors.palette.purple} style={{ fontSize: 80 }} />
            <InlineTextWrapper>
              <Text variant={'paragraph1'} text={t('for_companies_9')} margin="0 4px 0 0" />
              <Text variant={'paragraph1'} text={` ${t('for_companies_10')} `} margin="0 4px 0 0" style={{ fontFamily: 'Barlow-Bold' }} />
              <Text variant={'paragraph1'} text={t('for_companies_11')} />
            </InlineTextWrapper>
          </Grid>

          <Grid gridTemplateColumns={'1fr 8fr'} gridTemplateRows={'1fr'} width={'100%'} justifyContent="center" alignItems="center">
            <Text variant={'heading1'} text={'3'} color={theme.colors.palette.purple} style={{ fontSize: 80 }} />
            <InlineTextWrapper>
              <Text variant={'paragraph1'} text={t('for_companies_12')} margin="0 4px 0 0" style={{ fontFamily: 'Barlow-Bold' }} />
              <Text variant={'paragraph1'} text={` ${t('for_companies_13')} `} margin="0 4px 0 0" />
              <Text variant={'paragraph1'} text={`${t('for_companies_14')} `} margin="0 4px 0 0" style={{ fontFamily: 'Barlow-Bold' }} />
              <Text variant={'paragraph1'} text={t('for_companies_15')} />
            </InlineTextWrapper>
          </Grid>
        </Flex>
      </Grid>

      <Text variant={'paragraph1'} text={t('for_companies_16')} margin={'0 0 24px 0'} />

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={isMobile ? '32px' : '100px'}
        margin={'0 0 32px 0'}
      >
        <Flex flexDirection="column" alignItems="center" gap="16px">
          <Text
            variant={'paragraph1'}
            text={t('for_companies_17')}
            style={{ fontFamily: 'Barlow-Bold' }}
            color={theme.colors.palette.purple}
          />
          <Text variant={'paragraph1'} textAlign="center" text={t('for_companies_18')} />
        </Flex>
        <Flex flexDirection="column" alignItems="center" gap="16px">
          <Text
            variant={'paragraph1'}
            text={t('for_companies_19')}
            style={{ fontFamily: 'Barlow-Bold' }}
            color={theme.colors.palette.purple}
          />
          <Text textAlign="center" variant={'paragraph1'} text={t('for_companies_20')} />
        </Flex>
        <Flex flexDirection="column" alignItems="center" gap="16px">
          <Text
            variant={'paragraph1'}
            text={t('for_companies_21')}
            style={{ fontFamily: 'Barlow-Bold' }}
            color={theme.colors.palette.purple}
          />
          <Text variant={'paragraph1'} textAlign="center" text={t('for_companies_22')} />
        </Flex>
      </Grid>

      <Flex flexDirection="column" justifyContent="center" width="100%" alignItems="center" margin={'24px 0 0 0'}>
        <Text
          variant={'paragraph1'}
          text={t('for_companies_23')}
          margin={'0 0 24px 0'}
          style={{ fontFamily: 'Barlow-Bold' }}
          textAlign={'justify'}
        />
        <InlineTextWrapper margin={'0 0 40px 0'} textAlign={'center'}>
          <Text variant={'paragraph1'} text={`${t('for_companies_24')} `} margin="0 4px 0 0" />
          <a href={`mailto:${config.CONTACT_MAIL}`}>
            <Text variant={'paragraph1'} text={config.CONTACT_MAIL} margin="0 4px 0 0" style={{ fontFamily: 'Barlow-Bold' }} />
          </a>
          <Text variant={'paragraph1'} text={t('for_companies_25')} />
        </InlineTextWrapper>
      </Flex>
    </Flex>
  );
};
