import React, { useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { HomeCarousel } from './HomeCarousel';
import { GetMatchedModal } from './modals/GetMatchedModal';
import { AboutPrivacyModal } from './modals/AboutPrivacyModal';
import { Accordion, Button, Card, Flex, Grid, Text } from 'src/components/common';
import { Facebook, Instagram, Linkedin } from 'src/components/icons/social-icons';
import LandingSekcija1Ilustracija from 'src/assets/images/landing/landing-sekcija1-ilustracija.svg';
import LandingCurvedLineBg from 'src/assets/images/landing/landing-curved-line-bg.png';
import { RootState, useAppSelector } from 'src/store';
import { whyWeStarted, howDoWeSelectTherapists } from 'src/assets/data/home_page';
import { faqClients } from 'src/assets/data/faq';
import { maxHorizontalPadding } from 'src/constants/common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeClientsAboutUs } from './HomeClientsAboutUs';

export const Home = () => {
  const theme = useTheme();

  const { isOpen: isGetMatchedModalOpen, close: closeGetMatchedModal, open: openGetMatchedModal } = useDisclosure(false);
  const { isOpen: isAboutPrivacyModalOpen, close: closeAboutPrivacyModal, open: openAboutPrivacyModal } = useDisclosure(false);
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  const { isAuthenticated, user } = useAppSelector((state: RootState) => state.auth);

  const hideJoinUsButton = useMemo(() => {
    return isAuthenticated && user;
  }, [isAuthenticated, user]);

  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
      <GetMatchedModal isOpen={isGetMatchedModalOpen} onClose={closeGetMatchedModal} />
      <AboutPrivacyModal isOpen={isAboutPrivacyModalOpen} onClose={closeAboutPrivacyModal} />

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'40px'}
        backgroundColor={theme.colors.palette.lightPurple}
        padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}
      >
        <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'}>
          <Text variant={'heading4'} text={t('home_1')} margin={'0 0 40px 0'} />

          <Text variant={'paragraph1'} text={t('home_2')} margin={'0 0 40px 0'} />

          <Flex flexDirection="row" gap="10px">
            {!hideJoinUsButton && (
              <Button
                onClick={openGetMatchedModal}
                text={t('btn_find_therapist')}
                width={isMobile ? '100%' : 'max-content'}
                margin={'0 0 20px 0'}
              />
            )}
            <Button
              onClick={openAboutPrivacyModal}
              text={t('btn_privacy')}
              width={isMobile ? '100%' : 'max-content'}
              margin={'0 0 20px 0'}
              variant="accentDefault"
            />
          </Flex>

          <Flex justifyContent={'flex-start'} width={isMobile ? '100%' : '50%'}>
            <Link to={'https://www.facebook.com/profile.php?id=61553944146513'}>
              <Facebook size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
            <Link to={'https://www.instagram.com/psihoterapija.app'}>
              <Instagram size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
            <Link to={'https://www.linkedin.com/company/psihoterapija-app/'}>
              <Linkedin size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
          </Flex>
        </Flex>

        <Flex width={'100%'} justifyContent={'center'} alignItems={isMobile ? 'center' : 'flex-end'} flexDirection={'column'}>
          <img src={LandingSekcija1Ilustracija} alt="" width={'95%'} height={'auto'} />
        </Flex>
      </Grid>

      <Text
        variant={'heading4'}
        text={t('home_21')}
        margin={'68px 0 0 0'}
        padding={isMobile ? '0 20px' : '0 100px'}
        textAlign={isMobile ? 'center' : 'left'}
      />

      <HomeClientsAboutUs />

      <Flex
        width={'100%'}
        flexDirection={'column'}
        justifyContent={'center'}
        gap={'40px'}
        padding={isMobile ? '20px 10px' : `48px ${maxHorizontalPadding}`}
      >
        <Card width={'100%'} alignItems={'center'} borderRadius={'20px'} margin={'0 0 32px 0'}>
          <Text variant={'heading4'} text={t('home_3')} width={'100%'} textAlign={'center'} margin={'0 0 40px 0'} />

          <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'40px'}>
            {howDoWeSelectTherapists().map((item, index) => (
              <Flex key={index} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                <img src={item.icon} alt="ikona" width={'50%'} height={'auto'} />

                <Text variant={'paragraph1'} text={item.text} margin={'40px 0 0 0'} width={'100%'} textAlign={'center'} />
              </Flex>
            ))}
          </Grid>
        </Card>
      </Flex>

      <Text
        variant={'heading4'}
        text={t('home_4')}
        margin={'20px 0'}
        padding={isMobile ? '0 20px' : '0 100px'}
        textAlign={isMobile ? 'center' : 'left'}
      />

      <HomeCarousel />

      <Text
        variant={'heading4'}
        text={t('home_5')}
        margin={'20px 0'}
        padding={isMobile ? '0 20px' : '0 100px'}
        textAlign={isMobile ? 'center' : 'left'}
      />

      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr 1fr 1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'75%'}
        padding={isMobile ? '20px 10px' : '20px 0'}
      >
        {whyWeStarted().map((item, index) => (
          <Flex
            key={index}
            flexDirection={item.reverse && !isMobile ? 'column-reverse' : 'column'}
            width={'100%'}
            alignItems={'center'}
            gap={'20px'}
            height={'max-content'}
            margin={index !== 0 && index !== whyWeStarted().length - 1 && !isMobile ? '80px 0 0 0' : '0'}
          >
            <img src={item.icon} alt="icon" width={'130px'} height={'auto'} />
            <Text variant={'paragraph1'} text={item.text} width={'75%'} textAlign={'center'} />
          </Flex>
        ))}
      </Grid>

      <img src={LandingCurvedLineBg} alt="curved-line" style={{ width: '100%', zIndex: -1, marginTop: '-25%' }} />

      <Flex
        width={'100%'}
        backgroundColor={theme.colors.palette.lightPurple}
        padding={isMobile ? '20px' : `48px ${maxHorizontalPadding}`}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Text variant={'heading4'} text={t('home_6')} margin={'0 0 40px 0'} width={'100%'} textAlign={'center'} />

        <Accordion panels={faqClients()} columns={2} />
      </Flex>
    </Flex>
  );
};
