import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';

import { SocketContext } from 'src/context/SocketContext';
import { Flex, Modal, Text, Input, Button } from 'src/components/common';
import { RootState, useAppSelector } from 'src/store';
import { userAPI } from 'src/api/user/ApiRequests';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { Message } from 'src/types/Message';
import { useTranslation } from 'react-i18next';

interface MessagesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MessagesModal = ({ isOpen, onClose }: MessagesModalProps) => {
  const theme = useTheme();
  const socket = useContext(SocketContext);

  const { t } = useTranslation();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { selectedInbox } = useAppSelector((state: RootState) => state.user);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const lastMessageRef = useRef(null);

  const loadMessages = async () => {
    try {
      const response = await userAPI.getMessages(user.id, selectedInbox.user.id);
      setMessages(response.data ?? []);
    } catch (error) {
      const err = parseError(error);
      ToastNotifications.error(err, 'loadMessages');
    }
  };

  useEffect(() => {
    if (!selectedInbox || !user) return;

    loadMessages();
  }, [selectedInbox]);

  useEffect(() => {
    const handler = (data) => {
      setMessages([...messages, data]);
    };

    socket.off('updateChat', handler).on('updateChat', handler);
  }, [socket, messages]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const messageChanged = (e) => {
    setNewMessage(e.target.value);
  };

  const sendNewMessageClicked = () => {
    socket.emit('newMessage', {
      message: newMessage.trim(),
      fromUserId: user.id,
      toUserId: selectedInbox.user.id,
      socketId: `${socket.id}+${Math.random()}`,
    });
    setNewMessage('');
  };

  if (!selectedInbox) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large" backgroundColor={theme.colors.background.secondary.BG4}>
      <Flex height={'600px'} flexDirection={'column'}>
        <Text variant={'heading6'} text={`${selectedInbox?.user.username}`} margin={'0 0 48px 0'} color={theme.colors.palette.purple} />
        <Flex width={'100%'} height={'100%'} flexDirection={'column'} gap="20px" overflow={'scroll'}>
          {messages &&
            messages.map((message: Message, index) => (
              <Flex key={index} width={'100%'} justifyContent={message.fromUser.id === user.id ? 'flex-end' : 'flex-start'}>
                <Flex
                  maxWidth={'80%'}
                  border={`1px solid ${theme.colors.border.darker}`}
                  borderRadius="20px"
                  backgroundColor={theme.colors.border.darker}
                  padding={'10px'}
                  flexDirection={'column'}
                  gap="5px"
                >
                  <Text variant={'paragraph1'} text={message.message} />
                  <Text
                    variant={'paragraph4'}
                    text={moment(message.createdAt).format('HH:mm, D-MM-yyyy')}
                    textAlign={message.fromUser.id === user.id ? 'right' : 'left'}
                    width={'100%'}
                  />
                </Flex>
              </Flex>
            ))}
          <Flex ref={lastMessageRef} />
        </Flex>
        <Flex width={'100%'} flexDirection={'row'} alignItems={'center'} gap="20px">
          <Input placeholder={t('hint_message')} value={newMessage} width="100%" onChange={messageChanged} />
          <Button variant={'primaryDefault'} text={t('btn_send')} minWidth="120px" onClick={() => sendNewMessageClicked()} />
        </Flex>
        <Text variant={'paragraph3'} text={t('messages_5')} textAlign="center" width={'100%'} margin={'8px 0 0 0'} />
      </Flex>
    </Modal>
  );
};
