export const styles = {
  bmBurgerButton: {
    display: 'none',
  },
  bmBurgerBars: {
    display: 'none',
  },
  bmBurgerBarsHover: {
    display: 'none',
  },
  bmCrossButton: {
    display: 'none',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '79px',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  bmMenu: {
    background: '#FFFFFF',
  },
  bmItemList: {
    height: '100%',
    color: '#b8b7ad',
    padding: '24px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  bmItem: {
    display: 'inline-block',
    padding: '8px 16px',
    outline: 'none',
    border: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    top: '79px',
    left: '0',
    bottom: '0',
    right: '0',
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};
