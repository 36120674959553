import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { Button, Flex, Modal, Text, Input, Grid } from 'src/components/common';
import { DateTimePicker, InlineTextWrapper } from 'src/components/shared';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';
import { appointmentsAPI } from 'src/api/appointments/ApiRequests';
import { RoutesEnum } from 'src/routes';
import { MonriPaymentInputs } from 'src/pages/dashboard/MonriPaymentInputs';
import { config } from 'src/config/config';
import { delay } from 'src/utils/delay';
import { CheckBox, ToggleButtonLabel, ToggleButtonWrapper } from 'src/components/common/toggle-button/styles';
import { useTheme } from 'styled-components';
import { goToUrl } from 'src/utils/go-to-url';
import { RolesEnum } from 'src/constants/RolesEnum';
import { refreshCurrentUser } from 'src/store/slices/authSlice';
import moment from 'moment';
import { setSelectedSession } from 'src/store/slices/sessionSlice';
import MasterCardIcon from 'src/assets/images/monri/mastercard.png';
import MaestroIcon from 'src/assets/images/monri/maestro.png';
import VisaIcon from 'src/assets/images/monri/visa.jpg';
import MonriIcon from 'src/assets/images/monri/monri.png';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/User';

interface Props {
  therapist: User;
  clientId: number;
  availableDatesToBook: Date[];
  isOpen: boolean;
  redirectRoute: RoutesEnum;
  buttonLabel: string;
  onClose: () => void;
}

export const BookTherapistModal = ({ isOpen, onClose, therapist, clientId, availableDatesToBook, redirectRoute, buttonLabel }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const { t } = useTranslation();
  const { isMobile } = useContext(IsMobileContext);

  const { user } = useAppSelector((state: RootState) => state.auth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [includedTimes, setIncludedTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(user?.role?.role == RolesEnum.THERAPIST);
  const [voucherCode, setVoucherCode] = useState('');

  const formRef = useRef<HTMLFormElement>();

  function getMinTimeBeforeBooking() {
    // Therapists are able to book appointment without any time related rules
    // For clients booking appointment should be available only 48hrs before session
    let minTimeBeforeBooking = user?.role?.role === RolesEnum.THERAPIST ? 0 : config.MIN_TIME_BEFORE_BOOKING_48;
    // I user is booking then it depends on therapist settings
    if (user?.role?.role !== RolesEnum.THERAPIST) {
      minTimeBeforeBooking = therapist?.earlyBooking == true ? config.MIN_TIME_BEFORE_BOOKING_24 : config.MIN_TIME_BEFORE_BOOKING_48;
    }
    return minTimeBeforeBooking;
  }

  const filteredAvailableDates = useMemo(() => {
    if (!availableDatesToBook || !Array.isArray(availableDatesToBook) || availableDatesToBook.length === 0) {
      return [];
    }
    return availableDatesToBook
      ?.filter((date) => date.getTime() > new Date().getTime() + getMinTimeBeforeBooking())
      ?.sort((a, b) => b.getDate() - a.getDate());
  }, [availableDatesToBook]);

  useEffect(() => {
    getIncludedTimes(selectedDate);
  }, []);

  // by default selected date is null
  // when user clicks on desired date 1st time, selectedDate's time will be current one
  // submit button should be disabled until user selects one of available times too
  const isSelectedDateOneOfAvailable = useMemo(() => {
    const selectedDateHour = selectedDate?.getHours();
    const selectedDateAvailableHours = includedTimes?.map((t) => t.getHours());
    return selectedDateAvailableHours.includes(selectedDateHour);
  }, [selectedDate, includedTimes]);

  const getIncludedTimes = (date: Date) => {
    let sameDates = [];
    for (let i = 0; i < filteredAvailableDates.length; i++) {
      if (moment(date, moment.ISO_8601).format('YYYY/MM/DD') === moment(filteredAvailableDates[i], moment.ISO_8601).format('YYYY/MM/DD')) {
        const sameDate = filteredAvailableDates[i];
        sameDates = [...sameDates, sameDate];
      }
    }
    const removePassedTimes = sameDates.filter((date) => {
      return date.getTime() > new Date().getTime() + getMinTimeBeforeBooking();
    });

    setIncludedTimes(removePassedTimes);
  };

  const createAppointment = async () => {
    setLoading(true);

    if (voucherCode && voucherCode.length > 0) {
      payWithVoucher();
    } else {
      payWithMonri();
    }
  };

  async function payWithVoucher() {
    try {
      const response = await appointmentsAPI.createAppointmentWithVoucher({
        therapistId: therapist?.id?.toString(),
        clientId,
        date: selectedDate.getTime(),
        voucher: voucherCode,
      });
      if (response.success) {
        await dispatch(setSelectedSession(response.data));
        ToastNotifications.success(t('msg_session_booked'), 'createAppointment');
        await dispatch(refreshCurrentUser());
        paymentSuccessful();
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'createAppointment');
    } finally {
      setLoading(false);
    }
  }

  async function payWithMonri() {
    try {
      const response = await appointmentsAPI.createAppointment({
        therapistId: therapist?.id?.toString(),
        clientId,
        date: selectedDate.getTime(),
      });
      if (response.success) {
        await dispatch(setSelectedSession(response.data));
        // Trigger monri only for clients, if therepast is booking for client then just dismiss modal
        if (user?.role?.role != RolesEnum.THERAPIST) {
          await delay(300);
          formRef.current && formRef.current.submit();
        } else {
          ToastNotifications.success(t('msg_session_booked'), 'createAppointment');
          paymentSuccessful();
        }
      }
    } catch (error) {
      ToastNotifications.error(parseError(error), 'createAppointment');
    } finally {
      setLoading(false);
    }
  }

  const paymentSuccessful = async () => {
    onClose();

    if (redirectRoute != null) {
      navigate(redirectRoute);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} gap={'10px'}>
        <Text variant="heading5" text={t('book_therapist_1')} margin={'0 0 20px 0'} width={'100%'} textAlign={'center'} />

        <DateTimePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onSelect={getIncludedTimes}
          includeDates={filteredAvailableDates}
          highlightDates={filteredAvailableDates}
          includeTimes={includedTimes}
          timeIntervalInMinutes={config.CALENDAR_TIME_INTERVAL_IN_MINUTES}
          minTime={config.CALENDAR_MIN_TIME}
          maxTime={config.CALENDAR_MAX_TIME}
          maxYears={config.CALENDAR_MAX_YEARS}
          excludeOutOfBoundsTimes={true}
          dayWrapperSize={isMobile ? '2rem' : '5rem'}
          dayTextSize={18}
          monthWidth={'30%'}
          timeTrackWidth={isMobile ? '15%' : '20%'}
          monthTrackWidth={isMobile ? '85%' : '80%'}
          monthYearHeaderMargin={isMobile ? 0 : 10}
        />

        <form ref={formRef} action={process.env.REACT_APP_MONRI_URL} id="monri-integration-form-prepare" method="POST">
          <MonriPaymentInputs user={user} onPaymentSuccessful={paymentSuccessful} />
        </form>

        <Flex flexDirection={isMobile ? 'column' : 'row'} gap="40px" width="100%">
          {user?.role?.role != RolesEnum.THERAPIST && (
            <Flex flexDirection="column" width="100%" justifyContent="flex-start">
              <ToggleButtonWrapper style={{ marginBottom: 24, marginTop: 16 }}>
                <CheckBox id="checkbox" type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
                <ToggleButtonLabel htmlFor="checkbox" />
                <InlineTextWrapper>
                  <Text margin="0 4px 0 0" variant={'paragraph1'} text={t('book_therapist_2')} />
                  <Text
                    margin="0 4px 0 0"
                    variant={'paragraph1'}
                    text={t('book_therapist_3')}
                    color={theme.colors.palette.purple}
                    style={{ textDecoration: 'underline' }}
                    onClick={() => goToUrl(config.PAYMENT_POLICY_URL, '_blank')}
                  />
                </InlineTextWrapper>
              </ToggleButtonWrapper>
              <Input
                placeholder={t('hint_voucher')}
                width={'100%'}
                value={voucherCode}
                onChange={(e) => {
                  setVoucherCode(e.target.value);
                }}
              />
            </Flex>
          )}
          <Button
            type="button"
            disabled={loading || !selectedDate || !isSelectedDateOneOfAvailable || !termsAccepted}
            isLoading={loading}
            variant="primaryLarge"
            width="100%"
            text={buttonLabel}
            margin={'auto 0 0 0'}
            onClick={createAppointment}
          />
        </Flex>

        <InlineTextWrapper textAlign="center">
          <Text
            margin="0 4px 0 0"
            variant={'paragraph2'}
            text={`${t('book_therapist_4')} `}
            style={{ fontFamily: 'Barlow-Bold' }}
            color={theme.colors.palette.red}
          />
          <Text margin="0 4px 0 0" variant={'paragraph2'} text={t('book_therapist_5')} />
        </InlineTextWrapper>

        <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr'} gridTemplateRows={'1fr'} gap={'10px'} width={isMobile ? '80%' : '50%'}>
          <a href="https://www.mastercard.hr/hr-hr.html" target="_blank" rel="noreferrer">
            <img src={MasterCardIcon} alt="mastercard-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html" target="_blank" rel="noreferrer">
            <img src={MaestroIcon} alt="maestro-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://www.visaeurope.com" target="_blank" rel="noreferrer">
            <img src={VisaIcon} alt="visa-icon" height={isMobile ? 30 : 40} />
          </a>
          <a href="https://monri.com" target="_blank" rel="noreferrer">
            <img src={MonriIcon} alt="monri-icon" height={isMobile ? 30 : 40} />
          </a>
        </Grid>
      </Flex>
    </Modal>
  );
};
