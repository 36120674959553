import { AccordionItem } from 'src/components/common/accordion/Accordion';
import { useTranslation } from 'react-i18next';

export const faqClients = (): AccordionItem[] => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      title: t('faq_5'),
      content: t('faq_6'),
    },
    {
      id: 2,
      title: t('faq_7'),
      content: t('faq_8'),
    },
    {
      id: 3,
      title: t('faq_9'),
      content: t('faq_10'),
    },
    {
      id: 4,
      title: t('faq_11'),
      content: t('faq_12'),
    },
    {
      id: 5,
      title: t('faq_13'),
      content: t('faq_14'),
    },
    {
      id: 6,
      title: t('faq_15'),
      content: t('faq_16'),
    },
    {
      id: 7,
      title: t('faq_17'),
      content: t('faq_18'),
    },
    {
      id: 8,
      title: t('faq_19'),
      content: t('faq_20'),
    },
    {
      id: 9,
      title: t('faq_21'),
      content: t('faq_22'),
    },
    {
      id: 10,
      title: t('faq_23'),
      content: t('faq_24'),
    },
    {
      id: 11,
      title: t('faq_25'),
      content: t('faq_26'),
    },
    {
      id: 12,
      title: t('faq_27'),
      content: t('faq_28'),
    },
    {
      id: 13,
      title: t('faq_29'),
      content: t('faq_30'),
    },
    {
      id: 14,
      title: t('faq_31'),
      content: t('faq_32'),
    },
    {
      id: 15,
      title: t('faq_33'),
      content: t('faq_34'),
    },
  ];
};

export const faqTherapists = (): AccordionItem[] => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      title: t('faq_35'),
      content: t('faq_36'),
    },
    {
      id: 2,
      title: t('faq_37'),
      content: t('faq_38'),
    },
    {
      id: 3,
      title: t('faq_39'),
      content: t('faq_40'),
    },
    {
      id: 4,
      title: t('faq_41'),
      content: t('faq_42'),
    },
    {
      id: 5,
      title: t('faq_43'),
      content: t('faq_44'),
    },
    {
      id: 6,
      title: t('faq_455'),
      content: t('faq_45'),
    },
    {
      id: 7,
      title: t('faq_46'),
      content: t('faq_47'),
    },
    {
      id: 8,
      title: t('faq_48'),
      content: t('faq_49'),
    },
    {
      id: 9,
      title: t('faq_50'),
      content: t('faq_51'),
    },
    {
      id: 10,
      title: t('faq_52'),
      content: t('faq_53'),
    },
    {
      id: 11,
      title: t('faq_54'),
      content: t('faq_55'),
    },
  ];
};
