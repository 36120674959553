import React, { Ref } from 'react';
import { OptionProps } from 'react-select';

import { Text, Checkbox } from 'src/components/common';

import { SelectDropdownOption } from '../SelectDropdown.types';
import { OptionWrapper } from '../SelectDropdown.styles';

export const CustomOption = ({ innerProps, isSelected, label, selectProps: { isMulti } }: OptionProps<SelectDropdownOption>) => (
  <OptionWrapper {...(innerProps as Ref<HTMLDivElement>)}>
    {isMulti && <Checkbox checked={isSelected} />}
    <Text text={label} variant="paragraph1" classNames="label" whiteSpace="pre-line" />
  </OptionWrapper>
);
