import styled, { DefaultTheme } from 'styled-components';

import { ButtonVariant, ButtonSize, ButtonStyles } from 'src/types/Button';

export const getStyles = (variant: ButtonVariant, theme: DefaultTheme): ButtonStyles => {
  const getPrimaryStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.purple,
      backgroundColorDisabled: theme.colors.palette.purple,
      border: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      color: theme.colors.palette.white,
      iconColor: theme.colors.text.primary,
      colorDisabled: theme.colors.palette.white,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getSecondaryStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.white,
      backgroundColorDisabled: theme.colors.palette.white,
      border: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      color: theme.colors.text.primary,
      iconColor: theme.colors.text.primary,
      colorDisabled: theme.colors.text.disabled,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getTransparentStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: 'transparent',
      backgroundColorDisabled: theme.colors.palette.purple,
      border: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      color: theme.colors.text.primary,
      iconColor: theme.colors.text.primary,
      colorDisabled: theme.colors.text.disabled,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getRedStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.red,
      backgroundColorDisabled: theme.colors.palette.purple,
      border: `${borderThickness}px solid ${theme.colors.palette.red}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.purple}`,
      color: theme.colors.palette.white,
      iconColor: theme.colors.text.white,
      colorDisabled: theme.colors.text.disabled,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getSecondaryRedStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.white,
      backgroundColorDisabled: theme.colors.palette.purple,
      border: `${borderThickness}px solid ${theme.colors.palette.red}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.red}`,
      color: theme.colors.text.primary,
      iconColor: theme.colors.text.primary,
      colorDisabled: theme.colors.text.disabled,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getAccentStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.green,
      backgroundColorDisabled: theme.colors.palette.green,
      border: `${borderThickness}px solid ${theme.colors.palette.green}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.green}`,
      color: theme.colors.palette.white,
      iconColor: theme.colors.text.white,
      colorDisabled: theme.colors.palette.white,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getSecondaryAccentStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.white,
      backgroundColorDisabled: theme.colors.palette.green,
      border: `${borderThickness}px solid ${theme.colors.palette.green}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.green}`,
      color: theme.colors.text.primary,
      iconColor: theme.colors.text.primary,
      colorDisabled: theme.colors.text.disabled,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const getInfoStyles = (borderThickness, padding, height, typographyVariant): ButtonStyles => {
    return {
      backgroundColor: theme.colors.palette.info,
      backgroundColorDisabled: theme.colors.palette.info,
      border: `${borderThickness}px solid ${theme.colors.palette.info}`,
      borderDisabled: `${borderThickness}px solid ${theme.colors.palette.info}`,
      color: theme.colors.palette.white,
      iconColor: theme.colors.text.white,
      colorDisabled: theme.colors.palette.white,
      padding: padding,
      height: height,
      typographyVariant: typographyVariant,
    };
  };

  const styles = {
    primaryLarge: getPrimaryStyles(2, '19px 16px', ButtonSize.LARGE, 'subheading'),
    primaryDefault: getPrimaryStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    primarySmall: getPrimaryStyles(1, '9px 12px', ButtonSize.SMALL, 'paragraph2'),
    secondaryLarge: getSecondaryStyles(2, '19px 16px', ButtonSize.LARGE, 'subheading'),
    secondaryDefault: getSecondaryStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    secondarySmall: getSecondaryStyles(1, '9px 12px', ButtonSize.SMALL, 'paragraph2'),
    transparentLarge: getTransparentStyles(2, '19px 16px', ButtonSize.LARGE, 'subheading'),
    transparentDefault: getTransparentStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    transparentSmall: getTransparentStyles(1, '9px 12px', ButtonSize.SMALL, 'paragraph2'),
    redLarge: getRedStyles(2, '19px 16px', ButtonSize.LARGE, 'subheading'),
    redDefault: getRedStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    secondaryRedDefault: getSecondaryRedStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    redSmall: getRedStyles(1, '9px 12px', ButtonSize.SMALL, 'paragraph2'),
    accentLarge: getAccentStyles(2, '19px 16px', ButtonSize.LARGE, 'subheading'),
    accentDefault: getAccentStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    secondaryAccentDefault: getSecondaryAccentStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
    accentSmall: getAccentStyles(1, '9px 12px', ButtonSize.SMALL, 'paragraph2'),
    infoDefault: getInfoStyles(1, '16px', ButtonSize.DEFAULT, 'paragraph1'),
  };

  return styles[variant];
};

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  outline: 0;
  opacity: 1;
  border-radius: 30px;
  position: relative;
  width: ${(props) => props.width ?? 'fit-content'};
  min-width: ${(props) => props.minWidth ?? 'unset'};
  margin: ${(props) => props.margin ?? '0px'};
  background-color: ${(props) => props.styles.backgroundColor};
  color: ${(props) => props.styles.color};
  border: ${(props) => (props.noBorder ? 'none' : props.styles.border)};
  padding: ${(props) => props.styles.padding};
  height: ${(props) => props.styles.height};

  * {
    cursor: pointer !important;
  }

  &:hover {
    opacity: 0.8;
  }

  :disabled {
    cursor: not-allowed !important;
    background-color: ${(props) => props.styles.backgroundColorDisabled};
    border: ${(props) => props.styles.borderDisabled} !important;
    opacity: 0.7;

    * {
      cursor: not-allowed !important;
    }

    > div {
      color: ${(props) => props.styles.colorDisabled} !important;
    }

    svg path {
      fill: ${(props) => props.styles.colorDisabled};
    }
  }
`;

export const RightIconWrapper = styled.div`
  margin-left: 8px;
  height: 20px;
  width: 20px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const LeftIconWrapper = styled.div`
  margin-right: 8px;
  height: 20px;
  width: 20px;

  svg {
    height: 20px;
    width: 20px;
  }
`;
