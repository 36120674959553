import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Text } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';
import { goToUrl } from 'src/utils/go-to-url';
import { config } from 'src/config/config';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flexDirection="column" flex="1" padding={isMobile ? '20px' : `50px ${maxHorizontalPadding}`}>
      <Text variant={'heading2'} text={t('privacy_policy_1')} margin={'0 0 32px 0'} />

      <Text variant={'paragraph1'} margin={'0 0 32px 0'} whiteSpace={'pre-wrap'} text={t('privacy_policy_2')} />

      <Text variant={'heading5'} text={t('privacy_policy_3')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_4')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_5')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_6')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_7')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_8')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_9')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_10')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_11')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_12')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_13')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_14')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_15')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_16')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_17')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_18')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_19')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_20')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_21')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_22')} margin={'0 0 16px 0'} />
      <InlineTextWrapper margin={'0 0 32px 0'}>
        <Text margin="0 4px 0 0" variant={'paragraph1'} text={`${t('privacy_policy_23')} `} />
        <Text
          margin="0 4px 0 0"
          variant={'paragraph1'}
          text={t('privacy_policy_24')}
          color={theme.colors.palette.blue}
          style={{ textDecoration: 'underline' }}
          onClick={() => goToUrl(config.CONTACT_URL)}
        />
      </InlineTextWrapper>

      <Text variant={'heading5'} text={t('privacy_policy_25')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_26')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_27')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_28')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_29')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_30')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} text={t('privacy_policy_31')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_32')} margin={'0 0 32px 0'} />

      <Text variant={'heading5'} margin={'0 0 32px 0'} text={t('privacy_policy_33')} />

      <Text variant={'heading5'} text={t('privacy_policy_34')} margin={'0 0 16px 0'} />
      <Text variant={'paragraph1'} text={t('privacy_policy_35')} margin={'0 0 16px 0'} />

      <Text variant={'paragraph1'} whiteSpace={'pre-wrap'} text={t('privacy_policy_36')} margin={'0 0 32px 0'} />
    </Flex>
  );
};
