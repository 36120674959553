import { IStatisticsResponseDTO } from 'src/api/user/ResponseDtos';
import { StatisticsLabels } from 'src/constants/StatisticsLabels';

export const generateStatisticsData = (apiData: IStatisticsResponseDTO) => {
  if (!apiData) {
    return undefined;
  }

  const labels = apiData.previousAppointments.map(item => item.label);
  const currentSessionsByMonth = apiData.previousAppointments.map(item => item.count);
  const futureSessionsByMonth = apiData.futureAppointments.map(item => item.count);
  const profitByMonth = apiData.profitByMonth.map(item => item.count);

  return {
    labels,
    datasets: [
      {
        label: StatisticsLabels.COMPLETED_SESSIONS,
        data: currentSessionsByMonth,
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: StatisticsLabels.FUTURE_SESSIONS,
        data: futureSessionsByMonth,
        fill: true,
        borderColor: '#742774',
      },
      {
        label: StatisticsLabels.PROFIT_BY_MONTH,
        data: profitByMonth,
        fill: true,
        borderColor: '#742774',
      },
    ],
  };
};
