import React, { useMemo, forwardRef } from 'react';
import { useTheme } from 'styled-components';

import { Text } from 'src/components/common';
import { TextAreaProps } from 'src/types/Textarea';
import { StyledTextareaContainer, TextareaWrapper, StyledTextarea, ErrorMessageWrapper, getColorStyles, getFontStyles } from './styles';

export const Textarea = forwardRef((props: TextAreaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
  const theme = useTheme();

  const { label = undefined, errorMessage = undefined, readOnly, rows = 6, minChars = undefined, ...rest } = props;
  const colorStyles = useMemo(() => {
    return getColorStyles(errorMessage, readOnly, theme);
  }, [errorMessage, readOnly, theme]);

  const fontStyles = useMemo(() => {
    return getFontStyles(theme);
  }, [theme]);

  const minCharsError = useMemo(() => {
    return minChars && rest.value && rest.value.toString().length < minChars;
  }, [minChars, rest.value]);

  return (
    <StyledTextareaContainer {...rest}>
      {label && <Text text={label} variant="paragraph2" margin="0 0 4px 0" />}
      <TextareaWrapper>
        <StyledTextarea {...rest} rows={rows} ref={ref} colorStyles={colorStyles} fontStyles={fontStyles} />
      </TextareaWrapper>

      {errorMessage && !minCharsError && (
        <ErrorMessageWrapper>
          <Text text={errorMessage} variant="paragraph3" margin="4px 0 0 0" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}

      {minCharsError && !errorMessage && (
        <ErrorMessageWrapper>
          <Text text={`Minimalan broj znakova je ${minChars}`} variant="paragraph3" margin="4px 0 0 8px" color={theme.colors.palette.red} />
        </ErrorMessageWrapper>
      )}
    </StyledTextareaContainer>
  );
});
