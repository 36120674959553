import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ToastNotifications } from 'src/utils/toast-notifications';
import { parseError } from 'src/utils/error-parser';
import { useAppDispatch } from 'src/store';
import { resetPassword } from 'src/store/slices/authSlice';
import { useTranslation } from 'react-i18next';

export interface FormState {
  email: string;
}

const formDefaultValues: FormState = {
  email: '',
};

const useResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const useResetPasswordFormchema = yup.object().shape({
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
  });

  const form = useForm({
    resolver: yupResolver(useResetPasswordFormchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: FormState) => {
    setIsSubmittingForm(true);

    setTimeout(() => {
      try {
        dispatch(resetPassword(data));
      } catch (error) {
        const err = parseError(error);
        ToastNotifications.error(err, 'resetPwSubmit');
      } finally {
        setIsSubmittingForm(false);
      }
    }, 200);
  };

  return {
    formDefaultValues,
    form,
    isSubmittingForm,
    onFormSubmit: onSubmit,
  };
};

export { useResetPasswordForm };
