import React, { ReactElement, useRef, useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import classnames from 'classnames';
import { Text } from 'src/components/common';
import { generateUrlFromFile } from 'src/utils/generate-url-from-file';
import { useTranslation } from 'react-i18next';

const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: ${(props) => props.margin || 0};
`;

const UploadPhotoElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => props.size || '100px'};
  width: ${(props) => props.size || '100px'};
  background-color: ${(props) => props.backgroundColor || props.theme.colors.background.secondary.BG1};
  border-radius: 50%;
  cursor: pointer;
  border: none;
  z-index: 1000;
  overflow: hidden;
  outline: 4px solid white !important;
  outline-offset: 2px !important;
  opacity: 1;
  transition: all 0.5s;

  > * {
    cursor: pointer;
  }

  input[type='file'] {
    display: none;
  }

  &.loading {
    opacity: 0.5;
    transition: all 0.5s;
  }
`;

const AddTextWrapper = styled.div`
  position: absolute;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }
`;

const PhotoPreview = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

interface IProps {
  externalImageLoading?: boolean;
  size?: string;
  backgroundColor?: string;
  margin?: string;
  onChange?: (event: any) => void;
  photoAdded?: File | null;
  allowedFormats?: string;
  errorMessage?: string;
}

export const UploadPhotoWithPreview = (props: IProps): ReactElement => {
  const theme = useTheme();
  const fileInput = useRef(null);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const photoUrl = useMemo(() => {
    return generateUrlFromFile(props.photoAdded);
  }, [props.photoAdded]);

  const imageChangeHandler = (e: any) => {
    setLoading(true);
    const files = e.target.files;

    setTimeout(() => {
      props.onChange && props.onChange(files ? files[0] : null);
      setLoading(false);
    }, 500);
  };

  return (
    <UploadButtonWrapper margin={props.margin}>
      <UploadPhotoElement
        size={props.size}
        backgroundColor={props.backgroundColor}
        onClick={() => {
          if (fileInput && fileInput.current) {
            // @ts-ignore
            fileInput.current.click();
          }
        }}
        className={classnames('', {
          loading: loading,
        })}
      >
        <input ref={fileInput} type="file" onChange={imageChangeHandler} multiple={false} accept={props.allowedFormats || '*'} />

        {props.photoAdded && <PhotoPreview src={photoUrl} />}

        {!props.photoAdded && (
          <AddTextWrapper>
            <Text text={t('upload_1')} variant="paragraph3" color={theme.colors.text.primary} whiteSpace="pre" />
          </AddTextWrapper>
        )}
      </UploadPhotoElement>

      {props.errorMessage && <Text text={props.errorMessage} variant="paragraph3" margin="4px 0 0 0" color={theme.colors.palette.red} />}

      {!props.errorMessage && props.photoAdded && (
        <Text
          text={t('btn_remove_image')}
          variant="paragraph2"
          margin="24px 0 0 0"
          color={theme.colors.palette.red}
          width="100%"
          textAlign="center"
          onClick={() => {
            props.onChange(null);
            fileInput.current.value = null;
          }}
        />
      )}
    </UploadButtonWrapper>
  );
};
