import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const Instagram = (props: UIIconProps) => {
  const { size = 28, color = 'currentColor', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} fill="none" viewBox="0 0 28 28">
      <path
        fill={color}
        d="M14 8.265h2.867c.675 0 1.012.169 1.265.253.338.169.59.253.844.506.253.253.422.506.506.843.084.253.169.59.253 1.265V16.867c0 .675-.169 1.012-.253 1.265-.169.338-.253.59-.506.844-.253.253-.506.422-.843.506-.253.084-.59.169-1.265.253H11.133c-.675 0-1.012-.169-1.266-.253-.337-.169-.59-.253-.843-.506s-.422-.506-.506-.843c-.084-.253-.169-.59-.253-1.265V14v-2.867c0-.675.169-1.012.253-1.266.169-.337.253-.59.506-.843s.506-.422.843-.506c.253-.084.59-.169 1.265-.253H14zM14 7h-2.867c-.76 0-1.266.169-1.687.337a3.683 3.683 0 00-1.265.844c-.422.421-.59.759-.844 1.265-.168.421-.253.928-.337 1.687v5.734c0 .76.169 1.265.337 1.687.169.422.422.844.844 1.265.421.422.759.59 1.265.844.421.168.928.253 1.687.337h5.734c.76 0 1.265-.169 1.687-.337a3.683 3.683 0 001.265-.844c.422-.421.59-.759.844-1.265.168-.421.253-.927.337-1.686V14v-2.867c0-.76-.169-1.266-.337-1.687a3.683 3.683 0 00-.844-1.265c-.421-.422-.759-.59-1.265-.844-.421-.168-.927-.253-1.686-.337H14z"
      />
      <path
        fill={color}
        d="M14 10.373A3.6 3.6 0 0010.373 14 3.6 3.6 0 0014 17.627 3.6 3.6 0 0017.627 14 3.6 3.6 0 0014 10.373zm0 5.988A2.357 2.357 0 0111.639 14 2.357 2.357 0 0114 11.639 2.357 2.357 0 0116.361 14c0 1.265-1.096 2.361-2.361 2.361zM17.71 11.133a.843.843 0 100-1.687.843.843 0 000 1.687z"
      />
    </svg>
  );
};
