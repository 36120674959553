import React, { useContext } from 'react';
import { IsMobileContext } from 'src/context/IsMobileContext';
import { Grid, Flex, Text } from 'src/components/common';
import SendQuestionForm from 'src/components/shared/send-question-form/SendQuestionForm';
import FAQIlustracija from 'src/assets/images/faq-ilustracija.svg';
import { maxHorizontalPadding } from 'src/constants/common';
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'}>
      <Grid
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        gridTemplateRows={'1fr'}
        width={'100%'}
        gap={'10px'}
        padding={isMobile ? '20px' : `48px ${maxHorizontalPadding}`}
      >
        <Flex flexDirection={'column'} width={'100%'}>
          <Text variant={'heading4'} width={isMobile ? '100%' : '85%'} text={t('contact_1')} margin={'0 0 24px 0'} />
          <Text variant={'paragraph2'} width={isMobile ? '100%' : '85%'} text={t('contact_2')} margin={'0 0 32px 0'} />
          <img src={FAQIlustracija} alt="o-nama-glavna-ilustracija" width={'75%'} height={'auto'} />
        </Flex>

        <Flex flexDirection={'column'} width={'100%'}>
          <Text variant={'heading4'} width={'100%'} textAlign={'center'} text={t('btn_send_request')} margin={'0 0 24px 0'} />
          <SendQuestionForm />
        </Flex>
      </Grid>
    </Flex>
  );
};
