import { httpService } from 'src/config/http';
import { IRegisterRequestDTO, ILoginRequestDTO, IResetPasswordRequestDTO } from './RequestDtos';
import { IAuthenticateResponseDTO } from './ResponseDtos';
import { ApiResponse } from 'src/types/ApiResponse';
import { User } from 'src/types/User';

export default class AuthAPI {
  public register(data: IRegisterRequestDTO) {
    return httpService.post<IAuthenticateResponseDTO>('/auth/register', data, {
      noAuthentication: true,
    });
  }

  public login(data: ILoginRequestDTO) {
    return httpService.post<IAuthenticateResponseDTO>('/auth/login', data, {
      noAuthentication: true,
    });
  }

  public resetPassword(data: IResetPasswordRequestDTO) {
    return httpService.post<ApiResponse<string>>(`/auth/reset-password?email=${data.email}`, {
      noAuthentication: true,
    });
  }

  public getCurrentUser() {
    return httpService.get<User>('/users/me');
  }
}

export const authAPI = new AuthAPI();
