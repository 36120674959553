import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import MasterCardIcon from 'src/assets/images/monri/mastercard.png';
import MaestroIcon from 'src/assets/images/monri/maestro.png';
import VisaIcon from 'src/assets/images/monri/visa.jpg';
import MonriIcon from 'src/assets/images/monri/monri.png';

import { IsMobileContext } from 'src/context/IsMobileContext';
import { Flex, Grid, Text } from 'src/components/common';
import { InlineTextWrapper } from 'src/components/shared';
import { Facebook, Instagram, Linkedin } from 'src/components/icons/social-icons';
import { RoutesEnum } from 'src/routes';
import { maxHorizontalPadding } from 'src/constants/common';
import { config } from 'src/config/config';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  height: auto;
  width: 100%;
  background-color: ${(props) => props.theme.colors.palette.white};
  padding: 48px ${maxHorizontalPadding};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.text.disabled};
  gap: 40px;

  a {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 24px;
  }
`;

export const Footer = () => {
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Grid gridTemplateColumns={isMobile ? '1fr' : '2.5fr 1fr 1fr 2fr 0.5fr'} gridTemplateRows={'1fr'} width={'100%'} gap={'25px'}>
        <Flex flexDirection="column" width="auto" alignItems={isMobile ? 'center' : 'flex-start'} gap={'20px'}>
          <NavLink to={RoutesEnum.HOME}>
            <Text variant={'heading5'} text={config.APP_NAME} color={theme.colors.palette.purple} />
          </NavLink>

          <Flex width={'100%'} flexDirection={'column'} alignItems={isMobile ? 'center' : 'flex-start'} gap={'10px'}>
            <Text
              variant={isMobile ? 'paragraph2' : 'paragraph1'}
              text={t('footer_1')}
              whiteSpace={'pre-wrap'}
              textAlign={isMobile ? 'center' : 'left'}
            />

            <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr'} gridTemplateRows={'1fr'} gap={'10px'}>
              <a href="https://www.mastercard.hr/hr-hr.html" target="_blank" rel="noreferrer">
                <img src={MasterCardIcon} alt="mastercard-icon" height={isMobile ? 30 : 40} />
              </a>
              <a href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html" target="_blank" rel="noreferrer">
                <img src={MaestroIcon} alt="maestro-icon" height={isMobile ? 30 : 40} />
              </a>
              <a href="https://www.visaeurope.com" target="_blank" rel="noreferrer">
                <img src={VisaIcon} alt="visa-icon" height={isMobile ? 30 : 40} />
              </a>
              <a href="https://monri.com" target="_blank" rel="noreferrer">
                <img src={MonriIcon} alt="monri-icon" height={isMobile ? 30 : 40} />
              </a>
            </Grid>
          </Flex>
        </Flex>

        <Flex flexDirection="column" width="100%" gap="10px" alignItems={isMobile ? 'center' : 'flex-start'}>
          <Text variant={'paragraph1'} text={t('footer_2')} color={theme.colors.palette.purple} />

          <NavLink to={RoutesEnum.PRIVACY_POLICY}>
            <Text variant={'paragraph1'} text={t('footer_3')} />
          </NavLink>

          <NavLink to={RoutesEnum.TERMS_OF_USE}>
            <Text variant={'paragraph1'} text={t('footer_4')} />
          </NavLink>

          <NavLink to={RoutesEnum.PAYMENT_POLICY}>
            <Text variant={'paragraph1'} text={t('footer_5')} />
          </NavLink>
        </Flex>

        <Flex flexDirection="column" width="100%" gap="10px" alignItems={isMobile ? 'center' : 'flex-start'}>
          <Text variant={'paragraph1'} text={t('footer_6')} color={theme.colors.palette.purple} />

          <NavLink to={RoutesEnum.FAQ}>
            <Text variant={'paragraph1'} text={t('footer_7')} />
          </NavLink>
        </Flex>

        <Flex flexDirection="column" width="100%" gap="10px" alignItems={isMobile ? 'center' : 'flex-start'}>
          <Text variant={'paragraph1'} text={t('footer_8')} color={theme.colors.palette.purple} />

          <Text variant={'paragraph1'} text={`${t('footer_9')} ${config.COMPANY_NAME}`} />
          <Text variant={'paragraph1'} text={`${t('footer_10')} ${config.MUNICIPAL_COURT_DECISION}`} />
          <Text variant={'paragraph1'} text={`${t('footer_11')} ${config.ID_NUMBER}`} />
          <Text variant={'paragraph1'} text={`${t('footer_12')} ${config.CONTACT_PHONE}`} />
          <InlineTextWrapper>
            <Text variant={'paragraph1'} text={t('footer_13')} margin={'0 4px 0 0'} />
            <a href={`mailto:${config.CONTACT_MAIL}`}>
              <Text
                variant={'paragraph1'}
                text={config.CONTACT_MAIL}
                color={theme.colors.palette.blue}
                style={{ textDecoration: 'underline' }}
              />
            </a>
          </InlineTextWrapper>
        </Flex>

        <Flex flexDirection="column" width="100%" gap="10px" alignItems={isMobile ? 'center' : 'flex-start'}>
          <Text variant={'paragraph1'} text={t('footer_14')} color={theme.colors.palette.purple} />

          <Text variant={'paragraph1'} text={t('footer_15')} />

          <Flex
            width={'100%'}
            alignItems="center"
            margin={isMobile ? '0' : '0 0 0 -12px'}
            justifyContent={isMobile ? 'center' : 'flex-start'}
          >
            <Link to={'https://www.facebook.com/profile.php?id=61553944146513'}>
              <Facebook size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
            <Link to={'https://www.instagram.com/psihoterapija.app'}>
              <Instagram size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
            <Link to={'https://www.linkedin.com/company/psihoterapija-app/'}>
              <Linkedin size={48} color={theme.colors.palette.purple} style={{ opacity: 0.8 }} />
            </Link>
          </Flex>
        </Flex>
      </Grid>
      <Text text={t('footer_16')} width={'100%'} textAlign={isMobile ? 'center' : 'left'} />
    </FooterContainer>
  );
};
