import { createSlice } from '@reduxjs/toolkit';

import { ClientDashboardTabsEnum } from 'src/constants/ClientDashboardTabsEnum';
import { TherapistDashboardTabsEnum } from 'src/constants/TherapistDashboardTabsEnum';

export interface GlobalState {
  language: string;
  showRecommendFriendModal: boolean;
  showEditProfileModal: boolean;
  showDeleteProfileAlert: boolean;
  clientDashboard: {
    activeTab: number;
    clientBurgerMenuItems: any;
    clientDashboardNavItems: any;
  };
  therapistDashboard: {
    activeTab: number;
    therapistBurgerMenuItems: any;
    therapistDashboardNavItems: any;
  };
  showTutorialModal: boolean;
}

const initialState: GlobalState = {
  language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 'ba',
  showRecommendFriendModal: false,
  showEditProfileModal: false,
  showDeleteProfileAlert: false,
  clientDashboard: {
    activeTab: localStorage.getItem('clientDashboardActiveTab')
      ? Number(JSON.parse(localStorage.getItem('clientDashboardActiveTab')))
      : ClientDashboardTabsEnum.HOME,
    clientBurgerMenuItems: undefined,
    clientDashboardNavItems: undefined,
  },
  therapistDashboard: {
    activeTab: localStorage.getItem('therapistDashboardActiveTab')
      ? Number(JSON.parse(localStorage.getItem('therapistDashboardActiveTab')))
      : TherapistDashboardTabsEnum.HOME,
    therapistBurgerMenuItems: undefined,
    therapistDashboardNavItems: undefined,
  },
  showTutorialModal: false,
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', JSON.stringify(action.payload));
    },
    setShowRecommendFriendModal: (state, action) => {
      state.showRecommendFriendModal = action.payload;
    },
    setShowEditProfileModal: (state, action) => {
      state.showEditProfileModal = action.payload;
    },
    setShowDeleteProfileAlert: (state, action) => {
      state.showDeleteProfileAlert = action.payload;
    },
    setClientDashboardActiveTab: (state, action) => {
      state.clientDashboard.activeTab = action.payload;

      if (action.payload) {
        localStorage.setItem('clientDashboardActiveTab', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('clientDashboardActiveTab');
      }
    },
    setClientDashboardBurgerMenuItems: (state, action) => {
      state.clientDashboard.clientBurgerMenuItems = action.payload;
    },
    setClientDashboardNavItems: (state, action) => {
      state.clientDashboard.clientDashboardNavItems = action.payload;
    },
    setTherapistDashboardActiveTab: (state, action) => {
      state.therapistDashboard.activeTab = action.payload;

      if (action.payload) {
        localStorage.setItem('therapistDashboardActiveTab', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('therapistDashboardActiveTab');
      }
    },
    setTherapistDashboardBurgerMenuItems: (state, action) => {
      state.therapistDashboard.therapistBurgerMenuItems = action.payload;
    },
    setTherapistDashboardNavItems: (state, action) => {
      state.therapistDashboard.therapistDashboardNavItems = action.payload;
    },
    setShowTutorialModal: (state, action) => {
      state.showTutorialModal = action.payload;
    },
  },
  extraReducers() {},
});

export const {
  setLanguage,
  setShowRecommendFriendModal,
  setShowEditProfileModal,
  setShowDeleteProfileAlert,
  setClientDashboardActiveTab,
  setClientDashboardBurgerMenuItems,
  setClientDashboardNavItems,
  setTherapistDashboardActiveTab,
  setTherapistDashboardBurgerMenuItems,
  setTherapistDashboardNavItems,
  setShowTutorialModal,
} = globalSlice.actions;

export default globalSlice.reducer;
