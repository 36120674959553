import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Feedback } from 'src/types/Feedback';
import { feedbackAPI } from 'src/api/feedback/ApiRequests';
import { parseError } from 'src/utils/error-parser';
import { ToastNotifications } from 'src/utils/toast-notifications';

export interface FeedbackState {
  showFeedbackModal: boolean;
  loading: boolean;
  feedback: Feedback[];
}

const initialState: FeedbackState = {
  showFeedbackModal: false,
  loading: false,
  feedback: [],
};

export const getFeedback = createAsyncThunk('getFeedback', async (_, thunkAPI) => {
  try {
    return await feedbackAPI.getFeedback();
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'getFeedback');
    return thunkAPI.rejectWithValue(error);
  }
});

export const submitFeedback = createAsyncThunk('submitFeedback', async (data: Feedback, thunkAPI) => {
  try {
    return await feedbackAPI.submitFeedback(data);
  } catch (error) {
    const err = parseError(error);
    ToastNotifications.error(err, 'submitFeedback');
    return thunkAPI.rejectWithValue(error);
  }
});

export const feedbackSlice = createSlice({
  name: 'feedbackSlice',
  initialState,
  reducers: {
    toggleFeedbackModal: (state, action) => {
      state.showFeedbackModal = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFeedback.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedback.fulfilled, (state, action: any) => {
        state.loading = false;
        state.feedback = action.payload.data;
      })
      .addCase(getFeedback.rejected, (state) => {
        state.loading = false;
        state.feedback = [];
      });
  },
});

export const { toggleFeedbackModal } = feedbackSlice.actions;

export default feedbackSlice.reducer;
