import React from 'react';
import { MenuListProps, MultiValue, components } from 'react-select';

import { Flex, Text , Checkbox} from 'src/components/common';
import { CustomSelectProps, SelectDropdownOption } from '../SelectDropdown.types';
import { OptionWrapper } from '../SelectDropdown.styles';

export const CustomMenuList = ({ selectProps, ...rest }: MenuListProps<SelectDropdownOption>) => {
  const { isMulti, hasSelectAll, maxSelectable, onChange, options, value } = selectProps as CustomSelectProps;

  // handle all selection
  const handleSelectAll = () => {
    if (maxSelectable) {
      return;
    }

    if (onChange && options) {
      onChange(
        value === null || value === undefined || (value as Array<SelectDropdownOption>).length < options.length
          ? options
          : [],
        {
          action: 'select-option',
          option: options,
        }
      );
    }
  };

  return (
    <Flex flexDirection='column'>
      {isMulti && hasSelectAll && Array.isArray(options) && options.length > 0 && (
        <OptionWrapper onClick={handleSelectAll}>
          <Checkbox
            disabled={!!maxSelectable}
            {...(Array.isArray(value) && {
              checked: (value as MultiValue<SelectDropdownOption>).length === options.length,
              withIndeterminateState: (value as MultiValue<SelectDropdownOption>).length < options.length,
            })}
          />
          <Text text={'Označi sve'} variant='paragraph1' classNames='label' />
        </OptionWrapper>
      )}
      <components.MenuList selectProps={selectProps} {...rest} />
    </Flex>
  );
};
