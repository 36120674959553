import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { QuestionAnswer } from 'src/types/QuestionAnswer';
import { RolesEnum } from 'src/constants/RolesEnum';
import { useAppDispatch } from 'src/store';
import { register } from 'src/store/slices/authSlice';
import { ToastNotifications } from 'src/utils/toast-notifications';

import { userAPI } from 'src/api/user/ApiRequests';
import { useTranslation } from 'react-i18next';

export interface SendApplicationState {
  name: string;
  email: string;
  city: string;
  country: string;
  phone: string;
  password: string;
  repeatPassword: string;
  price: number;
  details: string;
}
const formDefaultValues: SendApplicationState = {
  name: '',
  email: '',
  city: '',
  country: '',
  phone: '',
  password: '',
  repeatPassword: '',
  price: undefined,
  details: '',
};

interface UseSendApplicationFormProps {
  setLoading: (loading: boolean) => void;
}

const useSendApplicationForm = ({ setLoading }: UseSendApplicationFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const useSendQuestionFormSchema = yup.object().shape({
    name: yup.string().required(t('error_name')),
    email: yup.string().email(t('error_email')).required(t('error_email_required')),
    city: yup.string().required(t('error_city_required')),
    country: yup.string().required(t('error_country_required')),
    phone: yup.string().required(t('error_phone')),
    password: yup.string().required(t('error_password')).min(6, t('error_password_required')),
    repeatPassword: yup
      .string()
      .required(t('error_password_repeat'))
      .oneOf([yup.ref('password'), null], t('error_password_repeat_match')),
    price: yup.number().required(t('error_price')),
    details: yup.string().required(t('error_details')),
  });

  const sendApplicationForm = useForm({
    resolver: yupResolver(useSendQuestionFormSchema),
    defaultValues: formDefaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (
    data: SendApplicationState,
    outputAnswers: QuestionAnswer[],
    role: RolesEnum,
    files: File[],
    onClose: () => void
  ) => {
    setLoading(true);

    try {
      const response: any = await dispatch(
        register({
          username: data.name,
          email: data.email,
          city: data.city,
          country: data.country,
          password: data.password,
          phone: data.phone,
          price: data.price,
          details: data.details,
          role: role,
          answers: outputAnswers,
        })
      );

      if (response.payload.success) {
        const formData = new FormData();
        formData.append('userId', response.payload.data.user.id);
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
        const response2 = await userAPI.uploadFiles(formData);
        if (response2.success) {
          ToastNotifications.success(t('msg_therapist_request_sent'));
          onClose();
        } else {
          ToastNotifications.error(t('error_therapist_request'), 'sendApplicationForm');
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    formDefaultValues,
    sendApplicationForm,
    onFormSubmit: onSubmit,
  };
};

export { useSendApplicationForm };
