import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { LoginModal } from 'src/components/shared';
import { Header, Footer } from 'src/components/core';
import { headerHeight } from 'src/constants/common';
import { RootState, useAppSelector } from 'src/store';
import { RegisterModal } from 'src/components/shared/register-modal/RegisterModal';

const LayoutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${headerHeight});
  width: 100%;
  height: auto;
  margin: ${headerHeight} auto 0 auto;
`;

interface LayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: LayoutProps) => {
  const { showLoginModal, showRegistrationodal } = useAppSelector((state: RootState) => state.auth);

  return (
    <main className="main">
      <ToastContainer />

      {showLoginModal && <LoginModal />}

      {showRegistrationodal && <RegisterModal />}

      <Header />

      <LayoutContentWrapper>{children}</LayoutContentWrapper>

      <Footer />
    </main>
  );
};
