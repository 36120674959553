import React from 'react';
import { UIIconProps } from 'src/types/Icons';

export const Linkedin = (props: UIIconProps) => {
  const { size = 28, color = 'currentColor', style = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} fill="none" viewBox="0 0 28 28">
      <path
        fill={color}
        d="M19.6 14.521v3.861h-2.26v-3.626c0-.894-.33-1.506-1.13-1.506-.612 0-.989.424-1.13.8-.047.141-.094.33-.094.565v3.767h-2.26s.047-6.121 0-6.733h2.26v.942c.282-.471.847-1.13 2.024-1.13 1.46 0 2.59.988 2.59 3.06zM10.371 8.4c-.753 0-1.27.518-1.27 1.177 0 .66.47 1.177 1.223 1.177.8 0 1.272-.518 1.272-1.177.047-.706-.424-1.177-1.225-1.177zm-1.13 9.982h2.26V11.65h-2.26v6.733z"
      />
    </svg>
  );
};
